export default {
  meta: {
    title: 'Ancho Completo - Imagen',
    templateClass: 'template-ancho-completo-imagen'
  },
  content: {
    modules: [
      {
        component: 'HeaderSlider',
        props: {
          frames: [
            {
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
              title: 'Energía limpia para un sistema energético sostenible',
              body:
                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
              cta: { type: 'link', target: '#slide-cta-1' },
            },
            {
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
              title: 'Titulo de ejemplo dos',
              body:
                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
              cta: { type: 'link', target: '#slide-cta-1' },
            },
          ],
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          secondLevel: 'Servicios',
		      pageTitle: 'Título de página'
        }   
      },
      {
        component: 'ModuloContenidoDev',
        props: {

        },
      },
    ],
  },
};
