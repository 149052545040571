export default {
  meta: {
    title: 'Soluciones Botonera',
  },
  content: {
    modules: [
    {
        component: 'SolucionesBotonera',
        props: {
          title: 'Descubre nuestras soluciones',
          body: 'Somos expertos en diseñar un planeta mejor. Ofrecemos soluciones para dar respuesta a los grandes desafíos globales a los que se enfrenta la humanidad y que marcarán el futuro del planeta.',
		      cta: 'Accede a todas las soluciones'
        }
      },    
    ]
  }
};
