export default {
  meta: {
    title: 'Agenda del Inversor',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Información bursátil',
          pageTitle: 'Agenda del inversor (6)',
          subscribe: true
		    }
      },  	  
      {
        component: 'Agenda',
        props: {
          dates: [
            {
              day: '12',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },             
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
            },
            {
              day: '16',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
            },  
            {
              day: '12',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '16',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              addCalendar: 'Añadir a mi calendario',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },                                    
          ],
          datesPast: [
            {
              day: '12',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
            },  
            {
              day: '12',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
            },              
          ]          
        }
      },
      {
        component: 'Banners',
        props: {
            frames: [
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },    
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                tel: '956 56 23 70',
                email: 'hola@email.com',
                link: '#',
                linkTitle: 'Contactar'
              },                            
            ]            
        }
      },
    {
        component: 'Banners',
        props: {
            title: 'Oficina del oficionista',
            type: 'motosharing',
            description: 'Póngase en contacto con la oficina del accionista para cualquier duda o petición.',
            linkLightbox: true

        }
      },  	  
	 	    
    ]
  }
};
