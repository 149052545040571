export default {
  meta: {
    title: 'Objetivos de desarrollo',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Objetivos de desarrollo sostenible',
        },
      },
      {
        component: 'DevelopmentGoals',
        props: {
          goals: [
            {
              number: 6,
              cta_type: 'external',
              content: 'https://www.un.org/sustainabledevelopment/es/water-and-sanitation/',
            },
            { number: 7, cta_type: 'internal', content: '#recurso_interno' },
            { number: 11, cta_type: 'internal', content: '#recurso_interno' },
            {
              number: 13,
              cta_type: 'lightbox',
              content: `
                <div class="text-container">
                  <h1>Objetivo numero 13</h1>
                  <p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación.</p>
                </div>
                <div class="image-container">
                  <div class="image">
                    <img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_M.jpg">
                  </div>
                  <div class="content">
                    <h3>Título pequeño</h3>
                  </div>
                </div>

              `,
            },
            {
              number: 16,
              cta_type: 'lightbox',
              content: `
                <div class="text-container">
                  <h1>Objetivo numero 16</h1>
                  <p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación.</p>
                </div>
                <div class="image-container">
                  <div class="image">
                    <img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_M.jpg">
                  </div>
                  <div class="content">
                    <h3>Título pequeño</h3>
                  </div>
                </div>
              `,
            },
          ],
        },
      },
    ],
  },
};
