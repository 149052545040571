import React, { useState, useEffect } from 'react';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { useSliderSwipe, useObserver } from 'React/custom_hooks';
import { TimelineMax, TweenMax } from 'gsap/all';
import { PropTypes as pt } from 'prop-types';

import './style.scss';

import { TagTitle, InputRangeSlider, Link } from 'React/components';

const CifrasSlider = ({ title, frames, module_title, lead_text, module_description, module_cta }) => {
  const [activeFrame, setActiveFrame] = useState(1);

  const imagesContainerWidth = { width: `${100 * (frames.length + 1)}%` };
  const cifrasSlider = React.createRef();
  const cifrasRef = React.createRef();
  const prevButton = React.createRef();
  const nextButton = React.createRef();

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const nextFrame = () => {
    if (activeFrame < frames.length) {
      setActiveFrame(activeFrame + 1);
    }
  };

  const prevFrame = () => {
    if (activeFrame > 1) {
      setActiveFrame(activeFrame - 1);
    }
  };

  if (frames.length > 1) {
    useSliderSwipe(cifrasSlider, setActiveFrame, frames.length);
  }

  const [observer, setElements, entries] = useObserver({
    root: null,
    threshold: 0.5,
  });

  const tl = new TimelineMax({ delay: 0, repeat: 0 });

  useEffect(() => {
    // añadimos la clase slow--y sólo al primer elemento, ya que si no haría trigger de la animación cada vez que cambiamos de frame.
    cifrasRef.current.firstElementChild.querySelector('.number-inner').classList.add('slow--y');
    cifrasRef.current.firstElementChild.querySelector('.title--xs').classList.add('slow--y');
    // guardamos los elementos que queremos observar
    const elements = cifrasSlider.current.querySelectorAll('.slow--y');
    // seteamos tweenmax con opacidad 0 para luego aplicar opacidad 1 cuando los elementos sean visibles al viewport.
    TweenMax.set(elements, { opacity: 0, y: 90 });
    setElements(elements);
  }, [setElements]);

  useEffect(() => {
    entries.forEach((entry) => {
      // buscamos los elementos que son visibles y entonces aplicamos la animación.
      if (entry.isIntersecting) {
        let lazyItem = entry.target;
        tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
        observer.unobserve(lazyItem);
      }
    });
  }, [entries, observer, TimelineMax]);

  return (
    <div className="module grid cifras-slider slow--y" data-active-frame={activeFrame} ref={cifrasSlider}>
      {title &&
        <TagTitle style="slow--y" title={title} />
      }
      {module_title && <h3 className="module_title title--l slow--y">{module_title}</h3>}
      {lead_text && <h4 className="lead_text body--l slow--y">{lead_text}</h4>}
      {module_description && (
        <div className="module_description body--m slow--y">
          {module_description}
          {module_cta && (
            <div className="module_cta slow--y">
              <Link path={module_cta.path} type="arrow">
                {module_cta.label}
              </Link>
            </div>
          )}
        </div>
      )}
      {frames.length > 1 && (
        <>
          <button
            onMouseOver={prevHover}
            onMouseOut={removeHover}
            className="ghost-frame-button ghost-frame-button--left"
            onClick={prevFrame}
          ></button>
          <button
            onMouseOver={nextHover}
            onMouseOut={removeHover}
            className="ghost-frame-button ghost-frame-button--right"
            onClick={nextFrame}
          ></button>
        </>
      )}
      <div ref={cifrasRef} className="cifras-container" style={imagesContainerWidth}>
        {frames.map((frame, idx) => {
          const { value, unit, caption } = frame;
          return (
            <div className="data-point" key={`data-point-${idx}`}>
              <h3 className="number cifras--xl">
                <div className="number-container">
                  <div className="number-inner">
                    {value}
                    {unit && <small>{unit}</small>}
                  </div>
                  <p className="title--xs short-descritpion">{caption}</p>
                </div>
              </h3>
            </div>
          );
        })}
      </div>

      {frames.length > 1 ? (
        <>
          <InputRangeSlider
            changeHandler={setActiveFrame}
            noIndicator
            framesCount={frames.length}
            activeFrame={parseInt(activeFrame)}
            description={module_description}
            frameDescription={frames[activeFrame - 1].descr}
          />
          <div className="slider-controls slow--y">
            <button ref={prevButton} className="prev-button" onClick={prevFrame}>
              <IconArrowRightLong />
            </button>
            <button ref={nextButton} className="next-button" onClick={nextFrame}>
              <IconArrowRightLong />
            </button>
          </div>
        </>
      ) : (
        <hr className="separator slow--y" />
      )}
    </div>
  );
};

CifrasSlider.propTypes = {
  title: pt.string,
  frames: pt.arrayOf(
    pt.shape({
      value: pt.string.isRequired,
      unit: pt.string,
      caption: pt.string,
      descr: pt.string,
    })
  ).isRequired,
  module_title: pt.string,
  lead_text: pt.string,
  module_description: pt.string,
  module_cta: pt.shape({
    path: pt.string.isRequired,
    label: pt.string.isRequired,
  }),
};

export default CifrasSlider;
