export default {
  meta: {
    title: 'Página Webs Acciona',
	  templateClass: 'template-webs-acciona template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
			firstLevel: 'Home',
			pageTitle: 'Webs de acciona'
        }
      },		
      {
        component: 'websAcciona',
        props: {
        }
      },   
    ],
  }
};