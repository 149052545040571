export default {
  meta: {
    title: 'Lightbox - contacto',
  },
  content: {
    modules: [
      {
        component: 'Lightbox',
        props: {
          isVisible: true,
          children: [
            {
              component: 'ContactForm',
              props: {
                action: '/subscription-API-endpoint',
                confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
                description:
                  'Ponemos a disposición pública este canal de comunicación para dar respuesta a consultas, quejas o sugerencias',
                labels: {
                  inquiry_type_title: 'Tipo de consulta',
                  inquiry_type: ['informes trimestrales', 'sugerencia', 'reclamación'],
                  object_data: {
                    title: 'Datos de la instalación',
                    ref: 'Nº de referencia',
                    name: 'Nombre de la instalación',
                    country_label: 'País',
                    country_options: ['Portugal', 'España', 'Reino Unido', 'Japon', 'Chile'],
                  },
                  data_sharing_title: 'Datos del solicitante',
                  data_sharing_name: 'Nombre completo',
                  data_sharing_radio: [
                    'Deseo mantenerme en el anonimato',
                    'Solicito que mis datos personales no sean compartidos con terceros',
                  ],
                  contact_method_title: '¿Cómo desea ser contactado?',
                  contact_method_options: ['email', 'teléfono'],
                  message_title: 'Datos de la consulta',
                  form_controls: {
                    submit: 'Enviar',
                    close: 'Cancelar',
                  },
                },
              },
            },
          ],
        },
      },
    ],
  },
};
