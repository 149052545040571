// Here we define routes used by router (Redbility validation) and Nav component
// Import view component and data, then map it to the corresponding path

import devRoutes from './devRoutes';
import countryRoutes from './countries';

// data models
import {
  // production
  home,
  sobre_acciona,
  page_sobre_acciona,
  soluciones,
  proyectos,
  accionistas_e_inversores,
} from 'Data/';
//
// this data model is used for unfinished pages
const generic_model = {
  meta: {
    title: '[ Redbility - WiP ]',
  },
  content: {
    modules: [],
  },
};

// routes that will appear in nav components
// the icon components for the subsection of 'soluciones' are imported inside the Nav,
// here we just define the name that should match the import alias used in the iconsMap object (inside Nav)
const productionRoutes = [
  { path: '/', label: 'Início', model: home },
  {
    path: '/sobre_acciona',
    label: 'Sobre Acciona',
    model: page_sobre_acciona,
    subRoutes: [
      { path: '/sobre_acciona/nuestro_proposito', label: 'Nuestro proposito', model: sobre_acciona },
      { path: '/sobre_acciona/historia', label: 'Historia', model: sobre_acciona },
      { path: '/sobre_acciona/iniciativas_que_apoyamos', label: 'Iniciativas que apoyamos', model: sobre_acciona },
      {
        path: '/sobre_acciona/sostenibilidad',
        label: 'Sostenibilidad',
        model: sobre_acciona,
        subRoutes: [
          { path: '/sobre_acciona/sostenibilidad/estrategia', label: 'Nuestra estrategia', model: sobre_acciona },
          {
            path: '/sobre_acciona/sostenibilidad/objetivos',
            label: 'Objectivos de Desarollo sostenible',
            model: sobre_acciona,
          },
          { path: '/sobre_acciona/sostenibilidad/indices', label: 'Índices de sostenibilidad', model: sobre_acciona },
          {
            path: '/sobre_acciona/sostenibilidad/alianzas-de-desarollo',
            label: 'Alianzas para el Desarollo Sostenible',
            model: sobre_acciona,
          },
          {
            path: '/sobre_acciona/sostenibilidad/emergencia-climatica',
            label: 'Emergencia Climática',
            model: sobre_acciona,
          },
          { path: '/sobre_acciona/sostenibilidad/desarollo-social', label: 'Desarollo Social', model: sobre_acciona },
        ],
      },
      {
        path: '/sobre_acciona/innovacion',
        label: 'Innoviación',
        model: sobre_acciona,
        subRoutes: [
          { path: '/sobre_acciona/innovacion/innovacion-abierta', label: 'Innoviación Abierta', model: sobre_acciona },
          { path: '/sobre_acciona/innovacion/centros_i_d', label: 'Centros de I+D', model: sobre_acciona },
          {
            path: '/sobre_acciona/innovacion/proyectos-innovacion',
            label: 'Proyectos de innovación',
            model: sobre_acciona,
          },
        ],
      },
      { path: '/sobre_acciona/nuestra_fundacion', label: 'Nuestra fundación', model: sobre_acciona },
      {
        path: '/sobre_acciona/razones_para_trabajar_con_acciona',
        label: 'Por qué trabajar con nosotros',
        model: sobre_acciona,
      },
    ],
  },
  {
    path: '/soluciones',
    label: 'Soluciones',
    model: soluciones,
    subRoutes: [
      { path: '/soluciones/energia', icon: 'Energy', label: 'Energía', model: generic_model },
      { path: '/soluciones/social', icon: 'Social', label: 'Social', model: generic_model },
      { path: '/soluciones/obra-civil', icon: 'Civil', label: 'Obra civil', model: generic_model },
      { path: '/soluciones/economia-circular', icon: 'Industrial', label: 'Economia circular', model: generic_model },
      { path: '/soluciones/movilidad', icon: 'Movilidad', label: 'Movilidad', model: generic_model },
      { path: '/soluciones/inmobiliaria', icon: 'Inmo', label: 'Inmobiliaria', model: generic_model },
      { path: '/soluciones/agua', icon: 'Agua', label: 'Agua', model: generic_model },
    ],
  },
  { path: '/proyectos', label: 'Proyectos', model: proyectos },
  {
    path: '/accionistas_e_inversores',
    label: 'Accionistas e inversores',
    model: accionistas_e_inversores,
    subRoutes: [
      {
        path: '/accionistas_e_inversores/informacion_financiera',
        label: 'Información Financiera',
        model: accionistas_e_inversores,
        subRoutes: [
          {
            path: '/accionistas_e_inversores/informacion_financiera/informe_anual',
            label: 'Informe Anual Integrado',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_financiera/cuentas_anuales',
            label: 'Cuentas anuales',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_financiera/informes_trimestrales',
            label: 'Informes y Presentaciones Trimestrales',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_financiera/periodo_medio_de_pago',
            label: 'Periodo Medio de Pago a Proveedores',
            model: accionistas_e_inversores,
          },
          { path: '/accionistas_e_inversores/informacion_financiera/rating', label: 'Rating', model: generic_model },
        ],
      },
      {
        path: '/accionistas_e_inversores/informacion_bursatil',
        label: 'Información Bursátil',
        model: accionistas_e_inversores,
        subRoutes: [
          {
            path: '/accionistas_e_inversores/informacion_bursatil/dividendos',
            label: 'Dividendos',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_bursatil/opinion_analistas',
            label: 'Opinión de Analistas',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_bursatil/agenda_de_inversor',
            label: 'Agenda del Inversor',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/informacion_bursatil/financiacion_sostenible',
            label: 'Financiación Sostenible',
            model: accionistas_e_inversores,
          },
        ],
      },
      {
        path: '/accionistas_e_inversores/gobierno_corporativo',
        label: 'Gobierno corporativo',
        model: accionistas_e_inversores,
        subRoutes: [
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/junta-general',
            label: 'Junta General de Accionistas',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/consejo-administracion-comisiones',
            label: 'Consejo de Adminstración y Comisiones',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/comite-direccion',
            label: 'Comite de Dirección',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/informacion-accionarial',
            label: 'Información Accionarial',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/informe-anual-gobierno',
            label: 'Informe Anual de Gobierno Corporativo',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/remuneracion-consejeros',
            label: 'Remuneración de los Consejeros',
            model: accionistas_e_inversores,
          },
          {
            path: '/accionistas_e_inversores/gobierno_corporativo/normas-gobierno',
            label: 'Normas de Gobierno',
            model: accionistas_e_inversores,
          },
        ],
      },
      {
        path: '/accionistas_e_inversores/emisiones_de_deuda',
        label: 'Emisiones de deuda',
        model: accionistas_e_inversores,
      },
      {
        path: '/accionistas_e_inversores/hechos_relevantes',
        label: 'Hechos relevantes',
        model: accionistas_e_inversores,
      },
    ],
  },
  { path: '/actualidad', label: 'Actualidad', model: generic_model },
];

export { productionRoutes, devRoutes, countryRoutes };
