import React from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { PropTypes as pt } from 'prop-types';

import './styles.scss';
import { rndKeyGen } from 'React/helpers';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as ReplyIcon } from 'Icons/responder-16.svg';
import { ReactComponent as RetweetIcon } from 'Icons/retweet-16.svg';
import { ReactComponent as FavoriteIcon } from 'Icons/favorite-16.svg';

const RSSfeed = ({ feed }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  return (
    <div ref={moduleRef} className="module">
      <div className="rss-feed grid slow--y">
        {feed.map((item) => {
          const { handle, textContent, link } = item;

          return (
            <div className="feed-item" key={rndKeyGen()}>
              <h3 className="handle title--x">
                <TwitterIcon />@{handle}
              </h3>
              <p className="text-content body--l">{textContent}</p>
              <a className="feed-item-link body--l" href={link}>
                {link}
              </a>
              <ul className="actions-list">
                <li>
                  <a href="#reply">
                    <span className="action-link taglabel">
                      <ReplyIcon /> Responder
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#retweet">
                    <span className="action-link taglabel">
                      <RetweetIcon /> Retweet
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#favorite">
                    <span className="action-link taglabel">
                      <FavoriteIcon className="favorite" /> Favorito
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

RSSfeed.propTypes = {
  feed: pt.arrayOf(
    pt.shape({
      handle: pt.string,
      textContent: pt.string,
      link: pt.string,
    })
  ),
};

export default RSSfeed;
