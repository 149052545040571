export default {
  meta: {
    title: '[WRK] - LogosSlider',
  },
  content: {
    modules: [
      {
        component: 'LogosSlider',
        props: {
          module_title: 'Presencia en organizaciones Internacionales',
          module_description:
            'Los reconocimientos suponen para nosotros un indicador de que trabajamos en la dirección correcta y nos animan a continuar con nuestro propósito de compañía.',
          module_cta: {
            label: 'Ir a Índices de Sostenibilidad',
            path: '#module_cta_path',
            type: 'internal',
          },
          logos: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo One',
              description: 'Se empieza a construir el proyecto en el 2000 y 2001',
              cta: {
                path: '#icon1_cta_path',
                type: 'internal',
              },
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Two',
              description: 'Se empieza a construir el proyecto en el 2000 y 2001',
              cta: {
                path: '#icon2_cta_path',
                type: 'external',
              },
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Three',
              description: 'Se empieza a construir el proyecto en el 2000 y 2001',
              cta: {
                path: '#icon3_cta_path',
                type: 'download',
              },
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Four',
              description: 'Se empieza a construir el proyecto en el 2000 y 2001',
              cta: {
                path: '#icon4_cta_path',
                type: 'lightbox',
              },
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
          ],
        },
      },
      {
        component: 'LogosSlider',
        props: {
          module_title: 'Presencia en organizaciones Internacionales',
          module_description:
            'Los reconocimientos suponen para nosotros un indicador de que trabajamos en la dirección correcta y nos animan a continuar con nuestro propósito de compañía.',
          module_cta: {
            label: 'Ir a Índices de Sostenibilidad',
            path: '#module_cta_path',
            type: 'internal',
          },
          logos: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo One',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Two',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Three',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Four',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              alt_text: 'Logo Five',
            },
          ],
        },
      },
    ],
  },
};
