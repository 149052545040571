import React from 'react';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';
import { useSlowFade } from 'React/custom_hooks';

import './Styles.scss';

const mapaWeb = () => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  return (
    <div ref={moduleRef} className="module-mapa-web module grid">
      <div className="grid-container">
        <div className="item slow--y">
          <div>
            <h2 className="title--s">
              <a href="">Home</a>
            </h2>
          </div>
        </div>
        <div className="item slow--y">
          <div className="item-container slow--y">
            <h2 className="title--s">
              <a href="">Sobre ACCIONA</a>
            </h2>
          </div>
          <div className="item-container slow--y">
            <h3 className="title--xs slow--y">
              <a href="">Nuestro propósito</a>
            </h3>
            <h3 className="title--xs slow--y">
              <a href="">Historia</a>
            </h3>
            <h3 className="title--xs slow--y">
              <a href="">Sostenibilidad</a>
            </h3>
            <nav>
              <p className="body--s slow--y">
                <a href="">Nuestra estrategia</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Objetivos de Desarrollo Sostenible</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Índices de Sostenibilidad</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Alianzas para el Desarrollo Sostenible</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Presencia de ACCIONA en las COP</a>
              </p>
            </nav>
            <h3 className="title--xs slow--y">
              <a href="">Innovación</a>
            </h3>
            <nav>
              <p className="body--s slow--y">
                <a href="">Centros de I+D</a>
              </p>
            </nav>
          </div>
          <div className="item-container slow--y">
            <h3 className="title--xs slow--y">
              <a href="">Por qué trabajar con nosotros</a>
            </h3>
            <nav>
              <p className="body--s slow--y">
                <a href="">Ofertas de empleo</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Formación</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Programa de graduados y becas</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Diversidad, igualdad e inclusión</a>
              </p>
              <p className="body--s slow--y">
                <a href="">Seguridad, salud y bienestar</a>
              </p>
            </nav>
            <h3 className="title--xs slow--y">
              <a href="">Nuestra fundación</a>
            </h3>
            <h3 className="title--xs slow--y">
              <a href="">Iniciativas que apoyamos</a>
            </h3>
          </div>
        </div>

        <div className="item">
          <h2 className="title--s item-container slow--y">
            <a href="">Soluciones</a>
          </h2>
          <ul className="item-container slow--y">
            <li className="title--xs slow--y">
              <a href="#">
                <EnergyIcon height="24" width="24" viewBox="0 0 24 24" />
                Energía
              </a>
            </li>
            <li className="title--xs slow--y">
              <a href="#">
                <MovilidadIcon height="24" width="24" viewBox="0 0 24 24" />
                Transporte
              </a>
            </li>
            <li className="title--xs slow--y">
              <a href="#">
                <AguaIcon height="24" width="24" viewBox="0 0 24 24" />
                Agua
              </a>
            </li>
            <li className="title--xs slow--y">
              <a href="#">
                <SocialIcon height="24" width="24" viewBox="0 0 24 24" />
                Social
              </a>
            </li>
          </ul>
          <ul className="item-container slow--y">
            <li className="title--xs slow--y">
              <a href="#">
                <CiudadesIcon height="24" width="24" viewBox="0 0 24 24" />
                Ciudades
              </a>
            </li>
            <li className="title--xs slow--y">
              <a href="#">
                <InmobiliariaIcon height="24" width="24" viewBox="0 0 24 24" />
                Inmobiliaria
              </a>
            </li>
            <li className="title--xs slow--y">
              <a href="#">
                <CapitalIcon height="24" width="24" viewBox="0 0 24 24" />
                Capital
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default mapaWeb;
