export default {
  meta: {
    title: 'Página 404',
	  templateClass: 'template-404'
  },
  content: {
    modules: [
      {
        component: 'error404',
        props: {
        }
      },
      {
        component: 'Banners',
        props: {
            moduleTitle: 'Módulo Banners',
            frames: [
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },    
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                tel: '956 56 23 70',
                email: 'hola@email.com',
                link: '#',
                linkTitle: 'Contactar'
              },                            
            ]            
        }
      },      
    ],
  }
};