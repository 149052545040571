import window from 'global';
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { productionRoutes, countryRoutes } from 'Client/routes/';

import { Footer, HeaderLanding, HeaderCountry } from 'React/layout';
import uiContext from 'Data/_uiContext';

import { Header } from 'React/components';

import './default.scss';
import FooterLanding from './FooterLanding';
import FooterCountry from './FooterCountry';

class defaultLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceScreenSize: 'small',
      canScroll: true,
      windowWidth: null,
      routesSet: this.props.pageMetaData.routesSet || 'production',
      mobileNavVisible: false,
      mobileNavSubsState: [null, null],
      desktopNavSubsState: null,
      updateMobileNavSubsState: this.toggleMobileNavSubsection.bind(this),
      updateDesktopNavSubsState: this.toggleDesktopNavSubsection.bind(this),
      toggleScroll: this.toggleScroll.bind(this),
      visibleLinksListBox: null,
      setVisibleLinksListBox: this.setVisibleLinksListBox.bind(this),
    };

    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.updateDeviceScreenSize = this.updateDeviceScreenSize.bind(this);
  }

  setVisibleLinksListBox(newId) {
    this.setState({
      visibleLinksListBox: newId,
    });
  }

  toggleScroll(newValue) {
    if (!newValue) {
      this.setState((prevState) => ({
        canScroll: !prevState.canScroll,
      }));
    } else {
      this.setState({ canScroll: newValue });
    }
  }

  toggleMobileNav() {
    // using callback instead of plain value ensures that we are getting updated prevState
    this.setState((prevState) => ({
      mobileNavVisible: !prevState.mobileNavVisible,
      canScroll: !prevState.canScroll,
    }));
  }

  toggleMobileNavSubsection(level, order) {
    const oldState = this.state.mobileNavSubsState;
    let newState = this.state.mobileNavSubsState;

    if (level === 0) {
      // top level items
      newState = [
        order === oldState[0] ? null : order, // when clicking the item that is currently open, collapse it
        null,
      ];
    }

    if (level === 1) {
      newState = [
        oldState[0],
        order === oldState[1] ? null : order, // when clicking the item that is currently open, collapse it
      ];
    }
    this.setState({
      mobileNavSubsState: newState,
    });
  }

  toggleDesktopNavSubsection(subsectionNumber) {
    this.setState({
      desktopNavSubsState: subsectionNumber,
    });
  }

  componentDidMount() {
    this.updateDeviceScreenSize();
    window.addEventListener('resize', this.updateDeviceScreenSize);
    this.detectTouchSupport();

    // set CSS var to fix issues with vh on iOS
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDeviceScreenSize);
  }

  updateDeviceScreenSize() {
    this.setState({
      windowWidth: window.innerWidth,
    });

    if (window.innerWidth < 1024) {
      this.setState({
        deviceScreenSize: 'small',
      });
    } else {
      this.setState({
        deviceScreenSize: 'large',
      });
    }
  }

  detectTouchSupport() {
    if (!('outouchstart' in document.documentElement)) {
      document.querySelector('.app--layout').classList.add('no-touch');
    } else {
      document.querySelector('.app--layout').classList.remove('no-touch');
    }
  }

  render() {
    const { pageMetaData, children } = this.props;
    const {
      title,
      templateClass,
      routesSet = 'production',
      stickyHeader = true,
      landingLogo,
      country,
      landing,
      landingFooter,
    } = pageMetaData;
    // we can pass routes prop into the layout to be used with the navigation component
    // if no routes prop is passed we use production routes
    // routesMap needs to contain imported arrays of routes

    const routesMap = {
      production: productionRoutes,
      country: countryRoutes,
    };

    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <uiContext.Provider value={this.state}>
          <div className={`app--layout layout--default ${templateClass}`} data-can-scroll={this.state.canScroll}>
            {routesSet !== null && routesSet !== 'country' && (
              <Header
                routes={routesMap[routesSet]}
                stickyHeader={stickyHeader}
                mobileNavState={this.state.mobileNavVisible}
                toggleMobileNav={this.toggleMobileNav}
              />
            )}
            {routesSet === 'country' && <HeaderCountry />}
            {landingLogo && <HeaderLanding logo stickyHeader={stickyHeader} />}
            {landing && <HeaderLanding landing stickyHeader={stickyHeader} />}
            <div id="main-container">
              <main id="main" role="main">
                <>
                  {children}
                  {landingFooter !== true && routesSet !== 'country' ? (
                    <Footer />
                  ) : landingFooter == true ? (
                    <FooterLanding />
                  ) : routesSet == 'country' ? (
                    <FooterCountry />
                  ) : (
                    <Footer />
                  )}
                </>
              </main>
            </div>
          </div>
        </uiContext.Provider>
      </>
    );
  }
}

defaultLayout.propTypes = {
  pageMetaData: PropTypes.object.isRequired,
};

export default defaultLayout;
