// button.component.js
import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import './styles.scss';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'Icons/whatsapp.svg';

const ShareSocial = ({ moduleTitle }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  return (
    <div ref={moduleRef} className="module grid module-sharesocial">
      <div className="module-sharesocial-container grid full-width">
        <h2 className="title--s slow--y">{moduleTitle}</h2>
        <nav className="sharesocial-nav">
          <a href="#" className="slow--y">
            <LinkedinIcon />
          </a>
          <a href="#" className="slow--y">
            <FacebookIcon />
          </a>
          <a href="#" className="slow--y">
            <TwitterIcon />
          </a>
          <a href="#" className="link mobile-visible slow--y">
            <WhatsappIcon />
          </a>
        </nav>
      </div>
    </div>
  );
};

export default ShareSocial;
