import React from 'react';
import ReactDOM from 'react-dom';
import { Button, DistribuidorSimple } from 'React/components';

// icons
import { ReactComponent as ChevronDown } from 'Icons/iconchevrondown.svg';
import { ReactComponent as ArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as Cross } from 'Icons/iconcross.svg';
import { ReactComponent as Less } from 'Icons/iconless.svg';
import { ReactComponent as Plus } from 'Icons/iconplus.svg';

const Elements = () => (
	<>
    <h1 className="title--xs xs-title--s s-title--l">Voy a cambiar en funcion del tamaño de viewport</h1>
  	<Button label='Label passed as a prop' size='xl'/>
		<h1 className="title--xl">Soy el título más grande</h1>
		<h2 className="space-top title--l">Soy un título grande</h2>
		<h3 className="space-top title--m">Soy un título mediano</h3>
		<h4 className="space-top title--s">Soy un título pequeño</h4>
		<h5 className="space-top title--xs">Soy un título muy pequeño</h5>
		<h5 className="space-top title--xxs">Soy un título muy muy pequeño</h5>
		<div className="space-top">
			<h5 className="title--small">Soy el título más pequeño que existe</h5>
		</div>
		<div className="space-top"><h4 className="cifras--s">48</h4></div>
		<div className="space-top"><h4 className="cifras--m">48</h4></div>
		<div className="space-top"><h4 className="cifras--l">48</h4></div>
		<ul>
			<li>
				//ChevronDown
				<ChevronDown className="icon icon--red" width="20px" height="20px" />
				//ArrowRight
				<ArrowRight width="20px" height="20px" />
				//Cross
				<Cross width="20px" height="20px" />
				<Less width="20px" height="20px" />
				<Plus width="20px" height="20px" />
			</li>
		</ul>
		<div className="module grid">
			<div className="grid-container"></div>
		</div>
		<div className="module grid grid-flow-left">
			<div className="grid-container--left"></div>
		</div>		
		<div className="module grid grid-flow-right">
			<div className="grid-container--right"></div>
		</div>
		<div className="module grid module-distributor module-distributor--left">
			<div className="module-distributor__image"></div>
			<div className="module-distributor__content">
				<div className="module-distributor__text"></div>
			</div>
		</div>
		<div className="module grid module-distributor module-distributor--right">
			<div className="module-distributor__content">
				<div className="module-distributor__text"></div>
			</div>
			<div className="module-distributor__image"></div>
		</div>
		<DistributorSimple href="#hola" label="soy un link"/>
	</>
);

export default Elements;
