import React, { useContext } from 'react';
import { Link } from 'React/components';
import { NavSubsection } from 'React/layout';
// import { useSpring, animated as a, config } from 'react-spring';

import uiContext from 'Data/_uiContext';
import './Nav.scss';
import { ReactComponent as Close } from 'Icons/iconcross.svg';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';

import navIconsMap from 'React/layout/_navIconsMap';

const Nav = ({ routes, isVisible, navCloseMethod, children }) => {
  const context = useContext(uiContext);

  // remove nav link to Home
  const routesArray = routes.filter((route) => route.path !== '/');

  // recursively generate Routes from routes array
  const generateLinks = (routesArray, lvl = 0) => {
    return routesArray.map((route, idx) => {
      const { path, label, icon, subRoutes } = route;
      const IconComponent = navIconsMap[icon];

      const labelActual =
        typeof subRoutes !== 'undefined' && context.deviceScreenSize === 'small'
          ? `Portada de ${label.toLowerCase()}`
          : label;

      const mainRoute = (
        <Link
          className={`link link-lvl-${lvl}`}
          key={`link-${lvl}-${idx}`}
          path={path}
          subsection={
            typeof subRoutes !== 'undefined' && context.deviceScreenSize !== 'small'
              ? { level: lvl, order: idx + 1 }
              : null
          }
        >
          {icon && <IconComponent width={18} height={18} viewBox="0 0 22 23" />}
          {labelActual}
          {subRoutes && <Chevron width="1em" height="1em" viewBox="0 -2 9 9" />}
        </Link>
      );

      if (typeof subRoutes !== 'undefined' && context.deviceScreenSize === 'small') {
        return (
          <React.Fragment key={`fragment-${idx}`}>
            {context.deviceScreenSize === 'small' ? null : mainRoute}

            <NavSubsection level={lvl} order={idx + 1} sectionTitle={label}>
              {generateLinks(subRoutes, lvl + 1)}
              {mainRoute}
            </NavSubsection>
          </React.Fragment>
        );
      } else {
        return mainRoute;
      }
    });
  };

  return (
    <a.nav className="nav__main" style={context.deviceScreenSize === 'small' ? springProps : null}>
      {context.deviceScreenSize === 'small' && <Close className="close-x" onClick={navCloseMethod} />}

      <ul className="nav_links">{generateLinks(routesArray)}</ul>

      {// render children inside nav footer on mobile
      children && <div className="nav__mobile-footer">{children}</div>}
    </a.nav>
  );
};

export default Nav;
