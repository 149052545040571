export default {
  meta: {
    title: '[WRK] - Distributor Destacado',
  },
  content: {
    modules: [     
      {
        component: 'DistribuidorDestacadoSlider',
        props:  {
        	frames: [
				{
					title: 'Nuestro impacto positivo en el planeta',
					text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
					image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
					label: 'Descubre más sobre seguridad y salud',
					href: '#',
					tagtitle: 'Nuestro objetivo',
          			ods: 'http://accionacorporativa-front.redbilityservers.com/static/icons/ods.svg',
					download: '',

				},
				{
					title: 'Nuestro propósito: Business as unusual',
					text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
					image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
					label: 'Descubre más sobre seguridad y salud',
					href: '#',
					tagtitle: 'Nuestro objetivo',
				}	,
				{
					title: 'Nuestro propósito: Business as unusual',
					text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
					image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
					label: 'Descubre más sobre seguridad y salud',
					href: '#',
					tagtitle: 'Nuestro objetivo',
          			download: 'Descargar información técnica'					
				}	,
				{
					title: 'Nuestro propósito: Business as unusual',
					text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
					image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
					label: 'Descubre más sobre seguridad y salud',
					href: '#',
					tagtitle: 'Nuestro objetivo'
				}												
			]
        },
      },
    ],
  },
};
