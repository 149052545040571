export default {
  meta: {
    title: '[WRK] - Testimonios',
  },
  content: {
    modules: [
      {
        component: 'TestimoniosSlider',
        props: {
          tagTitle: 'Testimonios con audio',
          frames: [
            {
              blockquote:
                'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
              personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
          ],
            cta: 'Ver nuestros propósitos'            

        },
      },
    ],
  },
};

