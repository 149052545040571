import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import './styles.scss';

const DistribuidorDestacadoAreas = ({
  moduleTitle,
  title,
  titleBlock,
  cifra1,
  cifra2,
  text,
  href,
  image,
  imageReplace,
  tagtitle,
}) => {
  const imageUrl = useWindowWidth() >= 767 ? image : imageReplace;

  const onHover = (event) => {
    event.currentTarget.parentNode.parentNode.classList.add('is-hovered');
  };

  const notHover = (event) => {
    event.currentTarget.parentNode.parentNode.classList.remove('is-hovered');
  };

  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  return (
    <section
      ref={moduleRef}
      className={`module grid module-distribuidor-destacado  module-distribuidor-destacado--areas grid-flow--left slow--y`}
    >
      {moduleTitle && <h2 className="title--tag grid-container">{moduleTitle}</h2>}
      <div className={`grid-container--left slow--y `} style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="cifras-container slow--y">
          <div className="slow--y">
            <span className="cifras--m slow--y">{cifra1}</span>
            <p className="taglabel slow--y">MW EÓLICOS EN PROPIEDAD</p>
          </div>
          {cifra2 && (
            <div className="slow--y">
              <span className="cifras--m slow--y">{cifra2}</span>
              <p className="taglabel slow--y">MW EÓLICOS EN PROPIEDAD</p>
            </div>
          )}
        </div>
      </div>
      <div className={`grid-container--right slow--y`}>
        {tagtitle && <TagTitle className="slow--y" title={tagtitle} />}

        {titleBlock && <h2 className="title--xs mb-2 slow--y">{titleBlock}</h2>}

        {title && (
          <h2 className="title--l slow--y" onMouseOut={notHover} onMouseOver={onHover}>
            <a href="#">{title}</a>
          </h2>
        )}

        {text && (
          <div className="mt-2 slow--y">
            <p className="body--m slow--y">{text}</p>
          </div>
        )}

        {href && (
          <div className="mt-3 slow--y">
            <Link type="arrow" />
          </div>
        )}
      </div>
    </section>
  );
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
};

export default DistribuidorDestacadoAreas;
