export default {
  meta: {
    title: '[WRK] - Distribuidor Multiple',
  },
  content: {
    modules: [
      {
        component: 'DistribuidorMultiple',
        props: {
          moduleTitle: 'Distribuidor múltiple',
          frames: [
            {
              moduleLink: 'Junta general de accionistas 2017',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Junta general de accionistas 2017',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              linkGroup: {
                links: [
                  { url: '#project_1', label: 'Project One' },
                  { url: '#project_2', label: 'Project Two' },
                  { url: '#project_3', label: 'Project Three' },
                ],
                label: 'Ver descargas',
              },
              fileParams: 'PDF 814KB',
              list: [
                'Mantener una cifra de innovación sobre ventas superior a la media europea para conservar el liderazgo innovador de ACCIONA',
                'Innovación colaborativa: cooperar con terceros para el desarrollo de tecnologías que garanticen la sostenibilidad de los negocios de la compañía',
                'Innovación operativa: crear y mejorar los procesos eficientes que generen ahorros de, al menos, 75 millones de euros (15 millones de euros cada año)',
              ],
            },
            {
              moduleLink: 'Junta general de accionistas 2017',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Junta general de accionistas 2017',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              linkGroup: {
                links: [
                  { url: '#project_1', label: 'Project One' },
                  { url: '#project_2', label: 'Project Two' },
                  { url: '#project_3', label: 'Project Three' },
                ],
                label: 'Ver descargas',
              },
              fileParams: 'PDF 814KB',
              singleList: true,
              list: ['Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos'],
            },
            {
              type: 'logo',
              moduleLink: 'Descubre nuestro propósito',
              mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              title: 'Premios Expansión Compilance 2018',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              link: 'Descubre nuestro propósito',
              download: 'Descárgate toda la información',
            },
            {
              moduleLink: 'Descubre nuestro propósito',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Premios Expansión Compilance 2018',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              link: 'Descubre nuestro propósito',
              download: 'Descárgate toda la información',
            },
          ],
        },
      },
    ],
  },
};
