import React, { useState, useEffect } from 'react';

import Map from './Map';
import './styles.scss';

import { Link } from 'React/components';

import { ReactComponent as IconLocation } from 'Icons/location-24.svg';

const ProjectsMap = ({ projectsList }) => {
  const mapCenter = projectsList[0];

  // component state
  const [centerPoint, setCenterPoint] = useState(mapCenter);
  const [zoomValue, setZoomValue] = useState(2);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredList, setFilteredList] = useState(projectsList);

  // selected project details
  const changeSelectedPoint = (projectId) => {
    setSelectedProject({
      key: projectId,
      ...filteredList.find((project) => project.id === projectId),
    });
  };

  useEffect(() => {
    if (selectedProject === null) return;

    setCenterPoint(selectedProject);

    // red Marker for selected project
    setFilteredList((oldState) =>
      oldState.map((project) => {
        if (project.id == selectedProject.id) {
          project.selected = true;
        } else {
          project.selected = false;
        }
        return project;
      })
    );
  }, [selectedProject]);

  return (
    <><div className="module grid projects-map module__projects-map-static">
      <div className="map-container" data-detail-overlay-is-visible={selectedProject !== null}>
        <Map
          pointsList={filteredList}
          changeSelectedPoint={changeSelectedPoint}
          mapCenter={centerPoint}
          changeMapCenter={setCenterPoint}
          mapZoomValue={zoomValue}
          changeZoomValue={setZoomValue}
        />

      </div>

    </div>
      <div className="location"><IconLocation /><span className="country title--s">Australia</span><Link type="external" label="Ver en Google Maps"/></div>
</>
  );
};

export default ProjectsMap;
