// this component renders its children and adds context-nav component with position sticky below
// it also passes the 'combined' prop that is used to remove bottom margin on children components
import React from 'react';
import { ReactComponent as EnergiaIcon } from 'Icons/energia-viento.svg';

import './withSolucionesNav.scss';

const withSectionContextNav = ({ children, type, interior, routes }) => {
  if (type === 'single') {
    return (
      <>
        {children.map((child) => {
          return React.cloneElement(child, { combined: true });
        })}
        <nav className="module context-nav context-nav--single">
          <div className="title--s is-active">
            <EnergiaIcon />
            <span>Energia</span>
          </div>
        </nav>
      </>
    );
  } else {
    return (
      <>
        {children.map((child) => {
          return React.cloneElement(child, { combined: true });
        })}
        <nav className="module context-nav">
          {interior && (
            <div className="title--s interior is-active">
              <a href="">
                <EnergiaIcon />
                <span>Energia</span>
              </a>
            </div>
          )}
          {!interior && (
            <div className="title--s is-active">
              <EnergiaIcon />
              <span>Energia</span>
            </div>
          )}
          <a href="#" className="title--xs">
            Proyectos
          </a>
          <a href="#" className="title--xs">
            Área de actividad
          </a>
          <a href="#" className="title--xs menu-active">
            Tecnologías
          </a>
          <a href="#" className="title--xs">
            Sostenibilidad
          </a>
          <a href="#" className="title--xs">
            Innovación
          </a>
        </nav>
      </>
    );
  }
};

export default withSectionContextNav;
