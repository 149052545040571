export default {
  meta: {
    title: 'CTA',
    templateClass: 'template-cta template-ancho-completo-imagen',
    stickyHeader: false,
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
          title: 'Trabajamos para dar respuesta a los grandes desafíos del planteta',
        },
      },      
      {
        component: 'Breadcrumbs',
        props: {
          type: 'fixed',
          className: 'anchors-fixed',
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Información financiera',
          fourthLevel: 'Informe Anual Integrado',
          pageTitle: 'Informe anual integrado 2018',
          label: 'Enlace',
          anchor: 'Ancla 1',
          secondAnchor: 'Ancla 2',
          thirdAnchor: 'Ancla 3',
          fourthAnchor: 'Ancla 4',
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
    ],
  },
};
