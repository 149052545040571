export default {
  meta: {
    title: 'Detalle Solución',
    templateClass: 'template-ancho-completo-imagen'

  },
  content: {
    modules: [
      {
        component: 'withSolucionesNav',
        props: {
          children: [
            {
              component: 'HeroImage',
              props: {
                imageUrl:
                'https://source.unsplash.com/random/2560x1440?wind',
                title: 'Energía limpia para un sistema energético sostenible',
                body:
                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
                cta: { type: 'link', target: '#some-cta' },
              },
            }
          ],
        }
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          secondLevel: 'Soluciones',
          pageTitle: 'Título de página'
        }
      },      
      {
        component: 'DistribuidorDestacadoAreas',
        props:  {
          title: 'Nuestra segunda tecnología por producción',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Solar Fotovoltaica',
          cifra1: '7.812',
        },
      }, 
      {
        component: 'DistribuidorDestacadoAreas',
        props:  {
          title: 'Nuestra segunda tecnología por producción',
          text: 'Contamos con una importante experiencia tanto en evaluación del recurso como en diseño, construcción, operación y mantenimiento de instalaciones hidroeléctricas.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Hidroeléctrica',
          cifra1: '7.812'
        },
      },
      {
        component: 'DistribuidorDestacadoAreas',
        props:  {
          moduleTitle: 'Áreas de actividad',
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          titleBlock: 'Nuestro objetivo',
          cifra1: '7.812'
        },
      },          
       {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: 'm³',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'link internal',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'civil',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                },
              },
            },
          ],
        },
       }	  	  	  
    ]
  }
};
