import React, { useState, useEffect } from 'react';

import { TagTitle, Link } from 'React/components';
import Map from './Map';
import { iconsMap, iconsMapLarge } from './solucionesIconsMap';
import './styles.scss';
import { ReactComponent as CircledArrow } from 'Icons/arrow_circle-68.svg';
import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { rndKeyGen } from 'React/helpers';

const solutionsList = ['energia', 'transporte', 'agua', 'social', 'ciudades', 'inmobiliaria', 'capital'];

const ProjectsMap = ({ title, initMapCenter, projectsList, module_cta }) => {
  const baseCenter = { lat: 40.4167754, lng: -3.7037902 };
  const mapCenter =
    typeof initMapCenter === 'undefined'
      ? baseCenter
      : typeof initMapCenter === 'number'
      ? projectsList[initMapCenter]
      : initMapCenter;

  // component state
  const [centerPoint, setCenterPoint] = useState(mapCenter);
  const [zoomValue, setZoomValue] = useState(2);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredList, setFilteredList] = useState(projectsList);

  // subset of solution types present in the projects list
  const solutionsOnMap = [...new Set(projectsList.map((project) => project.solution))];

  // filtering
  useEffect(() => {
    const projects = projectsList.map((project) => {
      project.id = rndKeyGen();
      return project;
    });

    if (selectedSolution === null) {
      setFilteredList(projects);
    } else {
      setFilteredList(projects.filter((project) => project.solution === selectedSolution));
    }
  }, [selectedSolution]);

  const handleSolutionClick = (solution) => {
    setSelectedSolution((oldState) => {
      if (oldState === solution) return null;

      if (!solutionsOnMap.includes(solution)) return null;

      return solution;
    });

    // center on base point
    if (solution === selectedSolution) {
      setCenterPoint({ lat: 40.4167754, lng: -3.7037902 }); // Madrid, Spain
      setZoomValue(2);
    }
  };

  const handleCloseOverlayClick = () => {
    setSelectedProject(null);
    if (filteredList.length > 0) {
      setFilteredList((oldState) =>
        oldState.map((project) => {
          project.selected = false;
          return project;
        })
      );
    }
  };

  // selected project details
  const changeSelectedPoint = (projectId) => {
    setSelectedProject({
      key: projectId,
      ...filteredList.find((project) => project.id === projectId),
    });
  };

  useEffect(() => {
    if (selectedProject === null) {
      return;
    }

    setCenterPoint(() => {
      const { lat, lng } = selectedProject;
      return { lat, lng };
    });

    // red Marker for selected project
    setFilteredList((oldState) =>
      oldState.map((project) => {
        if (project.id == selectedProject.id) {
          project.selected = true;
        } else {
          project.selected = false;
        }
        return project;
      })
    );
  }, [selectedProject]);

  return (
    <div className="module grid projects-map module__projects-map">
      <div className="map-header grid">
        <TagTitle title={title} />
        <Link type="arrow" className="module_cta slow">
          Título enlace
        </Link>
      </div>

      <div className="soluciones-container">
        <ul className="soluciones-selector" data-details-overlay-visible={selectedProject !== null}>
          {solutionsList.map((solution, idx) => {
            return (
              <li
                key={`solution-key-${idx}`}
                onClick={() => handleSolutionClick(solution)}
                data-selected-solution={selectedSolution === solution}
                data-solution-not-on-map={!solutionsOnMap.includes(solution)}
              >
                <button>
                  {typeof window != undefined && window.innerWidth < 1280
                    ? iconsMap[solution]
                    : iconsMapLarge[solution]}
                  <span className="title--xs">{solution}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="map-container" data-detail-overlay-is-visible={selectedProject !== null}>
        <Map
          pointsList={filteredList}
          selectedSolution={selectedSolution}
          changeSelectedPoint={changeSelectedPoint}
          mapCenter={centerPoint}
          changeMapCenter={setCenterPoint}
          mapZoomValue={zoomValue}
          changeZoomValue={setZoomValue}
        />

        <ProjectDetailsOverlay project={selectedProject} closeAction={handleCloseOverlayClick} />
        <div className="project-details-close-overlay" onClick={handleCloseOverlayClick} />
      </div>
      <Link type="arrow" className="module_cta mobile slow">
        Título enlace
      </Link>
    </div>
  );
};

const ProjectDetailsOverlay = ({ project, closeAction }) => {
  const { name, solution, location, image, url, info } = project || {};
  const _self = React.createRef();

  return (
    <div className="project-details-overlay grid" ref={_self} data-is-visible={project !== null}>
      <button className="close-overlay" onClick={() => closeAction(null)}>
        <CloseIcon />
      </button>
      {project && (
        <>
          <div className="image-container">
            <img src={image} />
            <div className="detail-body" data-content-type={typeof info === 'string' ? 'text' : 'cifra'}>
              {typeof info === 'string' && <h4 className="title--m">{info}</h4>}

              {typeof info === 'object' && (
                <>
                  <p className="cifras--m">
                    {info.cifra}
                    <small>{info.unit}</small>
                  </p>
                  <p className="title--xs">{info.caption}</p>
                </>
              )}
            </div>
          </div>
          <div className="details-footer">
            <span className="params">
              <a href="">
                <h3 className="name title--s">{name}</h3>
              </a>
              <span className="solution-location taglabel">
                <Link path="#solution_path">
                  {iconsMap[solution]}
                  <i>{solution}</i>
                </Link>
                |
                <a href="">
                  <i>{location}</i>
                </a>
              </span>
            </span>
            {url && (
              <Link path={url} className="link">
                {typeof window && window.innerWidth < 1680 ? (
                  <CircledArrow width="68" height="68" />
                ) : (
                  <CircledArrow width="120" height="120" />
                )}
              </Link>
            )}
          </div>
          <div className="additional-info">
            <ul>
              <li>
                <div className="taglabel">TITULARIDAD</div>
                <div className="body--m">Cliente</div>
              </li>
              <li>
                <div className="taglabel">ESTADO</div>
                <div className="body--m">Instalada</div>
              </li>
              <li>
                <div className="taglabel">SERVICIOS</div>
                <div className="body--m">Experiencia del cliente, Satisfacción, Sostenibilidad, Durabilidad</div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectsMap;
