import React from 'react';
import './styles.scss';

const cookies = () => {
  return (
    <div class="module-cookies">
      <div>
        <h2 class="title">Esta página web usa cookies</h2>
        <p>
          Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de
          redes sociales y analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con
          nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información
          que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios. Usted
          puede configurar las cookies haciendo clic en "Configuración" o puede aceptarlas de acuerdo con nuestra
          política de cookies y continuar navegando.
        </p>
      </div>
      <div class="inner">
        <button class="conf">Configuración</button>
        <button class="ok">OK</button>
      </div>
    </div>
  );
};

export default cookies;
