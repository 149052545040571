import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useHeroParallax } from 'React/custom_hooks';
import ReactPlayer from 'react-player';

import { Link, Lightbox } from 'React/components';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as Play } from 'Icons/video_play-white.svg';

import './HeaderSlider.scss';
import { setTimeout } from 'window-or-global';

const HeaderSlider = (props) => {
  const [activeFrame, setActiveFrame] = useState(1);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState(false);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const headerSliderComponent = React.createRef();
  const prevButton = React.createRef();
  const nextButton = React.createRef();
  const iframeContainer = React.createRef();

  const frames = props.frames.map((frame, idx) => {
    frame.id = idx + 1;
    return frame;
  });

  const singleFrame = frames.length === 1;

  const handleSliderChange = (e) => {
    clearTimeout(scrollTimeout);

    const newValue = parseInt(e.target.value);
    setActiveFrame(newValue);
  };

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
    stopAutoplay();
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
    stopAutoplay();
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
    playAutoplay();
  };

  const nextFrame = () => {
    clearTimeout(scrollTimeout);
    if (activeFrame < frames.length) {
      setActiveFrame(activeFrame + 1);
    }
  };

  const prevFrame = () => {
    clearTimeout(scrollTimeout);
    if (activeFrame > 1) {
      setActiveFrame(activeFrame - 1);
    }
  };

  const iframeCookies = (e) => {
    e.wrapper.querySelector('iframe').setAttribute('data-cookieconsent', 'marketing');
    e.wrapper.querySelector('iframe').setAttribute('data-cookieblock-src', '');
  }

  const startVideo = (videoId) => {
    const videoToPlay = frames[videoId].video;
    setActiveVideo(videoToPlay.src);
  };

  const stopAutoplay = () => {
    setActiveAutoplay(false);
  };

  const playAutoplay = () => {
    setActiveAutoplay(true);
  };

  const stopVideo = () => {
    setActiveVideo(null);
    setModalIsVisible(false);
  };


  useEffect(() => {
    if (activeAutoplay !== false) {
      let scrTimeout;
      if (activeFrame < frames.length) {
        scrTimeout = setTimeout(() => {
          setActiveFrame(activeFrame + 1);
        }, 6000);
      } else {
        scrTimeout = setTimeout(() => {
          setActiveFrame(1);
        }, 6000);
      }
      setScrollTimeout(scrTimeout);
    } else {
      clearTimeout(scrollTimeout);
    }
  }, [activeFrame, activeAutoplay]);


  useEffect(() => {

    if (activeVideo) {
      setModalIsVisible(true);
    }
  }, [activeVideo, iframeContainer]);

  useSliderSwipe(headerSliderComponent, setActiveFrame, frames.length, setActiveAutoplay);
  useHeroParallax('.component__header-slider .backgrounds');

  return (
    <>
      <div
        className="module grid full-width component__header-slider"
        data-active-frame={activeFrame}
        data-single-frame={singleFrame}
        ref={headerSliderComponent}
        data-combined={props.combined}
      >
        <div className="backgrounds full-width">
          {frames.map((frame, idx) => {
            const { image, velo, previewImage, id } = frame;

            return typeof image !== 'undefined' ? (
              <div
                className={`img-container ${velo ? '' : 'has-veil'}`}
                key={`hsb-${id}`}
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            ) : (
              <div
                className={`video-container ${velo ? '' : 'has-veil'}`}
                key={`hsb-${id}`}
                style={{ backgroundImage: `url(${previewImage})` }}
              ></div>
            );
          })}
        </div>
        <div className="content-blocks">
          {frames.map((frame, idx) => {
            const { title, body, cta, id } = frame;

            const ctaMarkup = {
              link: (
                <Link path={cta.target} className="circled-arrow-link">
                  <ArrowRight width="70" height="70" viewBox="0 0 94 94" />
                </Link>
              ),
              play: (
                <button data-frame-id={idx} onClick={() => startVideo(idx)} className="circled-button">
                  <Play width="70" height="70" viewBox="0 0 94 94" />
                </button>
              ),
            };

            return (
              <div className="block" data-content-block-id={id} key={`hscb-${id}`}>
                <h2 className="title--xl">{title}</h2>
                <p className="body body--l">{body}</p>
                {ctaMarkup[cta.type]}
              </div>
            );
          })}
        </div>
        <div className="controls">
          <input type="range" min={1} max={frames.length} value={activeFrame} step={1} onChange={handleSliderChange} />
          {frames.length > 1 && (
            <>
              <div className="slider-controls">
                <button
                  ref={prevButton}
                  className="prev-button"
                  onMouseOver={stopAutoplay}
                  onMouseOut={playAutoplay}
                  onClick={prevFrame}
                >
                  <IconArrowRightLong />
                </button>
                <button
                  ref={nextButton}
                  className="next-button"
                  onMouseOver={stopAutoplay}
                  onMouseOut={playAutoplay}
                  onClick={nextFrame}
                >
                  <IconArrowRightLong />
                </button>
              </div>
              <p className="frame-position-indicator">
                <span className="active">{`0${activeFrame}`}</span>
                <span className="last">{`0${frames.length}`}</span>
              </p>
            </>
          )}
        </div>
        {frames.length > 1 && (
          <>
            <button
              className="ghost-frame-button ghost-frame-button--left"
              onMouseOver={prevHover}
              onMouseOut={removeHover}
              onClick={prevFrame}
            ></button>
            <button
              className="ghost-frame-button ghost-frame-button--right"
              onMouseOver={nextHover}
              onMouseOut={removeHover}
              onClick={nextFrame}
            ></button>
          </>
        )}
      </div>

      {modalIsVisible && (
        <div ref={iframeContainer}>
          <Lightbox isVisible={modalIsVisible} closeMethod={stopVideo}>
            <ReactPlayer onReady={(e)=> iframeCookies(e)} url={activeVideo} width="100%" height="auto" playing={true} controls={false} />
          </Lightbox>
        </div>
      )}
    </>
  );
};

export default HeaderSlider;
