import React from 'react';
import { TagTitle } from 'React/components';

import './styles.scss';

const DetalleTexto = () => {

  return (
    <div className="grid module-detalle-texto">
      <div className="grid-container">
        <TagTitle style="slow--y" title="Información adicional" />
      </div>
      <div className="list-container grid full-width">
        <ul>
          <li>
            <div className="title--s">Cliente</div>
            <div className="body--l">Ayuntamiento de Murcia</div>
          </li>
          <li>
            <div className="title--s">Titularidad</div>
            <div className="body--l">En propiedad</div>
          </li>
        </ul>
        <ul>
          <li>
            <div className="title--s">POTENCIA TOTAL</div>
            <div className="body--l">24.000W</div>
          </li>
          <li>
            <div className="title--s">Cuenca fluvial</div>
            <div className="body--l">
              <p>2 túneles de 7 km y 1 diámetro de 9 metros ejecutados tuneladora EPB.</p><p>2 túneles de 7 km y 1 diámetro de 9 metros ejecutados tuneladora EPB.</p></div>
          </li>
        </ul>
      </div>
    </div>
  )
};

export default DetalleTexto;
