export default {
  meta: {
    title: 'Sobre Acciona',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          pageTitle: 'Sobre Acciona'
        }
      },  	  
      {
        component: 'BloqueTexto',
        props: {
          title: 'gobierno en materia de sostenibilidad',
          mainText: ['Las actuaciones en sostenibilidad se lideran desde el máximo nivel de la compañía. Desde el año 2009 ACCIONA cuenta con una Comisión de Sostenibilidad en el Consejo de Administración'],
          children: [          
            {
                component: 'Descargas',
                props: {
                  type: 'component',
                  downloads: [
                    'Mejora en las condiciones de vida de las comunidades',
                    'Mejora de la economía de las comunidades',
                    'Creación de empleo local y fomento del autoempleo local a través de microempresas',
                  ],			 
                  }
                }	              
          ]
        },
      },   
      {
        component: 'Cifras',
        props: {
			title: 'Nuestra compañía en cifras',
			frames: [
				{
					value: '+38.544',
					unit: 'empleados',
				},
				{
					value: '123',
					unit:  'nacionalidades'
				},
				{
					value: '790',
					unit: 'Hm3 de volumen de agua tratada',
				},											
			],
			module_title: 'Capacidad y producción de ACCIONA en Energía',
			lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
			module_description: 'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
			module_cta: {
				path: '#sample_path',
				label: 'Más sobre la capacidad y producción'
			}
        }
      },	
     {
        component: 'Wysiwyg',
        props: {
			html: `
				<div class="text-container">
					<p>El Plan de Acción Social se estructura en torno a los siguientes ejes de actuación:</p>
					<h3>1. Acceso a servicios básicos: Energía, Agua e Infraestructuras</h3>
						<ul>
						<li>50.100 personas beneficiarias.</li>
						<li>Nº Centros Luz en Casa: 19</li>
						<li>Nº de sistemas fotovoltaicos instalados: 12.135</li>
						</ul>
					<h3>2. Promoción de la sostenibilidad</h3>
						<ul>
						<li>6.500 niños han participado en el Aula de Sostenibilidad en México</li>
						<li>3.745.381 usuarios registrados en Sostenibilidad para Todos</li>
						<li>Más de 4,6 millones de visitas a Sostenibilidad para todos.</li>
						</ul>
					<h3>3. Inversión social asociada a proyectos</h3>
						<ul>
						<li>850.158 personas beneficiarias</li>
						<li>584 iniciativas llevadas a cabo</li>
						<li>22 países con iniciativas sociales</li>
						</ul>
					<h3>4. Programa de voluntariado</h3>
						<ul>
						<li>1.536 voluntarios de 20 países</li>
						<li>11.433 horas dedicadas de los voluntarios a trabajos en la comunidad</li>
						<li>44 iniciativas de voluntariado</li>
						</ul>
					<h3>5. Patrocinio y mecenazgo: salud, educación y cultura</h3>
						<ul>
						<li>246.590 personas beneficiarias</li>
						</ul>
				</div>
			`,
        },
      },              
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: [
                    { url: '#project_1', label: 'Project One'},
                    { url: '#project_2', label: 'Project Two'},
                    { url: '#project_3', label: 'Project Three'},
                  ],
                  type: 'download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: '#project_1',
                  type: 'download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: '#project_1',
                  type: 'download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: '#project_1',
                  type: 'download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: '#project_1',
                  type: 'download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
    
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                link: {
                  path: '#project_1',
                  type: 'download',
                }
              }
            },
          ],
        },
      },                                    
    ]
  }
};
