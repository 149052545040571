import React, { useEffect } from 'react';

import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import { ReactComponent as IconWater } from 'Icons/water-24.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

import { useObserver } from 'React/custom_hooks';
import { TimelineMax, TweenMax } from 'gsap/all';

import './style.scss';

const ModuleNews = ({ tagLine, content, moduleCta, type }) => {
  const newsCol = content.map((item, idx) => {
    const refList = content.map((item) => React.createRef());

    if (typeof window != undefined) {
      const [observer, setElements, entries] = useObserver({
        root: null,
        threshold: 0.25,
      });

      const tl = new TimelineMax({ delay: 0, repeat: 0 });

      useEffect(() => {
        // setting elements to observe
        const elements = document.querySelectorAll('.module-news .slow--y');
        TweenMax.set(elements, { opacity: 0, y: 90 });
        setElements(elements);
      }, [setElements]);

      useEffect(() => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let lazyItem = entry.target;
            tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
            observer.unobserve(lazyItem);
          }
        });
      }, [entries, observer, TimelineMax]);
    }

    const onHover = (event) => {
      const { refId } = event.currentTarget.dataset;
      refList[parseInt(refId)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
      const { refId } = event.currentTarget.dataset;
      refList[parseInt(refId)].current.classList.remove('is-hovered');
    };

    const { title, category, tag, date, text, href } = item;
    const maxTextLength = 100;

    if (type === 'hechos') {
      return (
        <div className="module-news__col">
          {title && (
            <a href="#" className="link slow--y" title={title}>
              <h2 className="title--s">{title}</h2>
            </a>
          )}
          <div className="tag title--small slow--y">
            <span className="date-calendar">
              <IconCalendar />
              {date}
            </span>
          </div>
          <div className="slow--y">
            <Link type="download" label="Registro: 30824" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="module-news__col slow--y" key={`news-${idx}`} ref={refList[idx]}>
          {title && (
            <a href="#" className="link" title={title} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
              <h2 className="title--s">{title}</h2>
            </a>
          )}
          <div className="tag title--small">
            <a href="#" className="link icon--red" title={category}>
              {!tag && <IconWater width="16" height="16" viewBox="0 0 24 24" />}
              {category && <span>{category}</span>}
              {tag && <span>{tag}</span>}
            </a>
            <span className="date">{date}</span>
          </div>
          <div>
            <p className="body--m">{text.length < maxTextLength ? text : `${text.substr(0, maxTextLength)}...`}</p>
          </div>
          <Link type="arrowLong" className="arrow" />
        </div>
      );
    }
  });

  return (
    <section className="module module-news grid">
      {tagLine && (
        <div className="module-news__header slow--y">
          <TagTitle title={tagLine} />
          <Link type="arrow" label="Ver todas las noticias" />
        </div>
      )}

      <div className="grid-container content-wrapper">{newsCol}</div>
      {moduleCta && (
        <div className="cta-footer">
          <Link type="arrow" label="Ver todas las noticias" />
        </div>
      )}
    </section>
  );
};

export default ModuleNews;
