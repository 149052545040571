export default {
  meta: {
    title: 'Formulario Newsletter',
  },
  content: {
    modules: [
      {
        component: 'SubscriptionForm',
        props: {
          color: 'white',
          action: '/subscription-API-endpoint',
          newsletterType: 'generic',
          title: 'Suscríbete a nuestra newsletter',
          descr:
            'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
          checkboxLabel:
            'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white" href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
          confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
        },
      },
    ],
  },
};

