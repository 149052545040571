// button.component.js
import React from 'react';
import {Link} from 'React/components';

const DistributorSimple = ({orientation='j', title='t', href, label}) => (
  <div className={`module-distributor-simple ${orientation}`}>
    <h2 className="title--m">{title}</h2>
  	<Link href={href} label={label}/>
  </div>
);

export default DistributorSimple;