// button.component.js
import React, { useEffect } from 'react';
import './styles.scss';
import { Link } from 'React/components';

import { ReactComponent as IconPhone } from 'Icons/phone.svg';
import { ReactComponent as IconEmail } from 'Icons/email.svg';
import { useSlowFade } from '../../custom_hooks';

const Banners = ({ moduleTitle, title, linkLightbox, description, type, frames }) => {
  const moduleRef = React.createRef();

  useSlowFade(moduleRef);

  if (type === 'motosharing') {
    return (
      <div ref={moduleRef} className="module module-banners slow--y">
        <div className="grid">
          <div className="banner-container slow--y">
            <div className="banner-item slow--y">
              <h2 className="title--m mb-2 slow--y">{title}</h2>
              {description && <p className="body--m slow--y">{description}</p>}
              {linkLightbox && <Link type="lightbox" className="slow--y" label="Contactar" />}
            </div>
            <div className="banner-item slow--y">
              <div className="banner-contact">
                <div>
                  <a className="title--s slow--y" href="tel:+18506484200">
                    <IconPhone />
                    TEL +34 948 16 68 00{' '}
                  </a>
                </div>
                <div>
                  <a className="title--s slow--y" href="fax:+18506484200">
                    <IconPhone />
                    FAX +34 948 16 68 00
                  </a>
                </div>
                <a href="mailto:hola@email.com" className="item-email body--l slow--y">
                  <IconEmail />
                  <span className="body--m slow--y">
                    <span className="link--black">responsabilidadcorporativa@acciona.com</span>
                  </span>
                </a>
                <p className="body--m mt-2 slow--y">
                  Información sobre{' '}
                  <a href="#" className="link--red">
                    protección de datos
                  </a>
                </p>
                <div className="horario mt-4 slow--y">
                  <div className="taglabel">Horario</div>
                  <p className="body--m">Lunes a Jueves de 8:30h a 17:30h</p>
                  <p className="body--m">Viernes de 8:00h a 15:00h</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (type === 'emails') {
    return (
      <div className="banner-item__content slow--y">
        <h2 className="title--s slow--y">Departamentos</h2>
        <div className="banner-contact banner-contact--emails">
          <div>
            <a href="mailto:hola@email.com" className="item-email body--l slow--y">
              <IconEmail />
              <span className="title--xxs">Canal Empleo</span>
              <span className="link">responsabilidadcorporativa@acciona.com</span>
            </a>
            <a href="mailto:hola@email.com" className="item-email body--l slow--y">
              <IconEmail />
              <span className="title--xxs">Sostenibilidad</span>
              <span className="link">responsabilidadcorporativa@acciona.com</span>
            </a>
            <a href="mailto:hola@email.com" className="item-email body--l slow--y">
              <IconEmail />
              <span className="title--xxs">Canal Empleo</span>
              <span className="link">responsabilidadcorporativa@acciona.com</span>
            </a>
            <a href="mailto:hola@email.com" className="item-email body--l slow--y">
              <IconEmail />
              <span className="title--xxs">Sostenibilidad</span>
              <span className="link">responsabilidadcorporativa@acciona.com</span>
            </a>
          </div>
        </div>
        <Link className="slow--y" type="arrow" href={link} label="Información sobre protección de datos" />
      </div>
    );
  } else {
    return (
      <div ref={moduleRef} className="module module-banners">
        <div className="grid">
          {moduleTitle && <h2 className={`slow--y title--tag`}>{moduleTitle}</h2>}
          <div className="banner-container slow--y">
            {frames.map((frame, idx) => {
              const { title, content, tel, email, link, linkTitle, id } = frame;

              return (
                <div className="banner-item slow--y" key={`data-point-${idx}`}>
                  <div className="banner-item__content">
                    <h2 className="title--s slow--y">{title}</h2>
                    <p className="body--m slow--y">{content}</p>
                    {tel && (
                      <div className="banner-contact slow--y">
                        <div>
                          <a className="title--s slow--y" href="tel:+18506484200">
                            <IconPhone />
                            {tel}
                          </a>
                        </div>
                        <div>
                          <a href="mailto:hola@email.com" className="slow--y item-email body--l">
                            <IconEmail />
                            {email}
                          </a>
                        </div>
                      </div>
                    )}
                    <Link className="slow--y" type="arrow" href={link} label={linkTitle} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default Banners;
