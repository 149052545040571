export default {
  meta: {
    title: 'Projects Map',
  },
  content: {
    modules: [
      {
        component: 'ProjectsMap',
        props: {
          title: 'Invertimos en el planeta',
          module_cta: {
            label: 'Ver todos los proyectos',
            path: '#areas-de-activdad_portada',
          },
          // initMapCenter: 2,
          projectsList: [
            {
              lat: 33.5724108,
              lng: -7.6570318,
              location: 'Casablanca',
              solution: 'energia',
              name: 'Casablanca Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/proyectos_930x600_energia.svg',
              url: '#project_path_Casablanca',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.566535,
              lng: 133.9779692,
              location: 'Okayama',
              solution: 'agua',
              name: 'Okayama Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 39.6777642,
              lng: 135.4160251,
              location: 'Sea of Japan',
              solution: 'movilidad',
              name: 'Sea Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.6777642,
              lng: 135.4160251,
              location: 'Osaca',
              solution: 'energia',
              name: 'Osaca Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/proyectos_930x600_energia.svg',
              url: '#project_path_Osaca',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: 47.5546492,
              lng: 7.5594407,
              location: 'Bazel',
              solution: 'capital',
              name: 'Bazel Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bazel',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 59.965,
              lng: 30.235,
              location: 'St.Petersburg',
              solution: 'agua',
              name: 'St.Petersburg Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_St',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: -1.2863888888889,
              lng: 36.817222222222,
              location: 'Kenya',
              solution: 'social',
              name: 'Kenya Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Kenya',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 12.97194,
              lng: 77.59369,
              location: 'Bangalore',
              solution: 'energia',
              name: 'Bangalore Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bangalore',
              info: 'Nuestro impacto positivo en el planeta',
            },
          ],
        },
      },
    ],
  },
};
