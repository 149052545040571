export default {
  meta: {
    title: 'Template detalle de persona',
	templateClass: 'template-detalle-persona template-ancho-completo'
  },
  content: {
    modules: [		
      {
        component: 'Breadcrumbs',
        props: {
			backLink: 'Volver',
			type: 'fixed--empleo',
			firstLevel: 'Inicio',
			secondLevel: 'Accionistas e Inversores',
			fourthLevel: 'Información financiera',
			pageTitle: 'José Manuel Entrecanales',
			cargo: 'Presidente ejecutivo de acciona'
        }
      }, 
   {
        component: 'Wysiwyg',
        props: {
          html: `
            <div class="image-featured"><div class="image-content"><img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_L.jpg"></div></div>
<div class="text-container"><ul><li>Mejora en las condiciones de vida de las comunidades&gt;</li><li>Mejora de la economía de las comunidades&gt;</li><li>Creación de empleo local y fomento del autoempleo local a través de microempresas&gt;</li><li>Integración laboral de personas en riesgo de exclusión social.&gt;</li><li>Acceso a iluminación eléctrica&gt;</li><li>Mejoras en la salud de las comunidades&gt;</li><li>Acceso a la educación&gt;</li><li>Mejora de las infraestructuras públicas&gt;</li><li>Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos&gt;</li><li>Fomento del deporte&gt;</li></ul><p>ACCIONA prevé poner en servicio ocho proyectos de esa cartera antes de que finalice 2023, añadiendo unos 1.500 MW de potencia pico (unos 1.200 MW nominales) a su parque de activos renovables en Norteamérica, donde cuenta con 1.245 MW eólicos operativos y en construcción. La compañía gestiona asimismo en propiedad una planta termosolar de 64 MW cerca de Las Vegas. </p>
<div class="blockquote"><blockquote>Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda</blockquote></div>
<h3>Título pequeño</h3>
<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>

<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>
<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>
            `
        },
     },   
	 {
		 component: 'ModuloContenidoDev',
		 props: {

		 }
	 },	 
      {
        component: 'RelatedItems',
        props: {
			items: [
				{
					personCargo: 'Presidente Ejecutivo de Acciona',
					icon: true,
					personName: 'José Manuel Entrecanales',
					personPicture: true,
					link: true,				
				},
				{
					personCargo: 'Presidente Ejecutivo de Acciona',
					icon: true,
					personName: 'José Manuel Entrecanales',
					personPicture: true,
					link: true,				
				}				
			]

      	}   
	  },
	 {
		 component: 'ModuloContenidoDev',
		 props: {
			 
		 }
	 },	 	  	  	  	  
    ]
  }
};
