export default {
  meta: {
    title: 'Detalle Solución',
    stickyHeader: false,
    templateClass: 'template-ancho-completo-imagen',
  },
  content: {
    modules: [
      {
        component: 'withSolucionesNav',
        props: {
          children: [
            {
              component: 'HeaderSlider',
              props: {
                frames: [
                  {
                    video: {
                      src: 'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4',
                    },
                    previewImage:
                      'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
                    title: 'Energía limpia para un sistema energético sostenible',
                    body:
                      'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
                    cta: { type: 'play' },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - frame único con texto',
          frames: [{ value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' }],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' },
        },
      },
      {
        component: 'ProjectsMap',
        props: {
          title: 'Invertimos en el planeta',
          initMapCenter: 2,
          projectsList: [
            {
              lat: 33.5724108,
              lng: -7.6570318,
              location: 'Casablanca',
              solution: 'energia',
              name: 'Casablanca Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Casablanca',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 39.6777642,
              lng: 135.4160251,
              location: 'Seul',
              solution: 'industrial',
              name: 'Seul Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.6777642,
              lng: 135.4160251,
              location: 'Osaca',
              solution: 'energia',
              name: 'Osaca Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Osaca',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: 47.5546492,
              lng: 7.5594407,
              location: 'Bazel',
              solution: 'movilidad',
              name: 'Bazel Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bazel',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 59.965,
              lng: 30.235,
              location: 'St.Petersburg',
              solution: 'agua',
              name: 'St.Petersburg Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_St',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: -1.2863888888889,
              lng: 36.817222222222,
              location: 'Kenya',
              solution: 'social',
              name: 'Kenya Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Kenya',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 12.97194,
              lng: 77.59369,
              location: 'Bangalore',
              solution: 'energia',
              name: 'Bangalore Project',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bangalore',
              info: 'Nuestro impacto positivo en el planeta',
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacadoSlider',
        props: {
          frames: [
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
          ],
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider',
          frames: [
            {
              value: '22.087',
              unit: 'GWh',
              caption: 'producidos en 2018',
              descr:
                'some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1some descriptive text for frame 1',
            },
            { value: '82,322', unit: 'MM', caption: 'caption 2' },
            { value: '7.321', unit: 'K', caption: 'caption 3', descr: 'some descriptive text for frame 3' },
            { value: '99,23', unit: '%', caption: 'satisfaction' },
            { value: '+38.544', caption: 'empleados' },
          ],
          // module_description: 'Garantizamos energía limpia y 100% renovable equivalente al consumo de 6 millones de hogares.'
        },
      },
      {
        component: 'AreasActividad',
        props: {
          title: 'Áreas de actividad',
          module_cta: {
            label: 'Todas las áreas de actividad de energia',
            path: '#areas-de-activdad_portada',
          },
          frames: [
            {
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 1',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro',
            },
            {
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 2',
              body:
                'Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!',
              link: '#hidro',
            },
            {
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 3',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro2',
            },
          ],
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - frame único con texto',
          frames: [{ value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' }],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' },
        },
      },
      {
        component: 'DistribuidorDestacado',
        props: {
          title: 'Nuestro propósito: Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image:
            'https://images.unsplash.com/photo-1522735338363-cc7313be0ae0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - frame único con texto',
          frames: [{ value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' }],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' },
        },
      },
      {
        component: 'ModuleNews',
        props: {
          tagLine: 'Módulo Noticias',
          cta: true,
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              category: 'Agua',
              date: 'Marzo 2018',
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacado',
        props: {
          title: 'Nuestro propósito: Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image:
            'https://images.unsplash.com/photo-1522735338363-cc7313be0ae0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
        },
      },
      {
        component: 'TrabajaConNosotrosSimple',
        props: {
          class: 'has-header',
          title: 'Trabaja con nosotros',
          cta: 'Ver todas las ofertas de energía',
          frames: [
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca Project Junior Engineer',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText:
                'Dar soporte y ayuda técnica para la ejecución de las obras, bajo las directrices del Jefe de Producción, en los aspectos técnicos, de control o de calidad, con el fin de asegurar una correcta ejecución de la obra.',
            },
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Gestión Contractual',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText:
                'Conocimiento de los diferentes aspectos jurídico-legales, de acuerdo con la normativa vigente, para defender los intereses de la división de Infraestructuras de ACCIONA y el cumplimiento de sus obligaciones jurídicas.',
            },
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Administración-Costes',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar apoyo a los procesos de Administración y costes de los proyectos actualmente en ejecución.',
            },
          ],
        },
      },
      {
        component: 'Banners',
        props: {
          moduleTitle: 'Módulo Banners',
          frames: [
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
          ],
        },
      },
    ],
  },
};
