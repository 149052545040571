export default {
  meta: {
    title: '[WRK] - Modulo Proyetos',
  },
  content: {
    modules: [
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Inversión social en proyectos de energía en México',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: 'm³',
                caption: 'capacidad',
              },
              project: {
                name: 'Parques eólicos de Oxaca',
                type: 'energía',
                location: 'Oxaca, México',
                info: true,
                link: {
                  path: [
                    { url: '#project_1', label: 'Project One' },
                    { url: '#project_2', label: 'Project Two' },
                    { url: '#project_3', label: 'Project Three' },
                    { url: '#project_1', label: 'Project Four' },
                    { url: '#project_2', label: 'Project Five' },
                    { url: '#project_3', label: 'Project Six' },
                  ],
                  type: 'link download',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: 'm³',
                caption: 'capacidad',
              },
              project: {
                info: true,
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'link internal',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'civil',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'civil',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - imagen único',
          cta: 'Ver todos los proyectos',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_3',
                  type: 'link internal',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - Enlace externo',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_3',
                  type: 'link external',
                },
              },
            },
          ],
        },
      },
    ],
  },
};
