export default {
  meta: {
    title: '[WRK] - Modulo Distribuidor 2 Columnas',
  },
  content: {
    modules: [
      {
        component: 'Distribuidor2Col',
        props: {
          tagLine: 'Distribuidor 2 Columnas',
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'Reactor Biológico Secuencial, BRAINYSBR®',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              downloadItem: 'Descárgate toda la información',
              fileParams: 'PDF 814KB'
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'CODIGESTIÓN',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'Título',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información',
            }                      
          ]
        },
      },
      {
        component: 'Distribuidor2Col',
        props: {
          tagLine: 'Distribuidor 2 Columnas',
          items: [
            {
              title: 'Reactor Biológico Secuencial, BRAINYSBR®',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              downloadItem: 'Descárgate toda la información',
              fileParams: 'PDF 814KB'
            },
            {
              title: 'CODIGESTIÓN',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información',
            },
            {
              title: 'Título',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información',
            }                      
          ]
        },
      }      
            
    ],
  },
};
