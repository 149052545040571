export default {
  meta: {
    title: 'Page equipo directivo',
    templateClass: 'template-equipo-directivo',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Gobierno corporativo',
          pageTitle: 'Equipo de dirección (46)',
        },
      },
      {
        component: 'EquipoDirectivoDestacado',
        props: {
          title: 'Comité de dirección',
          frames: [
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
          ],
          cta: 'Ver nuestros propósitos',
        },
      },
      {
        component: 'EquipoDirectivoDestacado',
        props: {
          frames: [
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
          ],
          cta: 'Ver nuestros propósitos',
        },
      },
      {
        component: 'EquipoDirectivo',
        props: {
          tagTitle: 'Testimonios con audio',
          frames: [
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
            },
          ],
          cta: 'Ver nuestros propósitos',
        },
      },
      {
        component: 'BuscadorFiltros',
        props: {
          type: 'equipo',
          title: 'Equipo de dirección',
        },
      },
      {
        component: 'EquipoDirectivo',
        props: {
          tagTitle: 'Testimonios con audio',
          frames: [
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
            {
              status: 'Presidente ejecutivo',
              personName: 'Jose manuel entrecanales',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
            },
          ],
          cta: 'Ver nuestros propósitos',
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
      {
        component: 'Banners',
        props: {
          moduleTitle: 'Módulo Banners',
          frames: [
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              tel: '956 56 23 70',
              email: 'hola@email.com',
              link: '#',
              linkTitle: 'Contactar',
            },
          ],
        },
      },
    ],
  },
};
