export default {
  meta: {
    title: 'Informe Anual Integrado 2018',
    templateClass: 'template-cta template-ancho-completo-imagen',
    stickyHeader: false
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
          title: 'Trabajamos para dar respuesta a los grandes desafíos del planteta',
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          type: 'fixed',
          className: 'anchors-fixed',
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Información financiera',
          fourthLevel: 'Informe Anual Integrado',
          pageTitle: 'Informe anual integrado 2018',
          label: 'Enlace',
          anchor: 'Ancla 1',
          secondAnchor: 'Ancla 2',
          thirdAnchor: 'Ancla 3',
          fourthAnchor: 'Ancla 4',
        },
      },
      {
        component: 'TestimoniosSlider',
        props: {
          tagTitle: 'Testimonios con audio',
          frames: [
            {
              blockquote:
                'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
              personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
              cta: 'Ver nuestros propósitos',
            },
            {
              blockquote:
                'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
              personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
          ],
        },
      },
      {
        component: 'Soluciones',
        props: {
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              title: 'Nuestro impacto positivo en la tierra',
              leadText: 'Civil',
            },
          ],
        },
      },
      {
        component: 'Soluciones',
        props: {
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              title: 'Nuestro impacto positivo en la tierra',
              leadText: 'Civil',
            },
          ],
        },
      },
      {
        component: 'Soluciones',
        props: {
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              title: 'Nuestro impacto positivo en la tierra',
              leadText: 'Civil',
            },
          ],
        },
      },
      {
        component: 'Cifras',
        props: {
          title: 'Nuestra compañía en cifras',
          type: 3,
          dataCaption: 'Datos de 2019',
          dataCaptionExtra: '* Dato del 2018',
          frames: [
            {
              value: '0,3',
              unit: '%',
              secondValue: '+3,50%',
              caption: 'contribución al PIB*',
            },
            {
              value: '200',
              unit: 'K',
              secondValue: '-2,40%',
              caption: 'invertidos en el proyecto',
            },
            {
              value: '2',
              unit: 'MM',
              secondValue: '+42,80M€',
              caption: 'de personas abastecidas',
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: 'm³',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'internal',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'civil',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'download',
                },
              },
            },
          ],
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - dos frames con texto',
          frames: [
            { value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' },
            {
              value: '22.087',
              unit: 'GWh',
              caption: 'prodicidos en 2018',
              descr: 'longer description for the second frame',
            },
          ],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' },
        },
      },
      {
        component: 'DistribuidorIconos',
        props: {
          title: 'Distribuidor Iconos',
          frames: [
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacado',
        props: {
          title: 'Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          link: 'link internal',
        },
      },
      {
        component: 'ProjectsMap',
        props: {
          title: 'Invertimos en el planeta',
          // initMapCenter: 2,
          projectsList: [
            {
              lat: 33.5724108,
              lng: -7.6570318,
              location: 'Casablanca',
              solution: 'energia',
              name: 'Casablanca Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Casablanca',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.566535,
              lng: 133.9779692,
              location: 'Okayama',
              solution: 'agua',
              name: 'Okayama Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 39.6777642,
              lng: 135.4160251,
              location: 'Sea of Japan',
              solution: 'industrial',
              name: 'Sea Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.6777642,
              lng: 135.4160251,
              location: 'Osaca',
              solution: 'energia',
              name: 'Osaca Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Osaca',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: 47.5546492,
              lng: 7.5594407,
              location: 'Bazel',
              solution: 'movilidad',
              name: 'Bazel Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bazel',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 59.965,
              lng: 30.235,
              location: 'St.Petersburg',
              solution: 'agua',
              name: 'St.Petersburg Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_St',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: -1.2863888888889,
              lng: 36.817222222222,
              location: 'Kenya',
              solution: 'social',
              name: 'Kenya Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Kenya',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 12.97194,
              lng: 77.59369,
              location: 'Bangalore',
              solution: 'energia',
              name: 'Bangalore Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bangalore',
              info: 'Nuestro impacto positivo en el planeta',
            },
          ],
        },
      },
    ],
  },
};
