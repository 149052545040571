import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { Link } from 'React/components';

import './Styles.scss';

const TextWithCta = ({}) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  return (
    <div className="grid module module-textwithcta" ref={moduleRef}>
      <div className="grid-container">
        <p className="body--m slow--y">
          ACCIONA garantiza que los Hechos Relevantes aquí contenidos se corresponden exactamente con los remitidos a la
          CNMV, bien por la propia sociedad o por terceros, y difundidos por aquella. No obstante, y por circunstancias
          de carácter técnico, pueden producirse eventualmente discrepancias temporales en la publicación de esta
          información, pudiendo en todo caso consultar los hechos relevantes publicados en la página web de la Comisión
          Nacional del Mercado de Valores (CNMV)
        </p>
        <Link type="external" className="mt-4 slow--y" label="Comisión nacional del Mercado de valores" />
      </div>
    </div>
  );
};

export default TextWithCta;
