export default {
  meta: {
    title: 'Informe anual integrado',
    templateClass: 'template-ancho-completo'

  },
  content: {
   modules: [		
      {
        component: 'Breadcrumbs',
        props: {
			backLink: 'Volver',
			firstLevel: 'Inicio',
			secondLevel: 'Accionistas e Inversores',
			fourthLevel: 'Información financiera',
			pageTitle: 'Informe anual integrado',
			intro: 'Texto descriptivo'
        }
	  },
     {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo'
        }
	  }	,  	  
    {
        component: 'DistribuidorMultiple',
        props: {
		moduleTitle: 'Histórico de informes anuales (1)',

			frames: [
				{
					moduleLink: 'Junta general de accionistas 2018',
					title: 'Junta general de accionistas 2018',
					mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
					desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',				
					body: 'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
					link: 'Enlace',
				},
				{
					moduleLink: 'Junta general de accionistas 2017',
					mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
					desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',						title: 'Junta general de accionistas 2017',
					body: 'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
					linkGroup: {
						links: [
							{ url: '#project_1', label: 'Project One'},
							{ url: '#project_2', label: 'Project Two'},
							{ url: '#project_3', label: 'Project Three'},
						],
						label: 'Ver descargas',
					},
              		fileParams: 'PDF 814KB'
				},
				{
					moduleLink: 'Junta general de accionistas 2016',
					mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_M.jpg',
					desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',	
					title: 'Junta general de accionistas 2016',
					body: 'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
					link: 'Junta general de accionistas 2016',
					download: 'Acuerdos aprobados y resultado de las votaciones',
				}			
			]
      	}   
	  },  
      {
        component: 'Paginator',
        props: {
      	}   
	  },
	{
		component: 'Banners',
		props: {
			frames: [
			{
				title: 'Agenda del Inversor',
				content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
				link: '#',
				linkTitle: 'Ir a Agenda del Inversor'
			},
			{
				title: 'Agenda del Inversor',
				content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
				link: '#',
				linkTitle: 'Ir a Agenda del Inversor'
			},    
			{
				title: 'Agenda del Inversor',
				content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
				tel: '956 56 23 70',
				email: 'hola@email.com',
				link: '#',
				linkTitle: 'Contactar'
			},                            
			]            
		}
      },
	{
		component: 'Banners',
		props: {
			frames: [
			{
				title: 'Agenda del Inversor',
				content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
				link: '#',
				linkTitle: 'Ir a Agenda del Inversor'
			}                       
			]            
		}
      },	  	    	  
    ]
  }
};
