export default {
  meta: {
    title: '[WRK] - Modulo Solución destacada',
  },
  content: {
    modules: [
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Solución destacada cifra',
          isProject: false,
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',

              content: {
                cifra: '432.000',
                unit: 'M³',
                caption: 'capacidad',
              },
              project: {
                titleBottom: true,
                name: 'Energía',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Solución destacada frase',
          isProject: false,
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_L.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                titleBottom: true,
                name: 'Energía',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Solución destacada con cifra y texto',
          isProject: false,
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_L.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',

              content: {
                cifra: '9.767',
                unit: 'MW',
                caption: 'instalados',
                titleLeft: 'Acceso energía 100% renovable'
              },
              project: {
                titleBottom: true,
                name: 'Energia',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                }
              }
            },
          ],
        },
      },
    ],
  },
};
