// PDF rendering library
// https://github.com/wojtekmaj/react-pdf

import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';
import { Lightbox } from 'React/components';
import { Document, Page } from 'react-pdf';
import { rndKeyGen } from 'React/helpers';

const PDFreader = ({ sourceFile }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(280);

  const selfRef = useRef();

  useEffect(() => {
    const widthLimit = selfRef.current.querySelector('.lightbox__box').getBoundingClientRect().width;
    setPageWidth(widthLimit);
  }, []);

  useEffect(() => {
    if (totalPages === null) return;
  }, [totalPages]);

  return (
    <div className="pdf-reader" ref={selfRef}>
      <Lightbox isVisible={true}>
        <Document renderMode="canvas" file={sourceFile} onLoadSuccess={({ numPages }) => setTotalPages(numPages)}>
          {totalPages !== null &&
            Array.from(new Array(totalPages), (page, idx) => (
              <Page width={pageWidth} pageNumber={idx + 1} key={rndKeyGen()} />
            ))}
        </Document>
      </Lightbox>
    </div>
  );
};

export default PDFreader;
