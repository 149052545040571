import React, { useState, useEffect } from 'react';

import './styles.scss';

import { TagTitle, Link } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';

import { useSlowFade } from 'React/custom_hooks';

const EquipoDirectivoDestacado = ({ title, frames }) => {
  const [activeAudio, setActiveAudio] = useState(null);

  // slider functionality
  const thisModule = React.createRef();

  const prevButton = React.createRef();
  const nextButton = React.createRef();

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  useSlowFade(thisModule);

  // audio controls
  const audioElementRefs = frames.map((element) => React.createRef());
  const audioStatsRefs = frames.map((element) => React.createRef());

  const togglePlay = (frameId) => {
    setActiveAudio((oldState) => (oldState === frameId ? null : frameId));
  };

  useEffect(() => {
    audioElementRefs.forEach((ref) => {
      if (ref.current != null) {
        ref.current.pause();
      }
    });

    if (activeAudio !== null) {
      audioElementRefs[activeAudio - 1].current.play();
    }
  }, [activeAudio]);

  // audio stats
  const formatedAudioTime = (timeFloat) => {
    if (timeFloat === Infinity) return '<i>∞</i>';

    const seconds = parseInt(timeFloat);
    if (seconds < 60) return `0’${seconds}’’`;

    if (seconds >= 60) return `${parseInt(seconds / 60)}’${seconds % 60}’’`;

    return timeFloat;
  };

  useEffect(() => {
    audioElementRefs.forEach((audioEl, idx) => {
      if (audioStatsRefs[idx].current != null) {
        const statsElement = audioStatsRefs[idx].current;
        const [oldTime, oldDuration] = statsElement.innerText.split('/');

        audioEl.current.addEventListener('loadedmetadata', (event) => {
          const { duration } = event.target;

          statsElement.innerHTML = `${oldTime} / ${formatedAudioTime(duration)}`;
        });

        audioEl.current.addEventListener('timeupdate', (event) => {
          const { currentTime, duration } = event.target;

          statsElement.innerHTML = `${formatedAudioTime(currentTime)} / ${formatedAudioTime(duration)}`;
        });
      }
    });
    return () => {
      audioElementRefs.forEach((audioEl) => {
        audioEl.current.removeEventListener('loadedmetadata');
        audioEl.current.removeEventListener('timeupdate');
      });
    };
  }, []);

  return (
    <div className="module grid equipo-directivo-destacado" ref={thisModule}>
      <div className="slow--y equipo-directivo-destacado-container">
        {title && (
          <div className="grid-container">
            <TagTitle equipo title={title} />
          </div>
        )}

        <div className="frames-container grid full-width">
          {frames.map((frame, idx) => {
            const { status, personImage, personName, audio, icon, link } = frame;
            return (
              <div className="frame" key={`tecno-frame-${idx}`}>
                <div className="frame-content">
                  {personImage && (
                    <div className="person-picture">
                      <img src={personImage} />
                    </div>
                  )}

                  <div className="person-content">
                    <div className="person-name">
                      {status && <h3 className="title--m">{status}</h3>}
                      <div className="person-data">
                        <h2 className="title--s">
                          {personName} {icon && <IconDireccion />}
                        </h2>
                      </div>
                      {link && <Link type="arrow" label="Conoce más sobre el presidente de Acciona" />}
                      <div className="person-audio title--xs">
                        {audio && (
                          <div className="player-content">
                            <div className="audio title--xs">
                              <audio id="player" ref={audioElementRefs[idx]}>
                                <source src={audio.src} type="audio/mpeg" />
                              </audio>

                              {audio.label}
                              <button id="player-button" onClick={() => togglePlay(idx + 1)}>
                                <img src="http://accionacorporativa-front.redbilityservers.com/static/icons/video.svg" />
                              </button>
                              <span className="audio-stats" ref={audioStatsRefs[idx]}>
                                0'0'' / --
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EquipoDirectivoDestacado;
