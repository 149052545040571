export default {
  meta: {
    title: 'Module - EnlaceAgrupador',
  },
  content: {
    modules: [
      {
        component: 'GridWrapper',
        props: {
          style: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
          children: [
            {
              component: 'EnlaceAgrupador',
              props: {
                box_type: 'round-button',
                links: [
                  {
                    label: 'Link 1',
                    url: '#download_1',
                  },
                  {
                    label: 'Link 2',
                    url: '#external_2',
                    type: 'external',
                  },
                  {
                    label: 'Link 3',
                    url: '#internal_3',
                    type: 'internal',
                  },
                ],
              },
            },
            {
              component: 'EnlaceAgrupador',
              props: {
                box_label: 'Descargar documento - sample 1',
                links: [
                  {
                    label: 'Link 1',
                    url: '#external_1',
                    type: 'external',
                  },
                  {
                    label: 'Link 2',
                    url: '#external_2',
                    type: 'external',
                  },
                ],
              },
            },
            {
              component: 'EnlaceAgrupador',
              props: {
                box_label: 'Descargar documento - sample 2',
                links: [
                  {
                    label: 'Link 3',
                    url: '#download_3',
                  },
                  {
                    label: 'Link 4',
                    url: '#download_4',
                  },
                  {
                    label: 'Link 5',
                    url: '#download_5',
                  },
                  {
                    label: 'Link 6',
                    url: '#download_6',
                  },
                  {
                    label: 'Link 7',
                    url: '#download_7',
                  },
                  {
                    label: 'Link 8',
                    url: '#download_8',
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
