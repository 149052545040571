// to add more icon options, update IconsMap file inside the DistribuidorIconos component folder
export default {
  meta: {
    title: '[WRK] - Distribuidor Iconos',
  },
  content: {
    modules: [
      {
        component: 'DistribuidorIconos',
        props: {
          title: 'Distribuidor Iconos',
          frames: [
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
          ],
        },
      },
    ],
  },
};
