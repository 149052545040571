export default {
  meta: {
    title: '[WRK] - Home page',
    templateClass: 'template-home',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'https://source.unsplash.com/random/2000x960?windmill',
          title: 'Energía limpia para un sistema energético sostenible',
          body:
            'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
          cta: {
            type: 'link',
            target: '#some-cta',
          },
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          tagLine: 'Un cambio de paradigma',
          title: 'NUESTRa misión: Business as Unusual',
          mainText: [
            'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
          ],
          moduleCta: {
            label: 'Descubre nuestro propósito',
            target: '#some-resource',
          },
        },
      },
      {
        component: 'SolucionesBotonera',
        props: {
          title: 'Descubre nuestras soluciones',
          body:
            'Somos expertos en diseñar un planeta mejor. Ofrecemos soluciones para dar respuesta a los grandes desafíos globales a los que se enfrenta la humanidad y que marcarán el futuro del planeta.',
          cta: 'Accede a todas las soluciones',
        },
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider',
          frames: [
            {
              value: '22.087',
              unit: 'GWh',
              caption: 'producidos en 2018',
              descr:
                'some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1some descriptive text for frame 1',
            },
            { value: '82,322', unit: 'MM', caption: 'caption 2' },
            { value: '7.321', unit: 'K', caption: 'caption 3', descr: 'some descriptive text for frame 3' },
            { value: '99,23', unit: '%', caption: 'satisfaction' },
            { value: '+38.544', caption: 'empleados' },
          ],
          // module_description: 'Garantizamos energía limpia y 100% renovable equivalente al consumo de 6 millones de hogares.'
        },
      },
      {
        component: 'DistribuidorDestacadoSlider',
        props: {
          frames: [
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'https://source.unsplash.com/random/920x1100?wind',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Contribuimos al desarrollo sostenible',
              text:
                'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              image: 'https://source.unsplash.com/random/920x1100?energy',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
          ],
        },
      },

      {
        component: 'ModuleNews',
        props: {
          tagLine: 'Módulo Noticias',
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              category: 'Agua',
              date: 'Marzo 2018',
            },
          ],
        },
      },
    ],
  },
};
