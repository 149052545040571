export default {
  meta: {
    title: '[WRK] - Header slider',
  },
  content: {
    modules: [
      {
        component: 'HeaderSlider',
        props: {
          frames: [
            {
              image: 'https://source.unsplash.com/random/2560x1440?hydro',
              video: {
                src: 'https://youtu.be/eWpZ9mPEoOc',
                autoplay: false,
              },
              velo: true,
              title: 'Multi-frames example',
              body: 'To change the frame, swipe the module, drag horizontal white line or click/touch thin grey line.',
              cta: { type: 'play' },
            },
            {
              image: 'https://source.unsplash.com/random/2560x1440?solar',
              velo: true,
              title: 'Ever so slightly longer Title Two',
              body: 'Removes targets from a running animation or timeline.',
              cta: { type: 'link', target: '#slide-cta-2' },
            },
            {
              image: 'https://source.unsplash.com/random/2560x1440?nuclear',
              title: 'Significantly longer title here. How long can this one be?',
              body:
                'Several styles need to be applied to range inputs in all browsers to override their basic appearance.',
              cta: { type: 'link', target: '#slide-cta-3' },
            },
            {
              image: 'https://source.unsplash.com/random/2560x1440?infrastructure',
              title: 'Title Four',
              body:
                'The line that the thumb moves across is called the track. It is also styled just like a regular HTML element. Plays a paused animation, or starts the animation if the autoplay parameters is set to false. Removes targets from a running animation or timeline. The targets parameters accepts the same values as the targets property.',
              cta: { type: 'link', target: '#slide-cta-4' },
            },
          ],
        },
      },
      {
        component: 'HeaderSlider',
        props: {
          frames: [
            {
              image: 'https://source.unsplash.com/random/2560x1440?wind',
              title: 'Single-frame example',
              body:
                'Creates a Manager instance with a default set of recognizers and returns the manager instance. The default set contains tap, doubletap, pan, swipe, press, pinch and rotate recognizer instances. You should only use this when you’re fine with the default setup, or have set your own initial setup.',
              cta: { type: 'link', target: '#slide-cta-5' },
            },
          ],
        },
      },
    ],
  },
};
