export default {
  meta: {
    title: '[WRK] - Modulo Filtro',
  },
  content: {
    modules: [
      {
        component: 'ItemsFilter',
        props: {
          subject: 'eventos finalizados',
          can_filter_by: [ 'year' ],
          items: [
            { name: 'Event 1', year: 2011, country: 'España' },
            { name: 'Event 2', year: 2011, country: 'Francia' },
            { name: 'Event 3', year: 2018, country: 'Francia' },
            { name: 'Event 4', year: 2018, country: 'España'  },
          ]
        }
      }
    ]
  }
};
