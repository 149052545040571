export default {
  meta: {
    title: '[WRK] - Acordeon',
  },
  content: {
    modules: [
      {
        component: 'Acordeon',
        props: {
          tagLine: 'Acordeon - 2 col',
          moduleCTA: {
            label: 'Enlace interno',
            path: '#enlace',
            type: 'internal',
          },
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content: `
                <div class="image-container">
                  <div class="image">
                    <img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_M.jpg">
                  </div>
                  <div class="content">
                    <h3>Título pequeño</h3>
                  </div>
                </div>
                <div class="text-container">
                 <h4>Wysiwyg content<h4>
                 <ul>
                   <li> item 1 </li>
                   <li> item 2 </li>
                   <li> item 3 </li>
                 </ul>
                </div>
              `,
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_S.jpg',
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
          ],
        },
      },
      {
        component: 'Acordeon',
        props: {
          tagLine: 'Acordeon - 1 col sin imagenes',
          columns: 1,
          moduleCTA: {
            label: 'Enlace externo',
            path: '#enlace',
            type: 'external',
          },
          items: [
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
              content: `
                <div class="text-container">
                  <h1>el objetivo principal de este manual, titulo 1</h1>
                  <p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación.</p>
                  <p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación.</p>
                  <ul>
                    <li>item 1</li>
                    <li>item 1</li>
                  </ul>
                </div>
                <div class="image-container">
                  <div class="image">
                    <img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_M.jpg">
                  </div>
                  <div class="content">
                    <h3>Título pequeño</h3>
                  </div>
                </div>
                <div class="text-container"><h2>Título de módulo</h2><p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p><h3>Título pequeño</h3><p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p></div>    
                <div class="image-featured"><div class="image-content"><img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_L.jpg"></div><div class="image-caption">Raíz de sandía sin control de nematodo</div></div>
                <div class="blockquote"><blockquote>Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda</blockquote></div>
                <div class="text-container"><ul><li>Mejora en las condiciones de vida de las comunidades&gt;</li><li>Mejora de la economía de las comunidades&gt;</li><li>Creación de empleo local y fomento del autoempleo local a través de microempresas&gt;</li><li>Integración laboral de personas en riesgo de exclusión social.&gt;</li><li>Acceso a iluminación eléctrica&gt;</li><li>Mejoras en la salud de las comunidades&gt;</li><li>Acceso a la educación&gt;</li><li>Mejora de las infraestructuras públicas&gt;</li><li>Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos&gt;</li><li>Fomento del deporte&gt;</li></ul><ul><li>Lista<ul><li>Mejora en las condiciones de vida de las comunidades&gt;</li><li>Mejora de la economía de las comunidades&gt;</li><li>Creación de empleo local y fomento del autoempleo local a través de microempresas&gt;</li><li>Integración laboral de personas en riesgo de exclusión social.&gt;</li><li>Acceso a iluminación eléctrica&gt;</li><li>Mejoras en la salud de las comunidades&gt;</li><li>Acceso a la educación&gt;</li><li>Mejora de las infraestructuras públicas&gt;</li><li>Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos&gt;</li><li>Fomento del deporte&gt;</li></ul></li></ul><div><a class="link link--icon icon--red undefined" tabindex="0" href="/distundefined"><span>Enlace interno<svg width="18" height="18"><path fill="#000" fill-rule="nonzero" d="M10.92 4.707l4.147 4.146H1v1h14.067l-4.147 4.146.707.707 5.354-5.353L11.627 4z"></path></svg></span></a></div>
                <div class="link--download">
                <a class="icon--red link module--cta" href="#"><svg width="18" height="18"><path d="M2.5 12v3.5h13V12h1v4.5h-15V12h1zm7-12v11.167l3.382-3.381.707.707-4.596 4.596-4.597-4.596.708-.707 3.395 3.395L8.5 0h1z" fill="#1F140F" fill-rule="evenodd"></path></svg><p>Mejora en las condiciones de vida de las comunidades</p><p class="link--download-type">PDF 813KB</p></a>                
                </div>
                
                <div><a class="link link--icon icon--red link--external tabindex="0" href="/distundefined"><span>Enlace externo</span><svg width="13" height="15"><path d="M4 .5v1H1V14h11V9.5h1V15H0V.5h4zm9 0V7h-1V2.416l-7.424 7.46-.707-.708L11.502 1.5H6.5v-1H13z" fill="red" fill-rule="evenodd"></path></svg></a></div></div>                             
              `,
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
          ],
        },
      },
      {
        component: 'Acordeon',
        props: {
          tagLine: 'Acordeon - 2 col sin imagenes',
          // moduleCTA: {
          //   label: 'Enlace interno',
          //   path: '#enlace'
          // },
          items: [
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              title: 'Artículo 11. Acreditación de la condición de accionista',
              content:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              // downloadItem: 'Descárgate toda la información sobre esta tecnología',
            },
          ],
        },
      },
    ],
  },
};
