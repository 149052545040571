import React from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { Link } from 'React/components';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const SolucionesBotonera = ({ title, body, cta }) => {
  const moduleRef = React.createRef();

  useSlowFade(moduleRef);

  return (
    <div ref={moduleRef} className="module grid soluciones-botonera" id="soluciones-botonera">
      <div className="grid-container grid full-width soluciones-botonera__header">
        <h2 className="title title--l slow--y">{title}</h2>
        <p className="body body--m slow--y">
          {body}
          {cta && (
            <span className="cta grid-container slow--y mobile-hidden">
              <Link type="arrow" label={cta} />
            </span>
          )}
        </p>
        {cta && (
          <div className="cta grid-container slow--y mobile-visible">
            <Link type="arrow" label={cta} />
          </div>
        )}
      </div>
      <div className="grid-container grid full-width soluciones-botonera__content">
        <ul className="grid-container">
          <li className="title--s slow--y">
            <a href="#">
              <EnergyIcon />
              Energía
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <MovilidadIcon />
              Transporte
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <AguaIcon />
              Agua
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <SocialIcon />
              Social
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <CiudadesIcon />
              Ciudades
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <InmobiliariaIcon />
              Inmobiliaria
            </a>
          </li>
          <li className="title--s slow--y">
            <a href="#">
              <CapitalIcon />
              Capital
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SolucionesBotonera;
