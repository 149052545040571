import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ItemsFilter = ({ subject, items, can_filter_by }) => {
  const [ activeFilters, setActiveFilters ] = useState({});

  const getCurrentTitle = () => {
    const resultsCount = getFilteredItems(items, activeFilters).length;

    return Object.keys(activeFilters).length === 0
      ? `Todos los ${ subject } (${resultsCount})`
      : `Todos los ${ subject } de ${activeFilters[can_filter_by[0]]} (${resultsCount})`;
  }

  const getFilteredItems = (items, filters) => {
    return items.reduce((acc, item) => {
      let pass = true;

      Object.keys(filters).forEach((filterName) => {
        // we use non-strict matching here because filters can be of different types
        if (item[filterName] != filters[filterName]) pass = false
      });

      if (pass) acc.push(item);
      return acc
    }, [])
  }; 

  const updateFilters = (event) => {
    const { value } = event.target;
    const { filterType } = event.target.dataset;

    setActiveFilters((prevFilters) => {
      const newFilters = {...prevFilters };
      newFilters[filterType] = value;
      return newFilters;
    });
  };

  const clearFilters = () => {
    setActiveFilters({});
  };

  const getFiltersList = (filters, items) => {
    return filters
      .map((filter) => {
        return { 
          type: filter,
          options: [ ...new Set( 
            items.map((item) => item[filter]) 
          )]
        }
      }) 
    // remove filter if there are no items that have corresponding attribute
      .filter((item) => typeof item.options[0] !== 'undefined')
  };


  return (
    <div className="component items-filter">
      <h3 className="title--m">{ getCurrentTitle() }</h3> 
      <div className="filters-panel">{
        getFiltersList(can_filter_by, items).map((filter, idx) => {
          const { type, options } = filter;
          return (
            <FilterControl 
              filterType={type} 
              options={options} 
              handleChange={updateFilters}
              key={`${type}-${idx}`}
            />
          )
        })
      }
      <button onClick={clearFilters}>Clear</button>
    </div>
    <hr/>
    <div className="filtered-items">{
      getFilteredItems(items, activeFilters)
        .map((item, idx) => {
          const {name} = item;
          return (
            <div className="item-card" key={`ìtem-card-${idx}`}>
              <h5 className="title--s">{name}</h5>
            </div>
          )
        })
    }</div>
    </div>
  )
};

ItemsFilter.propTypes = {

  subject: PropTypes.string.isRequired,

  can_filter_by: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,

  items: PropTypes.array.isRequired,
};

// filter selector component
const FilterControl = ({ filterType, options, handleChange }) => {
  return (
    <select data-filter-type={filterType} onChange={ handleChange }>
      {options.map((option, idx) => (
        <option value={ option } key={ `${filterType}-${option}` }>{ option }</option>
      ))}
    </select>
  ) 
};

export default ItemsFilter;
