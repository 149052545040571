export default {
  meta: {
    title: 'Detalle Proyecto',
    templateClass: 'template-proyectos template-ancho-completo-imagen',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          className: 'component__hero-image--project',
          imageUrl: '/../static/formatos/formato_cabecera_XL.jpg',
          titleProject: 'Única en su diseño y construcción',
          cifraProject: '2',
          unitProject: 'MM',
          descriptionProject: 'MM de habitantes abastecidos',
          categoryProject: 'Agua',
          secondCategoryProject: 'Civil',
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          pageTitle: 'Proyectos',
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          map: true,
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          list: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
          downloads: ['Mejora en las condiciones de vida de las comunidades'],
        },
      },
      {
        component: 'DistribuidorDestacadoSlider',
        props: {
          frames: [
            {
              title: 'Nuestro impacto positivo en el planeta',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
              ods: 'http://accionacorporativa-front.redbilityservers.com/static/icons/ods.svg',
            },
            {
              title: 'Nuestro propósito: Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Nuestro propósito: Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Nuestro propósito: Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
          ],
        },
      },

      // {
      //   component: 'Mediateca',
      //   props: {
      //     titleTag: 'Mediateca',
      //     mediaList: [
      //       {
      //         src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
      //         src_type: 'image',
      //         preview_size: 'full',
      //         descr:
      //           'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
      //         media_cta: {
      //           label: 'Descargar documento',
      //           path: '#download_media_path',
      //           type: 'download',
      //           fileParams: 'PDF 1.2Mb',
      //         },
      //       },
      //       {
      //         src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
      //         src_type: 'image',
      //         preview_size: 'half',
      //         descr: 'Descripcion corta',
      //         media_cta: {
      //           label: 'Leer el estudio',
      //           path: '#internal_redirect_path',
      //           type: 'internal',
      //         },
      //       },
      //       {
      //         src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
      //         src_type: 'image',
      //         preview_size: 'half',
      //         descr: 'Descripcion un poco menos corta',
      //         media_cta: {
      //           label: 'Ver el articulo',
      //           path: '#extenral_redirect_path',
      //           type: 'external',
      //         },
      //       },
      //       {
      //         preview_url:
      //           'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
      //         src_url: 'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4',
      //         src_type: 'video',
      //         preview_size: 'full',
      //         descr: 'Un media item sin enlace',
      //       },
      //     ],
      //   },
      // },
      {
        component: 'Cifras',
        props: {
          title: 'Nuestra compañía en cifras',
          frames: [
            {
              value: '+38.544',
              unit: 'empleados',
            },
            {
              value: '123',
              unit: 'nacionalidades',
            },
            {
              value: '790',
              unit: 'Hm3 de volumen de agua tratada',
            },
          ],
        },
      },
      {
        component: 'Timeline',
        props: {
          framesList: [
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'titulo de ejemplo - 2',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'titulo de ejemplo - 2',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacado',
        props: {
          title: 'Nuestro propósito: Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image:
            'https://images.unsplash.com/photo-1522735338363-cc7313be0ae0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
        },
      },
      {
        component: 'DistribuidorMultiple',
        props: {
          moduleTitle: 'Descubre nuestro propósito',
          frames: [
            {
              mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              title: 'Premios Expansión Compilance 2018',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              link: 'Descubre nuestro propósito',
              download: 'Descárgate toda la información',
            },
          ],
        },
      },
      // {
      //   component: 'TestimoniosSlider',
      //   props: {
      //     tagTitle: 'Testimonios con audio',
      //     frames: [
      //       {
      //         blockquote:
      //           'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
      //         personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
      //         personImage:
      //           'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
      //         audio: {
      //           label: 'Escucha al presidente',
      //           src: 'http://hi5.1980s.fm/;',
      //         },
      //       },
      //       {
      //         blockquote:
      //           'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
      //         personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
      //         personImage:
      //           'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
      //         audio: {
      //           label: 'Escucha al presidente',
      //           src: 'http://hi5.1980s.fm/;',
      //         },
      //       },
      //     ],
      //   },
      // },
      {
        component: 'ModuleNews',
        props: {
          tagLine: 'Módulo Noticias',
          cta: true,
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              category: 'Agua',
              date: 'Marzo 2018',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              category: 'Agua',
              date: 'Marzo 2018',
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'internal',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'agua',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'download',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Banners',
        props: {
          frames: [
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
          ],
        },
      },
    ],
  },
};
