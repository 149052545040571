import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link, Wysiwyg } from 'React/components';

import './styles.scss';
import { ReactComponent as ChevronIcon } from 'Icons/chevron_down-18.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';

const Acordeon = ({ tagLine, moduleCTA, items, columns, equipodirectivo, equipodirectivolower, legend }) => {
  let moduleRef = React.createRef();
  let clickEl = React.createRef();
  useSlowFade(moduleRef);

  const [activeSection, setActiveSection] = useState();

  const toggleSection = (sectionId) => {
    clickEl.current.classList.add('is-active');
    console.log(clickEl.current.classList);
    setActiveSection((oldState) => (oldState === sectionId ? null : sectionId));
  };

  return (
    <div
      ref={moduleRef}
      className={`module grid acordeon ${columns === 1 ? 'single-column' : ''} ${
        equipodirectivo === true ? 'acordeon--equipo-directivo' : ''
      } ${equipodirectivolower === true ? 'acordeon--equipo-directivo acordeon--equipo-directivo--lower' : ''} ${
        typeof items[0].image !== 'undefined' ? 'with-image' : ''
      }`} // if first item doesn't have image we assume this acordeon doesn't have images
    >
      {tagLine && <TagTitle style="slow--y" title={tagLine} />}

      {moduleCTA && typeof window != 'undefined' && window.windowWidth >= 680 && (
        <Link className="module-cta" type={moduleCTA.type} path={moduleCTA.path} label={moduleCTA.label} />
      )}
      <div className="distribuidor-2-col-container grid">
        {items.map((item, idx) => {
          const { image, title, content, innercontent, subtitle, link, icon, chevron, disabled } = item;

          return (
            <div
              key={`data-section-${idx + 1}`}
              data-is-active={activeSection === idx + 1}
              className="distribuidor-2-col-item slow--y"
              ref={clickEl}
            >
              {image && (
                <div className="image-container">
                  <img src={image} />
                </div>
              )}

              <div
                className={`title-container ${disabled === true ? 'acordeon--disabled' : ''}`}
                onClick={(e) => e.currentTarget.parentElement.classList.toggle('is-active')}
              >
                <h2 className="title--s title">{title}</h2>
                {subtitle && (
                  <h3 className="title--xs">
                    {subtitle}
                    {icon && <IconDireccion />}
                  </h3>
                )}
                {link && (
                  <div>
                    <Link type="arrow" label={link} />
                  </div>
                )}
                {!chevron && (
                  <div className="chevron">
                    <ChevronIcon />
                  </div>
                )}
              </div>

              {content && content.trim().startsWith('<') && (
                <div className="main-content text-container">
                  <Wysiwyg html={content} />
                </div>
              )}

              {content && !content.trim().startsWith('<') && (
                <div className="main-content text-container">
                  <p className="lead-text body--m">{content}</p>
                </div>
              )}

              {innercontent && (
                <div className="main-content text-container">
                  <p className="body--s">Subdepartamentos</p>
                  <ul>
                    <li className="body--s">
                      <strong>Relación con Inversores:</strong> Raimundo Fernández Cuesta:{' '}
                      <span>
                        Raimundo Fernández Cuesta <IconDireccion />
                      </span>
                    </li>
                    <li className="body--s">
                      <strong>Gestión económica:</strong> José Manuel Terceiro
                    </li>
                    <li className="body--s">
                      <strong>Control Económico y Fiscal:</strong>{' '}
                      <span>
                        David Gutiérrez <IconDireccion />
                      </span>
                    </li>
                    <li className="body--s">
                      <strong>Financiación:</strong> Roberto Cabrera
                    </li>
                  </ul>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {moduleCTA && typeof window != 'undefined' && window.windowWidth < 680 && (
        <Link className="module-cta" type="arrow" path={moduleCTA.path} label={moduleCTA.label} />
      )}
      {legend && (
        <p className="legend link slow--y">
          <IconDireccion />
          PERSONAS QUE OSTENTAN CARGOS CON RESPONSABILIDAD DE DIRECCIÓN A EFECTOS DE LO PREVISTO EN EL REGLAMENTO (UE)
          Nº596/2014, DE 16 DE ABRIL, SOBRE EL ABUSO DE MERCADO.
        </p>
      )}
    </div>
  );
};

export default Acordeon;
