export default {
  meta: {
    title: 'Ancho Completo - Video',
    templateClass: 'template-ancho-completo-imagen'
  },
  content: {
    modules: [
      {
        component: 'HeaderSlider',
        props: {
          frames: [
            {
              video: { 
                src: 'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4', 
              },
              previewImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
              title: 'Energía limpia para un sistema energético sostenible',
              body:
                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
              cta: { type: 'play' },
            },
          ],
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          pageTitle: 'Título de página'
        }
      },      
      {
        component: 'ModuloContenidoDev',
        props: {

        }
      },
    ]
  }
};
