export default {
  meta: {
    title: 'Soluciones Story',
  },
  content: {
    modules: [
      {
        component: 'SolucionesStoryFinal',
        props: {
          en: true,
        },
      },
    ],
  },
};
