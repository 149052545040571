import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
require('intersection-observer');
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

const mountPoint = document.getElementById('app');

if (mountPoint) {
  ReactDOM.render(<App />, mountPoint);
} else {
  console.error('Can not mount App without mountPoint');
}
