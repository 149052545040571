import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { Link } from 'React/components';
import { ReactComponent as IconDownload } from 'Icons/download.svg';

import './styles.scss';
import { TagTitle } from '..';

const Descargas = ({ type, moduleTitle, downloads }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  if (type == 'component') {
    return (
      <ul ref={moduleRef} className="list list-downloads">
        <li className="slow--y">
          <Link type="lightbox" label="Ver informe" />
        </li>
        {downloads.map((item, idx) => {
          return (
            <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph">
              <a className="icon--red link module--cta" href="#">
                <IconDownload viewBox="0 0 18 18" width="15" />
                <p>{item}</p>
                <p className="link--download-type">PDF 813KB</p>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  if (type == 'two-columns') {
    return (
      <div ref={moduleRef} className="module descargas grid">
        {moduleTitle && (
          <div className="descargas__header slow--y">
            <TagTitle title={moduleTitle} />
            <Link type="arrow" label="Enlace interno" />
          </div>
        )}

        <h2 className="title--m slow--y">Informes trimestrales de 2019</h2>
        {downloads && (
          <div className="descargas__col-left">
            <ul className="list list-downloads">
              <li className="slow--y">
                <Link type="external" label="Enlace externo" />
              </li>
              {downloads.map((item, idx) => {
                return (
                  <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph">
                    <a className="icon--red link module--cta" href="#">
                      <IconDownload viewBox="0 0 18 18" width="15" />
                      <p>{item}</p>
                      <p className="link--download-type">PDF 813KB</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="descargas__col-right">
          <ul className="list list-downloads">
            <li className="slow--y">
              <Link type="lightbox" label="Ver informe" />
            </li>
            {downloads.map((item, idx) => {
              return (
                <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph">
                  <a className="icon--red link module--cta" href="#">
                    <IconDownload viewBox="0 0 18 18" width="15" />
                    <p>{item}</p>
                    <p className="link--download-type">PDF 813KB</p>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={moduleRef} className="module descargas descargas--single grid">
        {moduleTitle && (
          <div className="descargas__header slow--y">
            <TagTitle title={moduleTitle} />
            <Link type="arrow" label="Enlace interno" />
          </div>
        )}

        {downloads && (
          <div className="descargas__col-left">
            <h2 className="title--m slow--y">Informes trimestrales de 2019</h2>
            <ul className="list list-downloads">
              <li className="slow--y">
                <Link type="lightbox" label="Ver informe" />
              </li>
              <li className="slow--y">
                <Link type="external" label="Enlace externo" />
              </li>
              {downloads.map((item, idx) => {
                return (
                  <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y">
                    <a className="icon--red link module--cta" href="#">
                      <IconDownload viewBox="0 0 18 18" width="15" />
                      <p>{item}</p>
                      <p className="link--download-type">PDF 813KB</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="descargas__col-right">
          <h2 className="title--m slow--y">Informes trimestrales de 2019</h2>
          <ul className="list list-downloads">
            <li className="slow--y">
              <Link type="arrow" label="Enlace interno" />
            </li>
            <li className="slow--y">
              <Link type="lightbox" label="Ver informe" />
            </li>
            <li className="slow--y">
              <Link type="external" label="Enlace externo" />
            </li>
            {downloads.map((item, idx) => {
              return (
                <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y">
                  <a className="icon--red link module--cta" href="#">
                    <IconDownload viewBox="0 0 18 18" width="15" />
                    <p>{item}</p>
                    <p className="link--download-type">PDF 813KB</p>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
};

export default Descargas;
