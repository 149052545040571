// button.component.js
import React from 'react';

import './Styles.scss';

const Checkbox = ({ name, label, handleChange = (e) => console.log(e) }) => {
  return (
    <div className={`checkbox ${name}`}>
      <div className="checkbox__container">
        <label className="checkbox_item taglabel">
          <input name={name} type="checkbox" onChange={(e) => handleChange(name, e.target.checked)} />
          <span className="checkbox-ghost"></span>
          <span className="checkbox_label">{label}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
