// button.component.js
import React from 'react';

const ButtonCta = ({ label, container }) => {
  if (container == true) {
    return (
      <div className="module grid">
        <div className="grid-container align-center">
          <button className="btn-cta">
            {label}
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <button className="btn-cta">
        {label}
      </button>
    );    
  }
};

export default ButtonCta;