import React from 'react';

import { Link, TagTitle } from 'React/components';

import 'Styles/_texts.scss';
import './style.scss';
import './style.scss';

const WysiwygEmpleo = ({list}) => {
  return (
    <div className="module module-wysiwyg">
      <div className="grid">
        <div className="text-container">
            <p className="body--l">ACCIONA precisa incorporar un Responsable Mecánico, en el departamento de Ingeniería O&M para su área de Producción, dentro de la división de Energía para su centro de trabajo en Sabiñánigo (Huesca) o Sarriguren (Navarra)</p>
            
            <h3>Misión</h3>

            <p>Dependiendo del Gerente de “Ingeniería O&M”, será responsable de planificar, organizar, coordinar, supervisar y controlar el mantenimiento gran correctivo, especial, preventivo y los proyectos de modernización y paradas programadas anuales en los equipos y sistemas de Producción Hidráulica, Térmica y Fotovoltaica.</p>

            <h3>Funciones</h3>

            <ul>
              {list.map((item, idx) => {
                return <li key={`text-p-${idx}`} className="body--s main-text-paragraph">{item}></li>
              })}
            </ul>

            <h3>Requisitos</h3>
            <ul>
              <li className="body--s main-text-paragraph">Formación complementaria: Centrales eléctricas, mantenimiento y/o construcción</li>
              <li className="body--s main-text-paragraph">Perfil: Técnico con capacidad de gestionar equipos. Iniciativa y
              Flexibilidad. Orientación al resultado</li>
              <li className="body--s main-text-paragraph">Años/Años de experiencia requerida: 5 años en montaje, mantenimiento
              mecánico de centrales eléctricas e industriales de proceso continuo de
              personas</li>
              <li className="body--s main-text-paragraph">Disponibilidad para viajar: SI</li>
              <li className="body--s main-text-paragraph">Idiomas/Nivel: Inglés/Nivel Medio</li>
              <li className="body--s main-text-paragraph">Titulación/Formación: Grado en Ingienería, especialidad Mecánica</li>
            </ul>
            <p>ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad y el objetivo de responder con éxito a los mayores desafíos globales, como el calentamiento global, la superpoblación y la escasez hídrica. Retos ante los que diseña soluciones en energía renovable, infraestructuras resilientes, gestión y tratamiento de agua y servicios.</p>
          </div>
        </div>
      </div>
  )
};

export default WysiwygEmpleo;
