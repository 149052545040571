export default {
  meta: {
    title: 'Template Informes y presentaciones',
    templateClass: 'template-ancho-completo'

  },
  content: {
    modules: [		
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          secondLevel: 'Accionistas e Inversores',
          thirdLevel: 'Información financiera',
          pageTitle: 'Informes y presentaciones trimestrales (16)'
        }
      },
      {
        component: 'BuscadorFiltros',
        props: {
          type: 'informes',
        }
      },	  
      {
        component: 'Descargas',
        props: {
          downloads: [
          'Resultados 1er semestre 2019',
          'Estados financieros resumidos consolidados semestre 2019',
          'Resultados 1er trimestre 2019',
          ],
        }
      },
      {
        component: 'Descargas',
        props: {
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        }
      },
      {
        component: 'Descargas',
        props: {
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        }
      },
      {
        component: 'Descargas',
        props: {
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        }
      },	  	  	  
      {
        component: 'Paginator',
        props: {
      	}   
	  }	 	  ,
    {
        component: 'Banners',
        props: {
            moduleTitle: 'Módulo Banners',
            frames: [
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },    
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                tel: '956 56 23 70',
                email: 'hola@email.com',
                link: '#',
                linkTitle: 'Contactar'
              },                            
            ]            
        }
      },      	  
    ]
  }
};
