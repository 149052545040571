import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { useFade } from 'React/custom_hooks';
import { TagTitle, Link, ProjectsMapStatic } from 'React/components';
import { EnlaceAgrupador } from 'React/components';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const BloqueTexto = ({
  map,
  tagLine,
  title,
  leadText,
  leadTextColumnRight,
  leadTextColumnLeft,
  mainTextColumnLeft,
  mainTextColumnRight,
  leadSmallText,
  mainText,
  children,
  list,
  listOrder,
  downloads,
  moduleCta,
  linkGroup,
}) => {
  const leadTextClass = leadText ? 'with-lead-text' : '';

  const moduleRef = React.createRef();

  useSlowFade(moduleRef);
  useFade(moduleRef);

  return (
    <div ref={moduleRef} className={`module grid bloque-texto ${leadTextClass}`}>
      {tagLine && <TagTitle title={tagLine} />}

      <div className="title ">
        <h2 className="title--l slow">{title}</h2>
      </div>

      {map && <ProjectsMapStatic projectsList={[{ lat: -23.6948347, lng: 133.8793945 }]} />}


      {leadText && (
        <div className="lead-text-container">
          <p className="lead-text body--l slow">{leadText}</p>

          {leadSmallText && <p className="lead-small-text mt-2 body--m slow">{leadSmallText}</p>}
        </div>
      )}

      {mainText && (
        <div className="main-text">
          <p className="body--m slow main-text-paragraph ">{mainText}</p>
          {moduleCta && (
            <Link className="module--cta slow" type="arrow" path={moduleCta.target}>
              {moduleCta.label}
            </Link>
          )}
          {children}
        </div>
      )}

      {mainTextColumnRight && (
        <div className="main-text main-text--columns grid full-width">
          <div className="slow main-text-paragraph">
            <p className="body--l ">{leadTextColumnLeft}</p>
            <p className="body--m ">{mainTextColumnLeft}</p>
          </div>
          <div className="slow main-text-paragraph">
            <p className="body--l ">{leadTextColumnRight}</p>
            <p className="body--m ">{mainTextColumnRight}</p>
          </div>
        </div>
      )}

      {leadTextColumnRight && (
        <div className="main-text main-text--lead grid full-width">
          <div className="slow main-text-paragraph">
            <p className="body--l ">{leadTextColumnLeft}</p>
          </div>
          <div className="slow main-text-paragraph">
            <p className="body--l ">{leadTextColumnRight}</p>
          </div>
        </div>
      )}

      {linkGroup && (
        <>
          <ul className="enlace-agrupador">
            <li className="slow">
              <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
            </li>
            <li className="slow">
              <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
            </li>
            <li className="slow">
              <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
            </li>
            <li className="slow">
              <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
            </li>
          </ul>
        </>
      )}

      {list && (
        <ul className="list">
          {list.map((item, idx) => {
            return (
              <li key={`text-p-${idx}`} className=" body--s slow main-text-paragraph">
                {item}
              </li>
            );
          })}
        </ul>
      )}

      {listOrder && (
        <ol className="list list-ordered">
          {listOrder.map((item, idx) => {
            return (
              <li key={`text-p-${idx}`} className=" body--s slow main-text-paragraph">
                <span>{item}</span>
              </li>
            );
          })}
        </ol>
      )}

      {downloads && (
        <ul className="list list-downloads">
          {downloads.map((item, idx) => {
            return (
              <li key={`text-p-${idx}`} className=" link--download slow main-text-paragraph">
                <a className="icon--red link module--cta" href="#">
                  <DownloadIcon />
                  <p>{item}</p>
                  <p className="link--download-type">PDF 813KB</p>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default BloqueTexto;
