export default {
  meta: {
    title: 'Template Ofertas de Empleo',
    templateClass: 'template-detalle-empleo template-ancho-completo',
    stickyHeader: false,
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          type: 'fixed--empleo',
          cta: true,
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Sobre Acciona',
          thirdLevel: 'Por qué trabajar con nosotros',
          pageTitle: 'Responsable Mecánico - Ingeniería O&M',
          sector: 'Energía',
          jobPlace: 'Madrid',
          jobDate: '15 de Marzo, 2019',
          label: 'Solicitar empleo',
        },
      },
      {
        component: 'WysiwygEmpleo',
        props: {
          list: [
            'Cumplir con las expectativas de seguridad y comportamientos contemplados por las políticas, procedimientos y prácticas de Acciona Energía, y supervisar el cumplimiento de dichas expectativas.',
            'Cumplir con las Normas Generales de Seguridad y demás requisitos generales de QSE y supervisar el cumplimiento de dichas normas y requisitos por parte de las empresas colaboradoras.',
            'Dar servicio de apoyo o soporte técnico a los activos hidráulicos, térmicos y fotovoltaicos ante actualizaciones de equipos, modernizaciones de planta, averías de gran envergadura, análisis y estudio de comportamiento de los equipos, revisiones generales de las plantas, etc., relativas a los mecánicos de las plantas en lo que éstas se lo soliciten debido a su coste, capacidad técnica o falta de recursos.',
            'Organizar y planificar los trabajos especiales en el área mecánica al objeto de obtener la máxima disponibilidad de las instalaciones.',
            'Establecer las medidas necesarias para la solución de averías, y derivar a la empresa colaboradora especialista en caso necesario.',
            'Elaborar las especificaciones de las licitaciones de obra, proyectos o servicios de su organización, coordinar las empresas colaboradoras participantes y dar apoyo desde el punto de vista operativo y de mantenimiento.',
            'Supervisar los trabajos de mejora en el área de Ingeniería de Operación y Mantenimiento cuando sean realizadas por las empresas colaboradoras',
            'Establecer los procedimientos e instrucciones necesarias para la estandarización y normalización de equipos, tras una modificación llevada a cabo por su área',
            'Supervisión y apoyo al personal a su cargo en el cumplimiento del plan de autoprotección de la planta en que se estén ejecutando los trabajos',
            'Supervisión y apoyo al personal a su cargo en el cumplimiento del plan de autoprotección de la planta en que se estén ejecutando los trabajos',
            'Disponibilidad ante actuaciones de urgencia en centrales hidroeléctricas y plantas térmicas.',
            'Trabajo en equipo. Colaborar en la formación práctica de otros trabajadores',
            'Identificación y propuesta de mejoras en el área mecánica',
          ],
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },      
      {
        component: 'ShareSocial',
        props: {
          moduleTitle: 'Compartir en redes sociales',
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
      {
        component: 'TrabajaConNosotrosSimple',
        props: {
          className: 'has-header',
          cta: 'todas las ofertas de empleo',
          title: 'Más ofertas de empleo de energía',
          frames: [
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca Project Junior Engineer',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText:
                'Dar soporte y ayuda técnica para la ejecución de las obras, bajo las directrices del Jefe de Producción, en los aspectos técnicos, de control o de calidad, con el fin de asegurar una correcta ejecución de la obra.',
            },
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Gestión Contractual',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText:
                'Conocimiento de los diferentes aspectos jurídico-legales, de acuerdo con la normativa vigente, para defender los intereses de la división de Infraestructuras de ACCIONA y el cumplimiento de sus obligaciones jurídicas.',
            },
            {
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Administración-Costes',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar apoyo a los procesos de Administración y costes de los proyectos actualmente en ejecución.',
            },
          ],
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
    ],
  },
};
