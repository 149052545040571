export default {
  meta: {
    title: '[WRK] - Modulo Texto',
  },
  content: {
    modules: [
      {
        component: 'BloqueTexto',
        props: {
          title: 'gobierno en materia de sostenibilidad',
          mainText: [
            'Las actuaciones en sostenibilidad se lideran desde el máximo nivel de la compañía. Desde el año 2009 ACCIONA cuenta con una Comisión de Sostenibilidad en el Consejo de Administración',
          ],
          children: [
            {
              component: 'Descargas',
              props: {
                type: 'component',
                downloads: [
                  'Mejora en las condiciones de vida de las comunidades',
                  'Mejora de la economía de las comunidades',
                  'Creación de empleo local y fomento del autoempleo local a través de microempresas',
                  'Integración laboral de personas en riesgo de exclusión social.',
                  'Acceso a iluminación eléctrica',
                  'Mejoras en la salud de las comunidades',
                  'Acceso a la educación',
                  'Mejora de las infraestructuras públicas',
                  'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
                  'Fomento del deporte',
                ],
              },
            },
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          tagLine: 'Un cambio de paradigma',
          title: 'NUESTRa misión: Business as Unusual',
          mainText: [
            'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
          ],
          moduleCta: {
            label: 'Descubre nuestro propósito',
            target: '#some-resource',
          },
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'acceso energía 100 % renovable',
          leadText:
            'ACCIONA se posiciona como un actor clave para frenar los avances del calentamiento global y fomentar la transición hacia un modelo energético descarbonizado.',

          mainTextColumnLeft:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos. ',
          mainTextColumnRight:
            'También ha habido nuevas adjudicaciones en mercados incipientes que abren nuevas oportunidades de negocio para la compañía. Durante este año, ACCIONA ha incrementado considerablemente su base de clientes, a los que les ofrece diversas soluciones y productos energéticos: energía limpia, potencia, certificados verdes, almacenamiento, trazabilidad del origen, generación distribuida, etc.',
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          linkGroup: {
            links: [
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
            ],
            label: 'Ver descargas',
          },
          list: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          linkGroup: {
            links: [
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
            ],
            label: 'Ver descargas',
          },
          listOrder: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadTextColumnRight: 'Convocatoria Asamblea general de Obligacionistas. Obligaciones Simples Abril 2014 ',
          leadTextColumnLeft: 'Convocatoria Asamblea general de Obligacionistas. Obligaciones Simples Abril 2014 ',
          linkGroup: {
            links: [
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
            ],
            label: 'Ver descargas',
          },
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          linkGroup: {
            links: [
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
            ],
            label: 'Ver descargas',
          },
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Información técnica relevante',
          leadText:
            'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: [
            'ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:',
          ],
          linkGroup: {
            links: [
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
              { url: '#project_1', label: 'Project One' },
              { url: '#project_2', label: 'Project Two' },
              { url: '#project_3', label: 'Project Three' },
            ],
            label: 'Ver descargas',
          },
          downloads: [
            'Mejora en las condiciones de vida de las comunidades',
            'Mejora de la economía de las comunidades',
            'Creación de empleo local y fomento del autoempleo local a través de microempresas',
            'Integración laboral de personas en riesgo de exclusión social.',
            'Acceso a iluminación eléctrica',
            'Mejoras en la salud de las comunidades',
            'Acceso a la educación',
            'Mejora de las infraestructuras públicas',
            'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
            'Fomento del deporte',
          ],
        },
      },
    ],
  },
};
