export default {
  meta: {
    title: 'Ancho Completo - Base',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          secondLevel: 'Servicios',
		      pageTitle: 'Título de página'
        }
      },
      {
        component: 'ModuloContenidoDev',
        props: {
        }
      },
    ]
  }
};
