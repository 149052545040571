export default {
  meta: {
    title: 'Página Resultados de búsqueda',
    templateClass: 'template-ancho-completo template-sin-resultados',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Resultados de búsqueda para agua (fffhgh)',
          searchFail: true,
          noresults: 'Lo sentimos, no existen resultados para tu búsqueda',
        },
      },
      {
        component: 'Banners',
        props: {
          moduleTitle: 'Módulo Banners',
          frames: [
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              link: '#',
              linkTitle: 'Ir a Agenda del Inversor',
            },
            {
              title: 'Agenda del Inversor',
              content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
              tel: '956 56 23 70',
              email: 'hola@email.com',
              link: '#',
              linkTitle: 'Contactar',
            },
          ],
        },
      },
    ],
  },
};
