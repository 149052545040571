export default {
  meta: {
    title: 'Portada Proyectos',
    templateClass: 'template-detalle-proyecto template-ancho-completo-imagen'
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          className: 'component__hero-image--project',
          imageUrl:
            'https://source.unsplash.com/random/2560x1440?wind',
          titleProject: 'Única en su diseño y construcción',
          cifraProject: '2',
          unitProject: 'MM',
          descriptionProject: 'MM de habitantes abastecidos',
          categoryProject: 'Agua',
          countryProject: 'Alemania'
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          secondLevel: 'Proyectos',
          pageTitle: 'Idam Adelaida'
        }

      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Inversión social asociada a proyectos',
          leadText: 'Su objetivo es contribuir activamente a impulsar y mejorar el sistema socioeconómico de las comunidades en las que ACCIONA actúa.',
          leadSmallText: ['ACCIONA destaca en el escenario internacional, por su capacidad de combinar el crecimiento rentable de sus líneas de negocio con el impulso del desarrollo de las comunidades donde opera, con la generación de bienestar económico y mejora ambiental, entre otros beneficios:'],
          map: true,
        },
      },
      {
        component: 'DetalleTexto',
        props: {

        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {
        }
      },

    ]
  }
};
