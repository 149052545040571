export default {
  meta: {
    title: 'Contacto',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Contacto',
        },
      },
      {
        component: 'ContactInfo',
        props: {
          hq: {
            address: 'Avda. de Europa, 18. Parque Empresarial La Moraleja 28108 Alcobendas (MADRID)',
            map_url: '#internal_map_url',
            tel: '+34 948 16 68 00',
            fax: '+34 948 16 68 00',
          },
          departments: [
            { name: 'Canal Empleo', email: 'empleo@acciona.com' },
            { name: 'Sostenebilidad', email: 'responsabilidadcorporativa@acciona.com' },
            { name: 'Sostenebilidad', email: 'responsabilidadcorporativa@acciona.com' },
            { name: 'Sostenebilidad', email: 'responsabilidadcorporativa@acciona.com' },
            { name: 'Sostenebilidad', email: 'responsabilidadcorporativa@acciona.com' },
            { name: 'Sostenebilidad', email: 'responsabilidadcorporativa@acciona.com' },
          ],
          solutions: [
            {
              name: 'Energy',
              address: 'Avda. Ciudad de la Innovación, 5 31621 Sarriguren (Navarra)',
              tel: '+34 948 16 68 00',
              fax: '+34 948 16 68 00',
              map_url: 'https://maps.google.com',
            },
            {
              name: 'Civil',
              address: 'Avda. Ciudad de la Innovación, 5 31621 Sarriguren (Navarra)',
              tel: '+34 948 16 68 00',
              fax: '+34 948 16 68 00',
              map_url: 'https://maps.google.com',
            },
            {
              name: 'Movilidad',
              address: 'Avda. Ciudad de la Innovación, 5 31621 Sarriguren (Navarra)',
              tel: '+34 948 16 68 00',
              fax: '+34 948 16 68 00',
              map_url: 'https://maps.google.com',
            },
            {
              name: 'Agua',
              address: 'Avda. Ciudad de la Innovación, 5 31621 Sarriguren (Navarra)',
              tel: '+34 948 16 68 00',
              fax: '+34 948 16 68 00',
              map_url: 'https://maps.google.com',
            },
          ],
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
    ],
  },
};
