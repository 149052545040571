export default {
  meta: {
    title: '[WRK] - Distributor Destacado',
  },
  content: {
    modules: [   
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
        },
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          ods: 'http://accionacorporativa-front.redbilityservers.com/static/icons/ods.svg',
        },
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
          download: 'Descargar información técnica'
        },
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          soluciones: true
        },
      }                
    ],
  },
};
