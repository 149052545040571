export default {
  meta: {
    title: 'Soluciones Story',
  },
  content: {
    modules: [
      {
        component: 'SolucionesDebug',
        props: {
          es: true
        },
      },
    ],
  },
};
