const countryRoutes = [
  { path: '/', label: 'Home' },
  { path: '/about-us', label: 'About us' },
  {
    path: '/business-divisions',
    label: 'Business divisions',
    subRoutes: [{ path: '/project-one', label: 'Project One' }, { path: '/project-two', label: 'Project Two' }],
  },
  { path: '/our-approach', label: 'Our approach' },
  { path: '/projects', label: 'Projects' },
  { path: '/news', label: 'News' },
];

export default countryRoutes;
