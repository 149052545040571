import React, { useState, useEffect } from 'react';

import './styles.scss';
import { ReactComponent as IconWind } from 'Icons/energia-viento.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

const Resultados = ({ frames }) => {

  const refList = frames.map(frame => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.remove('is-hovered');
  };

  return (
    <div className="grid module">
      <div className="grid-container module-resultados">
        {frames.map(({ desktopImage, mobileImage, info }, idx) => {

          const imageUrl = useWindowWidth() >= 768 ? desktopImage : mobileImage;

          return (
            <div className="module-resultados__item" key={`image-frame-${idx}`} ref={refList[idx]}>
              <div className="resultados__image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
              <div className="resultados__container">
                <div>
                  <h2 className="title--m"><a data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} href="#">Parque eólico, Osuka</a></h2>
                  <div className="taglabel"><a href="#" ><IconWind height="18" width="18" viewBox="0 0 24 24" />Energía</a><span><a href="#">Osaka, Japón</a></span></div>
                </div>
                <div className="cta">
                  <a href="#" data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}><ArrowRight viewBox="0 0 94 94" /></a>
                </div>
              </div>
              {info &&
                <div className="resultados__info">
                  <ul className="info-footer">
                    <li><h3 className="taglabel">TITULARIDAD</h3><p className="body--s">Cliente</p></li>
                    <li><h3 className="taglabel">Estado</h3><p className="body--s">Instalada</p></li>
                    <li><h3 className="taglabel">Servicios</h3><p className="body--s">Experiencia del cliente, Satisfacción, Sostenibilidad, Durabilidad</p></li>
                  </ul>
                </div>}
            </div>
          )
        })}
      </div>
    </div>
  )
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
};


export default Resultados;
