export default {
  meta: {
    title: 'Soluciones Mosaico',
  },
  content: {
    modules: [
      {
        component: 'SolucionesMosaico',
        props: {
          energia: 'http://accionacorporativa-front.redbilityservers.com/static/images/energia.jpg',
          transporte: 'http://accionacorporativa-front.redbilityservers.com/static/images/transporte.jpg',
          agua: 'http://accionacorporativa-front.redbilityservers.com/static/images/agua.jpg',
          social: 'http://accionacorporativa-front.redbilityservers.com/static/images/social.jpg',
          ciudades: 'http://accionacorporativa-front.redbilityservers.com/static/images/ciudades.jpg',
          inmobiliaria: 'http://accionacorporativa-front.redbilityservers.com/static/images/inmobiliaria.jpg',
          capital: 'http://accionacorporativa-front.redbilityservers.com/static/images/capital.jpg',
        },
      },
    ],
  },
};
