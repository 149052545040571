export default {
  meta: {
    title: '[WRK] - Módulo Noticias',
  },
  content: {
    modules: [
      {
        component: 'ModuleNews',
        props: {
        tagLine: 'Módulo Noticias',
        cta: true,
        content: [
          {
          title: 'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
          category: 'Agua',
          icon: '',
          date: 'Marzo 2018',
          text: 'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de'
          },
          {
          title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
          category: 'Agua',
          date: 'Marzo 2018',
          text: 'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },
        ]			
        },
      },
      {
       component: 'ModuleNews',
        props: {
        tagLine: 'Módulo Noticias',
        cta: true,
        content: [
          {
          title: 'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
          category: 'Agua',
          date: 'Marzo 2018',
          text: 'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de'
          },
          {
          title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
          category: 'Agua',
          date: 'Marzo 2018',
          text: 'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },                              
        ]			
        }        
      }
    ],
  }
};