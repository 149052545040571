export default {
  meta: {
    title: 'Portada Proyectos',
    templateClass: 'portada-proyectos template-ancho-completo-imagen'
  },
  content: {
    modules: [
      {

        component: 'HeroImage',
        props: {
          imageUrl:
            'https://source.unsplash.com/random/2560x1440?wind',
          title: 'Energía limpia para un sistema energético sostenible',
          body:
            'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
          cta: { type: 'link', target: '#some-cta' },
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Proyectos'
        }
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - frame único con texto',
          frames: [
            { value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' },
          ],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description: 'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' }
        }
      },


      {
        component: 'ProjectsMap',
        props: {
          title: 'Invertimos en el planeta',
          initMapCenter: 2,
          projectsList: [
            {
              lat: 33.5724108,
              lng: -7.6570318,
              location: 'Casablanca',
              solution: 'energia',
              name: 'Casablanca Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Casablanca',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.566535,
              lng: 133.9779692,
              location: 'Okayama',
              solution: 'agua',
              name: 'Okayama Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 39.6777642,
              lng: 135.4160251,
              location: 'Sea of Japan',
              solution: 'industrial',
              name: 'Sea Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Seul',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 34.6777642,
              lng: 135.4160251,
              location: 'Osaca',
              solution: 'energia',
              name: 'Osaca Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Osaca',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: 47.5546492,
              lng: 7.5594407,
              location: 'Bazel',
              solution: 'movilidad',
              name: 'Bazel Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bazel',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 59.965,
              lng: 30.235,
              location: 'St.Petersburg',
              solution: 'agua',
              name: 'St.Petersburg Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_St',
              info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
              },
            },
            {
              lat: -1.2863888888889,
              lng: 36.817222222222,
              location: 'Kenya',
              solution: 'social',
              name: 'Kenya Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Kenya',
              info: 'Nuestro impacto positivo en el planeta',
            },
            {
              lat: 12.97194,
              lng: 77.59369,
              location: 'Bangalore',
              solution: 'energia',
              name: 'Bangalore Project',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_S.jpg',
              url: '#project_path_Bangalore',
              info: 'Nuestro impacto positivo en el planeta',
            },
          ],
        },
      },

      {
        component: 'BuscadorFiltros',
        props: {
          type: 'proyectos-simple'
        }
      },


      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'link internal',
                }
              }
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'agua',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'link internal',
                }
              }
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'agua',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          tagTitle: 'Proyectos - slider',
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'link internal',
                }
              }
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'agua',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'link download',
                }
              }
            },
          ],
        },
      },
      {
        component: 'Mediateca',
        props: {
          titleTag: 'Mediateca',
          mediaList: [
            {
              src_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_type: 'image',
              preview_size: 'full',
              descr: 'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              media_cta: {
                label: 'Descargar documento',
                path: '#download_media_path',
                type: 'download',
                fileParams: 'PDF 1.2Mb',
              },
            },
            {
              src_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion corta',
              media_cta: {
                label: 'Leer el estudio',
                path: '#internal_redirect_path',
                type: 'internal',
              },
            },
            {
              src_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion un poco menos corta',
              media_cta: {
                label: 'Ver el articulo',
                path: '#extenral_redirect_path',
                type: 'external',
              },
            },
            {
              preview_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_url:
                'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4',
              src_type: 'video',
              preview_size: 'full',
              descr: 'Un media item sin enlace',
            },
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'gobierno en materia de sostenibilidad',
          mainText: ['Las actuaciones en sostenibilidad se lideran desde el máximo nivel de la compañía. Desde el año 2009 ACCIONA cuenta con una Comisión de Sostenibilidad en el Consejo de Administración'],
          children: [
            {
              component: 'EnlaceAgrupador',
              props: {
                box_label: 'Descargar documento - sample 1',
                links: [
                  {
                    label: 'Link 1',
                    url: '#external_1',
                    type: 'external',
                  },
                  {
                    label: 'Link 2',
                    url: '#external_2',
                    type: 'external',
                  },
                ],
              },
            },
            {
              component: 'Descargas',
              props: {
                type: 'component',
                downloads: [
                  'Mejora en las condiciones de vida de las comunidades',
                  'Mejora de la economía de las comunidades',
                  'Creación de empleo local y fomento del autoempleo local a través de microempresas',
                  'Integración laboral de personas en riesgo de exclusión social.',
                  'Acceso a iluminación eléctrica',
                  'Mejoras en la salud de las comunidades',
                  'Acceso a la educación',
                  'Mejora de las infraestructuras públicas',
                  'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
                  'Fomento del deporte',
                ],
              }
            }
          ]
        },
      },
    ]
  }
};
