import React, { useEffect } from 'react';
import { useFade } from 'React/custom_hooks';
import { EnlaceAgrupador } from 'React/components';
import HighlightPop from 'react-highlight-pop';
import { ReactComponent as TwitterPop } from 'Icons/twitter-pop.svg';
import 'Styles/_texts.scss';
import './style.scss';
import { document, window } from 'window-or-global';

const Wysiwyg = ({ html, linkGroup }) => {
  const moduleRef = React.useRef();

  useFade(moduleRef);

  const handleScroll = () => {
    if (moduleRef.current) {
      let popHover = moduleRef.current.querySelector('.h-popover');
      if (popHover) {
        popHover.classList.add('is-hide');
      }
    }
  };

  const resetHandleScroll = () => {
    if (moduleRef.current) {
      let popHover = moduleRef.current.querySelector('.h-popover');
      popHover.classList.remove('is-hide');
      popHover.classList.add('override-style');
    }
   
  };

  const getUserSelection = () => {
    return window.getSelection().toString();
  };

  useEffect(() => {
    const appWrapper = document.querySelector('.app--layout');
    appWrapper.addEventListener('scroll', handleScroll, false);
  }, []);

  return (
    <div ref={moduleRef} className="module module-wysiwyg">
      <HighlightPop
        onHighlightPop={() => {
          resetHandleScroll();
        }}
        popoverItems={(itemClass) => (
          <>
            <span
              className={itemClass}
              onClick={() => {
                var url = 'https://twitter.com/share?url=' + encodeURIComponent(window.location.href);
                var title = getUserSelection();
                var text = encodeURIComponent(title);
                return window.open(url + '&text=' + text, '_blank');
              }}
            >
              <TwitterPop />
            </span>
          </>
        )}
      >
        {html && <div className="grid" dangerouslySetInnerHTML={{ __html: html }}></div>}
        {linkGroup && (
          <>
            <div className="grid">
              <ul className="enlace-agrupador">
                <li className="slow">
                  <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
                </li>
                <li className="slow">
                  <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
                </li>
                <li className="slow">
                  <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
                </li>
                <li className="slow">
                  <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
                </li>
              </ul>
            </div>
          </>
        )}
      </HighlightPop>
    </div>
  );
};

export default Wysiwyg;
