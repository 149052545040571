export default {
  meta: {
    title: 'CTA',
	  templateClass: 'template-cta template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          type: 'fixed',
          className: 'download-fixed',
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Información financiera',
          fourthLevel: 'Informe Anual Integrado',
          pageTitle: 'Informe anual integrado 2018',
          label: 'Enlace',
          anchor: 'Ancla 1',
          secondAnchor: 'Ancla 2',
          thirdAnchor: 'Ancla 3',
          fourthAnchor: 'Ancla 4'		  
        }
      },
      {
        component: 'ModuloContenidoDev',
        props: {}
      }
    ]
  }
};
