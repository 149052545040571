import React, { useState } from 'react';

import { CheckboxGroup, Checkbox, RadioButton, TextInput, SelectInput, TextArea, Button } from 'React/components';
import { ReactComponent as IconCheck } from 'Icons/valid-16.svg';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';
import { ReactComponent as Check } from 'Icons/valid-16.svg';
import { ReactComponent as Cross } from 'Icons/cross.svg';

import './styles.scss';

// firstStep prop is used for internal validation only
const ContactForm = ({ firstStep = 'initial', title, description, labels, checkboxLabel, confirmationMsg }) => {
  const [formStep, setFormStep] = useState(firstStep);
  const [checkboxState, setCheckboxState] = useState(false);

  const toggleCheckbox = () => {
    setCheckboxState((oldState) => !oldState);
  };

  const {
    inquiry_type_title,
    inquiry_type,
    data_sharing_title,
    data_sharing_name,
    data_sharing_radio,
    object_data,
    contact_method_title,
    contact_method_options,
    message_title,
    form_controls,
  } = labels;

  const inquiryTypeOptions = inquiry_type.map((type, idx) => ({
    label: type,
    value: `inquiry_type-${idx + 1}`,
  }));

  const [contactMethods, setContactMethods] = useState({ phone: false, email: false });

  const handleContactMethodChange = (name, value) => {
    setContactMethods((oldState) => {
      const newState = { ...oldState };
      newState[name] = value;
      return newState;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormStep('sent');
  };

  if (formStep === 'initial')
    return (
      <form className="module grid contact_form">
        <div className="form_header">
          <legend className="form_title title--l">{title}</legend>
          <p className="form_desciption body--m">{description}</p>
        </div>

        <fieldset className="form_inputs">
          <RadioButton
            label={inquiry_type_title}
            name="inquiry_type"
            options={inquiryTypeOptions}
            selected_option={1}
          />

          <p className="form_section_title body--l">{object_data.title}</p>
          <TextInput label={object_data.ref} />
          <TextInput label={object_data.name} />
          <SelectInput label={object_data.country_label} options={object_data.country_options} />
          <p className="form_section_title body--l">{data_sharing_title}</p>
          <TextInput label={data_sharing_name} />

          <CheckboxGroup
            type="contacto"
            name="data_sharing"
            check1={data_sharing_radio[0]}
            check2={data_sharing_radio[1]}
          />

          <p className="form_section_title body--l">Ejemplos de errores de validación</p>
          <TextInput label="Fecha de apertura (dd/mm/aaaa)" />
          <TextInput
            label={object_data.name}
            initValue="Pato"
            errorMsg="No tenemos ninguna instalación con este nombre"
          />
          <TextInput
            label="Numero de referencia"
            initValue="Lua"
            errorMsg="El numero no es correcto. Pongase en contacto con Acciona en el <a href='tel:+34911345678'>+34&nbsp;911&nbsp;345&nbsp;678</a> o en el correo <a href='mailto:ayuda@acciona.com'>auyda@acciona.com</a>"
          />

          <p className="form_section_title body--l">{contact_method_title}</p>
          <div className="contact_methods_wrapper">
            <Checkbox name="email" label={contact_method_options[0]} handleChange={handleContactMethodChange} />
            <Checkbox name="phone" label={contact_method_options[1]} handleChange={handleContactMethodChange} />
          </div>
          {contactMethods.email && <TextInput label={contact_method_options[0]} />}
          {contactMethods.phone && <TextInput label={contact_method_options[1]} />}

          <TextArea label={message_title} />
          <div className="faux-flex slow-y">
            <input
              type="checkbox"
              id="conditions_accepted"
              value="true"
              checked={checkboxState === true}
              onChange={toggleCheckbox}
              name="conditions_accepted"
              className="slow--y"
            />
            <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
              <Chevron className="checkbox-tick" />
            </div>
            <label
              className="body--s for-terms-checkbox"
              dangerouslySetInnerHTML={{
                __html:
                  'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white" href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
              }}
              onClick={toggleCheckbox}
            ></label>
          </div>
          <div className="feedback-message" data-is-visible={formStep === 'submited'}>
            <Check width="16" height="16" />
            <p className="body--m">{confirmationMsg}</p>
          </div>
          <div className="feedback-message" data-is-visible={formStep === 'error'}>
            <Cross className="validation-fail" width="16" height="16" />
            <p className="body--m ">
              Por favor, indique su correo electrónico y el acuerdo con nuestra politica de privacidad.
            </p>
          </div>
          <div className="form_controls_wrapper">
            <Button
              type="submit"
              className="form_submit"
              label={form_controls.submit}
              size="button--lg"
              style_type="red"
              onClick={handleSubmit}
            />
            <Button className="form_close" label={form_controls.close} size="button--lg" />
          </div>
        </fieldset>
      </form>
    );

  if (formStep === 'sent')
    return (
      <div className="form_feedback">
        <h2 className="form_title title--l">{title}</h2>
        <div className="feedback-msg">
          <div className="icon-container">
            <IconCheck width="32" height="32" viewBox="-2 -4 16 16" />
          </div>
          <h3 className="body--l">!Hemos recibido todos tus datos!</h3>
          <h4 className="body--m">Nos pondremos en contacto contigo enseguida</h4>
        </div>
      </div>
    );
};

export default ContactForm;
