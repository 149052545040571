export default {
  meta: {
    title: '[WRK] - Modulo Filtro',
  },
  content: {
    modules: [
      {
        component: 'Lightbox',
        props: {
          btnLabel: 'Show lightbox',
          children: [
            { component: 'TagTitle', props: { title: 'Nested rendering FTW!' } },
            { component: 'img', props: { src: 'https://source.unsplash.com/random/1280x768?spring' } },
          ],
        },
      },
    ],
  },
};
