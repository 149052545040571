export default {
  meta: {
    title: 'Template Ofertas de Empleo',
    templateClass: 'template-ofertas-empleo template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Sobre Acciona',
          thirdLevel: 'Por qué trabajar con nosotros',
          pageTitle: 'Ofertas de empleo (50)',
          intro: 'Texto descriptivo'
        }
      },
      {
        component: 'BuscadorFiltros',
        props: {
        	type: 'empleo'
        }
      },      
      {
        component: 'TrabajaConNosotrosSimple',
        props: {
          frames: [
            { 
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca Project Junior Engineer',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar soporte y ayuda técnica para la ejecución de las obras, bajo las directrices del Jefe de Producción, en los aspectos técnicos, de control o de calidad, con el fin de asegurar una correcta ejecución de la obra.'
            },
            { 
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Gestión Contractual',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Conocimiento de los diferentes aspectos jurídico-legales, de acuerdo con la normativa vigente, para defender los intereses de la división de Infraestructuras de ACCIONA y el cumplimiento de sus obligaciones jurídicas.'
            },   
            { 
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Administración-Costes',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar apoyo a los procesos de Administración y costes de los proyectos actualmente en ejecución.'
            },                     
          ]
        }
      },	  
      {
        component: 'Paginator',
        props: {
      	}   
	  }	 	 ,
      {
        component: 'ModuloContenidoDev',
        props: {
      	}   
	  }	 		 	    
    ]
  }
};
