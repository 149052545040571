export default {
  meta: {
    title: 'Template Agenda del Inversor',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Información bursátil',
          pageTitle: 'Agenda del inversor (6)',
          subscribe: true,
          intro: 'Texto descriptivo'
		    }
      },  	  
      {
        component: 'Agenda',
        props: {
          dates: [
            {
              day: '12',
              link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },             
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
             link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
             link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
            },  
            {
              day: '12',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
             link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
             link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
             link: {
                path: [
                  { url: '#project_1', label: 'Añadir a Google Calendar', type: 'external' },
                  { url: '#project_2', label: 'Añadir a Apple Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Outlook Calendar', type: 'external' },
                  { url: '#project_3', label: 'Añadir a Yahoo Calendar', type: 'external' },
                ],
              },                
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },                                    
          ],
          datesPast: [
            {
              day: '12',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
              text: 'ACCIONA es una empresa global con un modelo de negocio construido en torno a la sostenibilidad'
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
            },  
            {
              day: '12',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
            },
            {
              day: '16',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'UBS Renewables Conference',
              addCalendar: 'Añadir a mi calendario',
            },
            {
              day: '18',
              month: 'Septiembre 2019',
              place: 'París',
              title: 'Kepler Cheuvrex-Autum Conference',
              addCalendar: 'Añadir a mi calendario',
            },              
          ]          
        }
      },
      {
        component: 'ModuloContenidoDev',
        props: {
      	}   
	  }	 		 	    
    ]
  }
};
