import React, { useState, useEffect } from 'react';
import { PropTypes as pt } from 'prop-types';
import ReactPlayer from 'react-player';
import ReactMediumImg from 'react-medium-zoom';

import { useSliderSwipe } from 'React/custom_hooks';
import { TagTitle, Link, Button, InputRangeSlider } from 'React/components';

import './style.scss';

import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { ReactComponent as GoLeft } from 'Icons/left.svg';
import { ReactComponent as GoRight } from 'Icons/right.svg';
import { ReactComponent as PlayButton } from 'Icons/video_play-white.svg';
import { ReactComponent as InstagramIcon } from 'Icons/instagram-24.svg';
import { ReactComponent as InfografiaIcon } from 'Icons/infografia-24.svg';

const Mediateca = ({ titleTag, mediaList }) => {
  const [modalIsVisible, setModalVisibility] = useState(false);
  const [activeFrame, setActiveFrame] = useState(1);
  const [playingVideo, setPlayingVideo] = useState(null);

  const iconsMap = {
    instagram: <InstagramIcon />,
    infografia: <InfografiaIcon />,
  };

  const swipableComponent = React.createRef();

  const iframeCookies = (e) => {
    e.wrapper.querySelector('iframe').setAttribute('data-cookieconsent', 'marketing');
    e.wrapper.querySelector('iframe').setAttribute('data-cookieblock-src', '');
  };

  const showLightbox = (frameId = 1) => {
    setModalVisibility(true);
    setActiveFrame(frameId);
    if (mediaList[frameId - 1].src_type === 'video') {
      handleClickOnVideo(frameId);
    }
    document.querySelector('.app--layout').dataset.canScroll = false;
  };

  const hideLightbox = () => {
    setModalVisibility(false);
    setActiveFrame(1);
    setPlayingVideo(null);
    document.querySelector('.app--layout').dataset.canScroll = true;
  };

  const shiftSlider = (direction) => {
    setActiveFrame((oldState) => {
      const curState = parseInt(oldState);

      if (direction === 'right' && curState < mediaList.length) {
        return curState + 1;
      } else if (direction === 'left' && curState > 1) {
        return curState - 1;
      } else {
        return oldState;
      }
    });
  };

  useEffect(() => {
    setPlayingVideo(null);
  }, [activeFrame]);

  const handleClickOnVideo = (videoId) => {
    setPlayingVideo((oldState) => {
      if (parseInt(oldState) === videoId) {
        return null;
      } else {
        return videoId;
      }
    });
  };

  useSliderSwipe(swipableComponent, setActiveFrame, mediaList.length);

  return (
    <section className="module grid module-gallery">
      <TagTitle style="slow--y" title={titleTag} />

      <div className="gallery-container grid">
        <div className="wrapper">
          {mediaList.map((item, idx) => {
            const img_src = item.preview_url ? item.preview_url : item.src_url;

            if (item.preview_size === 'full') {
              return (
                <div
                  style={{ backgroundImage: `url(${img_src})` }}
                  onClick={() => showLightbox(idx + 1)}
                  className="gallery-featured media-item media-type-full"
                  key={`media-item-${idx}`}
                >
                  {item.src_type === 'video' && <PlayButton />}
                  {item.gallery_icon && <div className="gallery_icon_wrapper">{iconsMap[item.gallery_icon]}</div>}
                </div>
              );
            }

            if (item.preview_size === 'half') {
              return (
                <div
                  style={{ backgroundImage: `url(${img_src})` }}
                  onClick={() => showLightbox(idx + 1)}
                  className="gallery-col gallery-col--left media-item media-type-half"
                  key={`media-item-${idx}`}
                >
                  {item.src_type === 'video' && <PlayButton />}
                  {item.gallery_icon && <div className="gallery_icon_wrapper">{iconsMap[item.gallery_icon]}</div>}
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="mediateca-lightbox" data-is-visible={modalIsVisible}>
        <div className="close-button-wrapper grid">
          <Button onClick={hideLightbox} className="close-lightbox-btn">
            <CloseIcon />
          </Button>
        </div>
        {mediaList.length > 1 && (
          <>
            <Button onClick={() => shiftSlider('left')} className="slide-btn slide-left-btn">
              <GoLeft />
            </Button>
            <Button onClick={() => shiftSlider('right')} className="slide-btn slide-right-btn">
              <GoRight />
            </Button>
          </>
        )}
        <div
          className="lightbox-content-slider"
          ref={swipableComponent}
          data-active-frame={activeFrame}
          style={{ width: `${100 * mediaList.length}vw` }}
        >
          {mediaList.map((frame, idx) => {
            const { src_url, src_type, descr, media_cta } = frame;

            return (
              <div className="frame grid" data-frame-id={idx + 1} key={`media-frame-${idx}`}>
                <div className="frame-content">
                  {src_type === 'image' && (
                    <div className="image-container">
                      <ReactMediumImg src={src_url} />
                    </div>
                  )}

                  {src_type === 'video' && (
                    <div
                      className="react-player-container"
                      data-is-playing={idx + 1 === playingVideo}
                      onClick={() => handleClickOnVideo(idx + 1)}
                    >
                      <ReactPlayer
                        onReady={(e) => iframeCookies(e)}
                        width="100%"
                        height="auto"
                        url={src_url}
                        playing={idx + 1 === playingVideo}
                        controls={false}
                      />
                    </div>
                  )}

                  {descr && <p className=" item-description body--l">{descr}</p>}

                  {media_cta && (
                    <Link
                      path={media_cta.path}
                      label={media_cta.label}
                      type={media_cta.type}
                      fileParams={media_cta.fileParams}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {mediaList.length > 1 && (
          <div className="range-slider-wrapper grid">
            <InputRangeSlider
              changeHandler={setActiveFrame}
              activeFrame={parseInt(activeFrame)}
              framesCount={mediaList.length}
            />
          </div>
        )}
      </div>
    </section>
  );
};

Mediateca.propTypes = {
  mediaList: pt.arrayOf(
    pt.shape({
      src_url: pt.string.isRequired,
      src_type: pt.oneOf(['image', 'video']).isRequired,
      preview_url: pt.string,
      preview_size: pt.oneOf(['full', 'half']).isRequired,
      descr: pt.string,
      media_cta: pt.shape({
        label: pt.string.isRequired,
        path: pt.string.isRequired,
        type: pt.oneOf(['download', 'internal', 'external']).isRequired,
        fileParams: pt.string,
      }),
    })
  ).isRequired,
};

export default Mediateca;
