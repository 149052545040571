
export default {
  meta: {
    title: '[WRK] - Base Page',
  },
  content: {
    pageTitle: 'Base Page',
    modules: [
      {
        component: 'ModuleText',
        props: {
          title: 'Hello',
          text: 'Using render props in React is a technique for efficiently re-using code. According to the React documentation, "a component with a render prop takes a function that returns a React element and calls it instead of implementing its own render logic". To understand what that means, let’s take a look at the render props pattern and then apply it to a couple of light examples.',
          image: 'https://source.unsplash.com/random/1024x768?automation'
        },
      },
      {
        component: 'ModuleText',
        props: {
          title: 'The render props pattern',
          text: 'In working with render props, you pass a render function to a component that, in turn, returns a React element. This render function is defined by another component, and the receiving component shares what is passed through the render function.',
          image: 'https://source.unsplash.com/random/1024x768?bears',
          orientation: 'right',
        },
      },
    ],
  },
};
