// button.component.js
import React, { useEffect } from 'react';
import { Link, TagTitle, EnlaceAgrupador } from 'React/components';

import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import './Styles.scss';


const error404 = () => {
 return (
	 <div className="template-404 module grid">
		 <div className="grid-container">
			 <div className="module-404">
				 <h2 className="title--l">Error 404</h2>
				 <p className="body--l mt-3">Lo sentimos, la página que has solicitado no existe</p>
				 <p className="body--m mt-3">
					 Si has intentado llegar escribiendo la dirección directamente, por favor asegúrate de que es correcta.
					</p>
					<p className="body--m mt-1"> Puedes volver a la portada o seguir navegando por las secciones del sitio.
				 </p>
				 <a href="#" className="btn-cta mt-4">Ir a la home</a>
			 </div>
		 </div>
	 </div>
	)

};

export default error404;