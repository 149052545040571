import React, { useState, useEffect } from 'react';
import { TagTitle, InputRangeSlider, Link } from 'React/components';
import { useSliderSwipeFeatured } from 'React/custom_hooks';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-viento.svg';
import { ReactComponent as IconMovilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconSocial } from 'Icons/social-24.svg';
import { ReactComponent as IconInmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';

const DistribuidorDestacadoSlider = ({ frames }) => {
  const swipableComponent = React.createRef();
  const [activeFrame, setActiveFrame] = useState(1);

  const prevButton = React.createRef();
  const nextButton = React.createRef();

  const nextHover = () => {
    swipableComponent.current.querySelector('.frame-active .next-button').classList.add('is-animated');
    swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
  };

  const prevHover = () => {
    swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
    swipableComponent.current.querySelector('.frame-active .prev-button').classList.add('is-animated');
  };

  const removeHover = () => {
    swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
    swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
  };

  const nextFrame = () => {
    if (activeFrame < frames.length) {
      setActiveFrame(parseInt(activeFrame) + 1);
    }
  };

  const prevFrame = () => {
    if (activeFrame > 1) {
      setActiveFrame(parseInt(activeFrame) - 1);
    }
  };

  useEffect(() => {
    const ref = swipableComponent.current;

    function callback(entries, observer) {
      entries.forEach((el) => {
        if (el.isIntersecting == true) {
          el.target.classList.add('is-visible');
        }
      });
    }

    // Instancing a new IntersectionObserver
    const observer = new IntersectionObserver(callback);

    // Adding a target to be observed
    observer.observe(ref);
  });

  useSliderSwipeFeatured(swipableComponent, setActiveFrame, frames.length);

  return (
    <section
      data-active-frame={activeFrame}
      ref={swipableComponent}
      className={`module grid module-distribuidor-destacado with-slider grid-flow--left`}
    >
      {frames.length > 1 && (
        <>
          <button
            className="ghost-frame-button ghost-frame-button--left"
            onMouseOver={prevHover}
            onMouseOut={removeHover}
            onClick={prevFrame}
          >
            Slider anterior
          </button>
          <button
            className="ghost-frame-button ghost-frame-button--right"
            onMouseOver={nextHover}
            onMouseOut={removeHover}
            onClick={nextFrame}
          >
            Slider siguiente
          </button>
        </>
      )}
      {frames.map((frame, idx) => {
        const { title, href, label, text, image, tagtitle, soluciones, download, ods, id } = frame;

        return (
          <div className="grid data-point grid-container full-width" key={`data-point-${idx}`}>
            <div className={`grid-container--left `}>
              <div className="grid-container--left image" style={{ backgroundImage: `url(${image})` }}>
                {ods && <img src={ods} className="ods" />}
              </div>
            </div>
            <div className={`grid-container--right slow--y`}>
              <div className="grid-container--text slow--y">
                {tagtitle ? <TagTitle style="slow--y" title={tagtitle} /> : null}

                {title ? <h2 className="title--l slow--y">{title}</h2> : null}

                {text ? (
                  <div className="mt-2">
                    <p className="body--m slow--y">{text}</p>
                  </div>
                ) : null}

                {href ? (
                  <div className="mt-3">
                    <Link className="slow--y" type="arrow" href={href} label={label} />
                  </div>
                ) : null}

                {soluciones === true && (
                  <div className="soluciones-destacado">
                    <ul className="grid-container">
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconEnergy height="18" width="18" viewBox="0 0 24 24" />
                          Energía
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconCivil />
                          Civil
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconMovilidad />
                          Movilidad
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconAgua />
                          Agua
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconSocial />
                          Social
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconIndustrial />
                          Economía circular
                        </a>
                      </li>
                      <li className="title--xs slow--y">
                        <a href="#">
                          <IconInmo />
                          Inmobiliaria
                        </a>
                      </li>
                    </ul>
                  </div>
                )}

                {download && (
                  <div className="mt-3 download slow--y">
                    <Link type="download" label={download} />
                  </div>
                )}
              </div>
              <InputRangeSlider
                framesCount={frames.length}
                noIndicator
                activeFrame={parseInt(activeFrame)}
                changeHandler={setActiveFrame}
                whiteThumb={false}
              />
              <div className="slider-controls slow--y">
                <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                  <IconArrowRightLong />
                </button>
                <button ref={nextButton} className="next-button" onClick={nextFrame}>
                  <IconArrowRightLong />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default DistribuidorDestacadoSlider;
