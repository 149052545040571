import React, { useState } from 'react';

import { rndKeyGen } from 'React/helpers';
import './Styles.scss';

const SelectInput = ({ label, options }) => {
  const [value, setValue] = useState('');

  return (
    <div className="select_input">
      <div className="select_wrapper">
        <label className="taglabel">{label}</label>
        <select
          className="body--m"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((option, idx) => (
            <option
              className="body--m"
              value={option.toLowerCase()}
              key={rndKeyGen()}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
