import React from 'react';
import { PropTypes as pt } from 'prop-types';
import './styles.scss';

const InputRangeSlider = React.forwardRef(
  ({ activeFrame, framesCount, frameDescription, changeHandler, noIndicator, whiteThumb }, ref) => {
    const handleSliderChange = (event) => {
      changeHandler(event.target.value);
    };

    return (
      <div ref={ref} className="component input-range-slider grid slow--y">
        {framesCount > 1 && (
          <input
            aria-label="slider control"
            className={whiteThumb ? 'white-thumb' : null}
            type="range"
            min={1}
            max={framesCount}
            value={activeFrame}
            step={1}
            onChange={handleSliderChange}
            onInput={handleSliderChange}
          />
        )}
        {framesCount === 1 && <hr />}
        {frameDescription && (
          <div className="description slow--y">
            <p className="body--m slow--y">{frameDescription ? frameDescription : <span>&nbsp;</span>}</p>
          </div>
        )}

        {!noIndicator && (
          <p className="frame-position-indicator slow--y">
            <span className="active">{activeFrame < 10 ? `0${activeFrame}` : activeFrame}</span>
            <span className="last">{framesCount < 10 ? `0${framesCount}` : framesCount}</span>
          </p>
        )}
      </div>
    );
  }
);

InputRangeSlider.propTypes = {
  changeHandler: pt.func.isRequired,
  activeFrame: pt.number.isRequired,
  framesCount: pt.number.isRequired,
  description: pt.string,
  frameDescription: pt.string,
  noIndicator: pt.bool, // hides n out of x frames indicator
  whiteThumb: pt.bool, // adds class to make thumb white
};

export default InputRangeSlider;
