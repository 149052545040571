import { ReactComponent as Civil } from 'Icons/civil.svg';
import { ReactComponent as Leaf } from 'Icons/leaf.svg';
import { ReactComponent as Energy } from 'Icons/energia-viento.svg';
import { ReactComponent as Movilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as Agua } from 'Icons/water-24.svg';
import { ReactComponent as Social } from 'Icons/social-24.svg';
import { ReactComponent as Inmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as Capital} from 'Icons/capital-24.svg';
import { ReactComponent as Industrial} from 'Icons/industrial-24.svg';
import { ReactComponent as Otros} from 'Icons/otrosnegocios-24.svg';

const navIconsMap = {
  Leaf,
  Civil,
  Energy, 
  Movilidad,
  Agua, 
  Social, 
  Capital,
  Inmo,
  Industrial,
  Otros,
};

export default navIconsMap;
