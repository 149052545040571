export default {
  meta: {
    title: 'Sobre Acciona',
    templateClass: 'template-ancho-completo-imagen',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'http://accionacorporativa-front.redbilityservers.com/static/images/imagen_cabecera.svg',
          title: 'Nuestra misión y valores',
          body: 'Buscamos satisfacer las necesidades actuales sin comprometer las de las generaciones futuras.',
          velo: true,
          cta: {
            type: 'link',
            target: '#some-cta',
          },
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          pageTitle: 'Sobre Acciona',
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          tagLine: 'Un cambio de paradigma',
          title: 'Nuestra misión: Business as unusual',
          mainText: [
            'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
          ],
          moduleCta: {
            label: 'Descubre nuestro propósito',
            target: '#some-resource',
          },
        },
      },
      {
        component: 'SolucionesBotonera',
        props: {
          title: 'Descubre nuestras soluciones',
          body:
            'Somos expertos en diseñar un planeta mejor. Ofrecemos soluciones para dar respuesta a los grandes desafíos globales a los que se enfrenta la humanidad y que marcarán el futuro del planeta.',
          cta: 'Accede a todas las soluciones',
        },
      },

      {
        component: 'TestimoniosSlider',
        props: {
          tagTitle: 'Testimonios con audio',
          frames: [
            {
              blockquote:
                'Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda',
              personName: 'Ramiro, Usuario de IDAM Adelaida, Australia',
              personImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_personas_M.jpg',
              audio: {
                label: 'Escucha al presidente',
                src: 'http://accionacorporativa-front.redbilityservers.com/static/audio_sample.mp3',
              },
            },
          ],
        },
      },
      {
        component: 'Cifras',
        props: {
          title: 'Nuestra compañía en cifras',
          frames: [
            {
              value: '+38.544',
              unit: 'empleados',
            },
            {
              value: '123',
              unit: 'nacionalidades',
            },
            {
              value: '790',
              unit: 'Hm3 de volumen de agua tratada',
            },
            {
              value: '+5.000',
              unit: 'km de carreteras creados',
            },
            {
              value: '1.700',
              unit: 'km de líneas de alta velocidad creados',
            },

            {
              value: '100%',
              unit: 'emisiones compensadas',
            },

            {
              value: '14,7',
              unit: 'millones de toneladas de CO2 evitadas',
            },
            {
              value: '225,4',
              unit: 'millones invertidos en innovación',
            },
          ],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description:
            'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: {
            path: '#sample_path',
            label: 'Más sobre la capacidad y producción',
          },
        },
      },
      {
        component: 'DistribuidorIconos',
        props: {
          title: 'Distribuidor Iconos',
          frames: [
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'sustainability',
              title: 'Enfoque a largo plazo',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'innovation',
              title: 'Factor de diferenciación y motor de cambio',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
            {
              icon_type: 'excellence',
              title: 'Ofrecemos las mejores soluciones',
              text:
                'Buscamos satisfacer las necediasdes del presente sin comprometes la capacidad de las futuras generaciones, garantizando el equilibrio...',
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacadoSlider',
        props: {
          frames: [
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                cifra: '432.000',
                unit: '㎥',
                caption: 'capacidad',
              },
              project: {
                name: 'Etap oum azza',
                type: 'energía',
                location: 'Rabbat, Maruecos',
                link: {
                  path: '#project_1',
                  type: 'internal',
                },
              },
            },
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_proyectos_L.jpg',
              content: {
                claim: 'Nuestro impacto positivo en el planeta',
              },
              project: {
                name: 'Shukran lakaa',
                type: 'agua',
                location: 'Beirut, Lebanon',
                link: {
                  path: '#project_2',
                  type: 'download',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Mediateca',
        props: {
          titleTag: 'Mediateca',
          mediaList: [
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_type: 'image',
              preview_size: 'full',
              descr:
                'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              media_cta: {
                label: 'Descargar documento',
                path: '#download_media_path',
                type: 'download',
                fileParams: 'PDF 1.2Mb',
              },
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion corta',
              media_cta: {
                label: 'Leer el estudio',
                path: '#internal_redirect_path',
                type: 'internal',
              },
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion un poco menos corta',
              media_cta: {
                label: 'Ver el articulo',
                path: '#extenral_redirect_path',
                type: 'external',
              },
            },
            {
              preview_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4',
              src_type: 'video',
              preview_size: 'full',
              descr: 'Un media item sin enlace',
            },
          ],
        },
      },
    ],
  },
};
