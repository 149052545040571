export default {
  meta: {
    title: '[WRK] - Banners',
  },
  content: {
    modules: [
      {
        component: 'Banners',
        props: {
            moduleTitle: 'Módulo Banners',
            frames: [
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              },    
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                tel: '956 56 23 70',
                email: 'hola@email.com',
                link: '#',
                linkTitle: 'Contactar'
              },                            
            ]            
        }
      },
    {
        component: 'Banners',
        props: {
            moduleTitle: 'Módulo Banners',
            title: 'Acciona Motosharing',
            type: 'motosharing',
            description: 'Avd. Ciudad de la Innovación, 531621 Sarriguren (Navarra)',
            linkLightbox: true

        }
      },          
      {
        component: 'Banners',
        props: {
            moduleTitle: 'Módulo Banners',
            frames: [
              {
                title: 'Agenda del Inversor',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Ir a Agenda del Inversor'
              }, 
              {
                title: 'Informes y presentaciones trimestrales',
                content: 'Consulta toda la información sobre los eventos interesantes para inversores.',
                link: '#',
                linkTitle: 'Contactar'
              },                            
            ]            
        }
      },      
    ]
  }
};
