// button.component.js
import React from 'react';

import './Button.scss';

const Button = ({ children, label, className, size = 'button--md', type = 'button', style_type = 'base', onClick }) => (
  <button onClick={onClick} type={type} className={`button taglabel ${size} type__${style_type} ${className}`}>
    {children}
    {label}
  </button>
);

export default Button;
