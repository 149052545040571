export default {
  meta: {
    title: 'PDF reader',
  },
  content: {
    modules: [
      {
        component: 'PDFreader',
        props: {
          sourceFile: 'http://accionacorporativa-front.redbilityservers.com/static/documents/sample.pdf',
          // sourceFile: '../../../static/documents/sample.pdf',
        },
      },
    ],
  },
};
