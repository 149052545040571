import React, { useRef, useState, useEffect } from 'react';

import { Button } from 'React/components';

import './Header.scss';
import './NavSecondary.scss';
import './NavSubsection.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { useStickyHeader } from 'React/custom_hooks';

import { ReactComponent as User } from 'Icons/usuario-16.svg';
import { ReactComponent as HamburgerMenuIcon } from 'Icons/hamburgermenu.svg';
import { ReactComponent as HamburgerMenuIconTablet } from 'Icons/hamburgermenu-tablet.svg';
import { ReactComponent as Triangle } from 'Icons/triangleup.svg';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';
import { ReactComponent as Plus } from 'Icons/plus-10.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';
import { ReactComponent as Chevron_M } from 'Icons/chevron_down-10.svg';

const Header = ({ isVisible, stickyHeader }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [mobileIsOpen, setMobileIsOpen] = useState(false);

  const searchInput = useRef(null);
  const searchInputTestStand = useRef(null);

  const swipeTargetHeader = React.createRef();
  const desktopNav = React.createRef();
  const mobileNav = React.createRef();
  const navDrawer = React.createRef();
  const countriesRef = React.createRef();
  const searchRef = React.createRef();

  const headerRef = useRef();

  function toggleMobileSubsection(event) {
    event.currentTarget.classList.toggle('active');

    document.querySelectorAll('.subsection__toggle').forEach(function(item) {
      if (item !== event.currentTarget) {
        item.classList.remove('active');
      }
    });
  }

  function toggleMobileChildSubsection(event) {
    event.currentTarget.classList.toggle('active');

    document.querySelectorAll('.subsection-child__toggle').forEach(function(item) {
      if (item !== event.currentTarget) {
        item.classList.remove('active');
      }
    });
  }

  const onHover = (event) => {
    const navSubsections = [...document.querySelectorAll('.nav_sub_section')];
    const parentLinks = [...document.querySelectorAll('.link-lvl-0')];

    parentLinks.map((item) => {
      if (item.dataset.sectionId === event.currentTarget.dataset.sectionId) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    });

    navSubsections.forEach(function(item) {
      if (item.dataset.section === event.currentTarget.dataset.sectionId) {
        item.dataset.isVisible = true;
        item.classList.add('is-visible');
        navDrawer.current.dataset.sectionActive = item.dataset.section;
        navDrawer.current.dataset.isVisible = true;
        navDrawer.current.classList.add('is-visible');
      }

      if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
        item.dataset.isVisible = false;
        item.classList.remove('is-visible');
      }

      if (event.currentTarget.dataset.children === 'no') {
        navDrawer.current.dataset.isVisible = false;
        navDrawer.current.classList.remove('is-visible');
      }
    });
  };

  const notHover = (event) => {
    const navSubsections = [...document.querySelectorAll('.nav_sub_section')];
    const parentLinks = [...document.querySelectorAll('.link-lvl-0')];
    parentLinks.map((item) => {
      if (item.dataset.children === 'no') {
        item.classList.remove('is-active');
      }
    });
    navSubsections.forEach(function(item) {
      if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
        item.dataset.isVisible = false;
        item.classList.remove('is-visible');
      }
    });
  };

  const toggleSubsectionVisibility = () => {
    navDrawer.current.dataset.isVisible = false;
    navDrawer.current.classList.remove('is-visible');
    const parentLinks = [...document.querySelectorAll('.link-lvl-0')];
    parentLinks.map((item) => {
      item.classList.remove('is-active');
    });
  };

  const openMenu = () => {
    setMobileIsOpen(true);
    document.querySelector('.app--layout').dataset.canScroll = false;
  };

  const closeMenu = () => {
    setMobileIsOpen(false);
    document.querySelector('.app--layout').dataset.canScroll = true;
  };

  const openCountriesOverlay = () => {
    countriesRef.current.dataset.isVisible = true;
  };

  const closeCountriesOverlay = () => {
    countriesRef.current.dataset.isVisible = false;
  };

  const openSearchOverlay = () => {
    searchRef.current.dataset.isVisible = true;
  };

  const closeSearchOverlay = () => {
    searchRef.current.dataset.isVisible = false;
  };

  useEffect(() => {
    if (isVisible) searchInput.current.focus();
  }, [isVisible, mobileIsOpen]);

  const handleInput = (e) => {
    setSearchTerm(e.target.value);

    const input = searchInput.current;
    const testStand = searchInputTestStand.current;

    // measure the length of the input text and update input width
    testStand.innerText = searchTerm;
    input.style.width = `calc(2em + ${testStand.getBoundingClientRect().width}px)`;
  };

  const handleSearchSubmit = () => {
    console.log('searching for ' + searchTerm);
  };

  const countriesList = [
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'Japón', path: '#japan' },
    { label: 'Alemania', path: '#germany' },
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'Japón', path: '#japan' },
    { label: 'Alemania', path: '#germany' },
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
  ];

  useStickyHeader(headerRef, stickyHeader);

  return (
    <>
      <header ref={headerRef} className="page-header grid" id="header">
        <a className="logo-home-link" path="/">
          <img className="logo" src={LogoFull} alt="Logo Acciona" />
        </a>

        <div className="header__row header__top">
          <p className="stock-ticker">
            <Triangle width={12} height={12} />
            <span>(IBEX 35) 46,1 0,7%</span>
          </p>
          <div className="header-right">
            <Button type="link" className="country-selector" onClick={openCountriesOverlay}>
              <span>Países</span>
              <Plus width="10px" height="10px" viewBox="0 0 10 10" />
            </Button>
            <span className="clientes-touch">
              <a href="#area_clientes">
                <User viewBox="0 1 17 17" />
                Clientes
              </a>
            </span>
          </div>
          <HamburgerMenuIcon className="icon icon__hamburger icon__hamburger--mobile" onClick={openMenu} />

          <nav className="nav_secondary" aria-label="menú de navegación secundaria" role="navigation">
            <a href="#contacto" className="is-active">
              Contacto
            </a>
            <a href="#english">English</a>
            <a href="#area_clientes">
              <User viewBox="0 1 17 17" />
              Área de clientes
            </a>
          </nav>

          <button className="open-search-form" onClick={openSearchOverlay} aria-label="open search form">
            <SearchLense />
          </button>
          <HamburgerMenuIconTablet className="icon icon__hamburger  icon__hamburger--tablet" onClick={openMenu} />
        </div>

        <div className="header__row header__bottom">
          <nav
            ref={desktopNav}
            className="nav__main nav__main--desktop"
            role="navigation"
            aria-label="Navegación principal para escritorio"
          >
            <ul className="nav_links">
              <a
                className="link link-lvl-0 current"
                href="/sobre_acciona"
                onMouseOut={notHover}
                onMouseOver={onHover}
                data-children="has-children"
                data-section-id="sobre_acciona"
              >
                <span>Nuestro propósito</span>
                <Chevron_L />
              </a>
              <a
                className="link link-lvl-0"
                href="/soluciones"
                onMouseOut={notHover}
                onMouseOver={onHover}
                data-children="has-children"
                data-section-id="soluciones"
              >
                <span> Soluciones</span>
                <Chevron_L />
              </a>
              <a
                className="link link-lvl-0"
                href="/proyectos"
                data-section-id="proyectos"
                data-children="no"
                onMouseOut={notHover}
                onMouseOver={onHover}
              >
                <span>Proyectos</span>
              </a>
              <a
                className="link link-lvl-0"
                href="/accionistas_e_inversores"
                data-children="has-children"
                onMouseOut={notHover}
                onMouseOver={onHover}
                data-section-id="accionistas_e_inversores"
              >
                <span>Accionistas e inversores</span>
                <Chevron_L />
              </a>
              <a
                className="link link-lvl-0"
                href="/actualidad"
                data-children="no"
                data-section-id="actualidad"
                onMouseOut={notHover}
                onMouseOver={onHover}
              >
                <span>Actualidad</span>
              </a>
            </ul>
          </nav>
          <div data-is-visible={mobileIsOpen} className="nav__overlay"></div>
          <nav
            className="nav__main nav__main--mobile"
            data-is-visible={mobileIsOpen}
            role="navigation"
            aria-label="Navegación principal para móvil"
          >
            <svg width="18" height="18" className="close-x" onClick={closeMenu}>
              <path d="M10 2v6h6v1h-6v6H9V9H3V8h6V2h1z" fill="#1F140F" fillRule="evenodd"></path>
            </svg>
            <ul className="nav_links">
              <button
                data-section="sobre-acciona"
                type="link"
                data-is-open="false"
                onClick={toggleMobileSubsection}
                className="button taglabel button--md type__base subsection__toggle"
              >
                <span>Nuestro propósito</span>
                <Chevron_L />
              </button>
              <div data-section="sobre-acciona" className="nav_sub_section lvl-1" data-subsection-visible="false">
                <a className="link link-lvl-1" href="/sobre_acciona/nuestro_proposito">
                  <span>Sobre acciona</span>
                </a>
                <a className="link link-lvl-1" href="/sobre_acciona/historia">
                  <span>Historia</span>
                </a>
                <a className="link link-lvl-1" href="/sobre_acciona/iniciativas_que_apoyamos">
                  <span>Iniciativas que apoyamos</span>
                </a>
                <button
                  type="link"
                  onClick={toggleMobileChildSubsection}
                  data-section="sostenibilidad"
                  className="button taglabel button--md type__base subsection-child__toggle subsection__toggle"
                >
                  <span>
                    Sostenibilidad <Chevron_M />
                  </span>
                </button>
                <div data-section="sostenibilidad" className="nav_sub_section lvl-2" data-subsection-visible="false">
                  <a className="link link-lvl-2 current" href="/sobre_acciona/sostenibilidad/estrategia">
                    <span>Nuestra estrategia</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/sostenibilidad/objetivos">
                    <span>Objectivos de Desarollo sostenible</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/sostenibilidad/indices">
                    <span>Índices de sostenibilidad</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/sostenibilidad/alianzas-de-desarollo">
                    <span>Alianzas para el Desarollo Sostenible</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/sostenibilidad/emergencia-climatica">
                    <span>Emergencia Climática</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/sostenibilidad/desarollo-social">
                    <span>Desarollo Social</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad">
                    <span>Portada de sostenibilidad</span>
                  </a>
                </div>
                <button
                  type="link"
                  data-section="innovacion"
                  onClick={toggleMobileChildSubsection}
                  className="button taglabel button--md type__base subsection-child__toggle subsection__toggle"
                >
                  <span>Innovación</span>
                  <Chevron_M />
                </button>
                <div data-section="innovacion" className="nav_sub_section lvl-2" data-subsection-visible="false">
                  <a className="link link-lvl-2" href="/sobre_acciona/innovacion/innovacion-abierta">
                    <span>Innovación Abierta</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/innovacion/centros_i_d">
                    <span>Centros de I+D</span>
                  </a>
                  <a className="link link-lvl-2" href="/sobre_acciona/innovacion/proyectos-innovacion">
                    <span>Proyectos de innovación</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/innovacion">
                    <span>Portada de innovación</span>
                    <svg width="1em" height="1em" viewBox="0 -2 9 9">
                      <path
                        d="M5.067 4.396l-.487.477L.686.979l.88-.842 3.005 3.017L7.67.127l.89.852z"
                        fill="#1F140F"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
                <a className="link link-lvl-1" href="/sobre_acciona/nuestra_fundacion">
                  <span>Nuestra fundación</span>
                </a>
                <a className="link link-lvl-1" href="/sobre_acciona/razones_para_trabajar_con_acciona">
                  <span>Por qué trabajar con nosotros</span>
                </a>
                <a className="link link-lvl-0" href="/sobre_acciona">
                  <span>Portada de nuestro propósito</span>
                </a>
              </div>

              <button
                type="link"
                data-section="soluciones"
                data-is-open="false"
                onClick={toggleMobileSubsection}
                className="button taglabel button--md type__base subsection__toggle"
              >
                <span>Soluciones</span>
                <Chevron_L />
              </button>
              <div data-section="soluciones" className="nav_sub_section lvl-1" data-subsection-visible="false">
                <a className="link current link-lvl-1" href="/soluciones/energia">
                  <EnergyIcon />
                  <span>Energía</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/transporte">
                  <MovilidadIcon />
                  <span>Transporte</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/agua">
                  <AguaIcon />
                  <span>Agua</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/social">
                  <SocialIcon />
                  <span>Social</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/ciudades">
                  <MovilidadIcon />
                  <span>Ciudades</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/inmobiliaria">
                  <InmobiliariaIcon />
                  <span>Inmobiliaria</span>
                </a>
                <a className="link link-lvl-1" href="/soluciones/capital">
                  <CapitalIcon />
                  <span>Capital</span>
                </a>
                <a className="link link-lvl-0" href="/soluciones">
                  Portada de soluciones
                </a>
              </div>
              <a className="link link-lvl-0" href="/proyectos">
                <span>Proyectos</span>
              </a>

              <button
                type="link"
                onClick={toggleMobileSubsection}
                data-is-open="false"
                data-section="accionistas-e-inversores"
                className="button taglabel button--md type__base subsection__toggle"
              >
                <span>Accionistas e inversores</span>
                <Chevron_L />
              </button>

              <div
                data-section="accionistas-e-inversores"
                className="nav_sub_section lvl-1"
                data-subsection-visible="false"
              >
                <button
                  type="link"
                  onClick={toggleMobileChildSubsection}
                  className="button taglabel button--md type__base subsection__toggle subsection-child__toggle"
                >
                  <span>Información Financiera</span> <Chevron_M />
                </button>
                <div
                  data-section="información-financiera"
                  className="nav_sub_section lvl-2"
                  data-subsection-visible="false"
                >
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_financiera/informe_anual">
                    Informe Anual Integrado
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_financiera/cuentas_anuales"
                  >
                    Cuentas anuales
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_financiera/informes_trimestrales"
                  >
                    Informes y Presentaciones Trimestrales
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_financiera/periodo_medio_de_pago"
                  >
                    Periodo Medio de Pago a Proveedores
                  </a>
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_financiera/rating">
                    Rating
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera">
                    Portada de información financiera
                  </a>
                </div>

                <button
                  type="link"
                  data-section="informacion-bursatil"
                  onClick={toggleMobileChildSubsection}
                  className="button taglabel button--md type__base subsection-child__toggle subsection__toggle"
                >
                  <span>
                    Información Bursátil <Chevron_M />
                  </span>
                </button>
                <div
                  data-section="informacion-bursatil"
                  className="nav_sub_section lvl-2"
                  data-subsection-visible="false"
                >
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_bursatil/dividendos">
                    <span>Dividendos</span>
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_bursatil/opinion_analistas"
                  >
                    <span>Opinión de Analistas</span>
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_bursatil/agenda_de_inversor"
                  >
                    <span>Agenda del Inversor</span>
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/informacion_bursatil/financiacion_sostenible"
                  >
                    Financiación Sostenible
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_bursatil">
                    Portada de información bursátil
                  </a>
                </div>

                <button
                  type="link"
                  onClick={toggleMobileChildSubsection}
                  data-section="gobierno-corporativo"
                  className="button taglabel button--md type__base subsection-child__toggle subsection__toggle"
                >
                  Gobierno corporativo <Chevron_M />
                </button>
                <div
                  data-section="gobierno-corporativo"
                  className="nav_sub_section lvl-2"
                  data-subsection-visible="false"
                >
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/junta-general">
                    Junta General de Accionistas
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/gobierno_corporativo/consejo-administracion-comisiones"
                  >
                    Consejo de Adminstración y Comisiones
                  </a>
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/comite-direccion">
                    Comite de Dirección
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/gobierno_corporativo/informacion-accionarial"
                  >
                    Información Accionarial
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/gobierno_corporativo/informe-anual-gobierno"
                  >
                    Informe Anual de Gobierno Corporativo
                  </a>
                  <a
                    className="link link-lvl-2"
                    href="/accionistas_e_inversores/gobierno_corporativo/remuneracion-consejeros"
                  >
                    Remuneración de los Consejeros
                  </a>
                  <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/normas-gobierno">
                    Normas de Gobierno
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo">
                    Portada de gobierno corporativo
                  </a>
                </div>
                <a className="link link-lvl-1" href="/accionistas_e_inversores/emisiones_de_deuda">
                  Emisiones de deuda
                </a>
                <a className="link link-lvl-1" href="/accionistas_e_inversores/hechos_relevantes">
                  Hechos relevantes
                </a>
                <a className="link link-lvl-0" href="/accionistas_e_inversores">
                  Portada de accionistas e inversores
                </a>
              </div>
              <a className="link link-lvl-0" href="/actualidad">
                Actualidad
              </a>
            </ul>
            <div className="nav__mobile-footer">
              <div className="nav_secondary">
                <a href="#" className="is-active title--xs">
                  Contacto
                </a>
                <a href="#" className="title--xs">
                  English
                </a>
              </div>
            </div>
          </nav>
        </div>
        <>
          <div ref={navDrawer} className="nav nav__drawer" data-section-active="" data-is-visible="false">
            <nav
              data-is-visible="false"
              data-order="1"
              className="nav_sub_section"
              data-section="sobre_acciona"
              role="navigation"
              aria-label="Sobre acciona"
            >
              <div className="drawer__header grid">
                <h1 className="drawer__header--title title--m">Somos expertos en diseñar un planeta mejor</h1>
                <a href="nuestro-proposito" title="Nuestro propósito" className="section-home-link">
                  <svg width="94" height="94" version="1.1" viewBox="0 0 94 94" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M56.25,40 L63.6746212,47.4246212 L56.25,54.8492424 L55.5428932,54.1421356 L62.01,47.674 L33,47.6746212 L33,46.6746212 L61.51,46.674 L55.5428932,40.7071068 L56.25,40 Z"
                      id="Combined-Shape"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    ></path>

                    <circle fill="none" id="circle1" strokeWidth="1" stroke="#FFFFFF" cx="48" cy="47" r="44"></circle>
                  </svg>
                </a>
              </div>
              <div className="section-links">
                <div className="subsection-box">
                  <a className="link current" href="/sobre_acciona/nuestro_proposito">
                    <span>Sobre acciona</span>
                  </a>
                  <a className="link" href="/sobre_acciona/historia">
                    <span>Historia</span>
                  </a>
                  <a className="link" href="/sobre_acciona/iniciativas_que_apoyamos">
                    <span>Iniciativas que apoyamos</span>
                  </a>
                  <a className="link" href="/sobre_acciona/nuestra_fundacion">
                    <span>Nuestra fundación</span>
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/sobre_acciona/sostenibilidad">
                    <span>Sostenibilidad</span>
                  </a>
                  <a className="link link-lvl-1 current" href="/sobre_acciona/sostenibilidad/estrategia">
                    <span>Nuestra estrategia</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad/objetivos">
                    <span>Objectivos de Desarollo sostenible</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad/indices">
                    <span>Índices de sostenibilidad</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad/alianzas-de-desarollo">
                    <span>Alianzas para el Desarollo Sostenible</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad/emergencia-climatica">
                    <span>Emergencia Climática</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/sostenibilidad/desarollo-social">
                    <span>Desarollo Social</span>
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/sobre_acciona/innovacion">
                    <span>Innovación</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/innovacion/innovacion-abierta">
                    <span>Innovación Abierta</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/innovacion/centros_i_d">
                    <span>Centros de I+D</span>
                  </a>
                  <a className="link link-lvl-1" href="/sobre_acciona/innovacion/proyectos-innovacion">
                    <span>Proyectos de innovación</span>
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/sobre_acciona/razones_para_trabajar_con_acciona">
                    <span>Por qué trabajar con nosotros</span>
                  </a>
                </div>
              </div>
            </nav>
            <nav
              data-is-visible="false"
              data-order="2"
              className="nav_sub_section"
              data-section="soluciones"
              role="navigation"
              aria-label="Soluciones"
            >
              <div className="drawer__header grid">
                <h1 className="drawer__header--title title--m">
                  Ofrecemos soluciones para dar respuesta a los grandes desafíos globales
                </h1>
                <a href="soluciones" title="Soluciones" className="section-home-link">
                  <ArrowRight viewBox="0 0 94 94" />
                </a>
              </div>
              <div className="section-links">
                <a className="link current" href="/soluciones/energia">
                  <EnergyIcon />
                  <span>Energía</span>
                </a>
                <a className="link" href="/soluciones/transporte">
                  <MovilidadIcon />
                  <span>Transporte</span>
                </a>
                <a className="link" href="/soluciones/agua">
                  <AguaIcon />
                  <span>Agua</span>
                </a>
                <a className="link" href="/soluciones/social">
                  <SocialIcon />
                  <span>Social</span>
                </a>
                <a className="link" href="/soluciones/ciudades">
                  <CiudadesIcon />
                  <span>Ciudades</span>
                </a>
                <a className="link" href="/soluciones/inmobiliaria">
                  <InmobiliariaIcon />
                  <span>Inmobiliaria</span>
                </a>
                <a className="link" href="/soluciones/capital">
                  <CapitalIcon />
                  <span>Capital</span>
                </a>
              </div>
            </nav>
            <nav
              data-is-visible="false"
              data-order="4"
              className="nav_sub_section"
              data-section="accionistas_e_inversores"
              role="navigation"
              aria-label="Accionistas e inversores"
            >
              <div className="drawer__header grid">
                <h1 className="drawer__header--title title--m">Somos expertos en diseñar un planeta mejor</h1>
                <a href="accionistas" title="Accionistas e inversores" className="section-home-link">
                  <ArrowRight viewBox="0 0 94 94" />
                </a>
              </div>
              <div className="section-links">
                <div className="subsection-box">
                  <a className="link" href="/accionistas_e_inversores/informacion_financiera">
                    Información Financiera
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera/informe_anual">
                    Informe Anual Integrado
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_financiera/cuentas_anuales"
                  >
                    Cuentas anuales
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_financiera/informes_trimestrales"
                  >
                    Informes y Presentaciones Trimestrales
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_financiera/periodo_medio_de_pago"
                  >
                    Periodo Medio de Pago a Proveedores
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera/rating">
                    Rating
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/accionistas_e_inversores/informacion_bursatil">
                    Información Bursátil
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_bursatil/dividendos">
                    Dividendos
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_bursatil/opinion_analistas"
                  >
                    Opinión de Analistas
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_bursatil/agenda_de_inversor"
                  >
                    Agenda del Inversor
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/informacion_bursatil/financiacion_sostenible"
                  >
                    Financiación Sostenible
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/accionistas_e_inversores/gobierno_corporativo">
                    Gobierno corporativo
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/junta-general">
                    Junta General de Accionistas
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/gobierno_corporativo/consejo-administracion-comisiones"
                  >
                    Consejo de Adminstración y Comisiones
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/comite-direccion">
                    Comite de Dirección
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/gobierno_corporativo/informacion-accionarial"
                  >
                    Información Accionarial
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/gobierno_corporativo/informe-anual-gobierno"
                  >
                    Informe Anual de Gobierno Corporativo
                  </a>
                  <a
                    className="link link-lvl-1"
                    href="/accionistas_e_inversores/gobierno_corporativo/remuneracion-consejeros"
                  >
                    Remuneración de los Consejeros
                  </a>
                  <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/normas-gobierno">
                    Normas de Gobierno
                  </a>
                </div>
                <div className="subsection-box">
                  <a className="link" href="/accionistas_e_inversores/emisiones_de_deuda">
                    Emisiones de deuda
                  </a>
                  <a className="link" href="/accionistas_e_inversores/hechos_relevantes">
                    Hechos relevantes
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div
            className="drawer-overlay"
            onClick={toggleSubsectionVisibility}
            onMouseOver={toggleSubsectionVisibility}
          ></div>
        </>
      </header>
      <>
        <div className={'bottom_drawer grid countries_overlay'} ref={countriesRef} data-is-visible="false">
          <div className="header" ref={swipeTargetHeader}>
            <h3 className="title title--xs">
              Nuestra presencia en el mundo
              <IconClose onClick={closeCountriesOverlay} />
            </h3>
          </div>
          <div className="body">
            {countriesList.map((country, idx) => (
              <a className="title--m" href={country.path} key={`country-item-${idx}`}>
                {country.label}
                <IconExternal width="18" height="18" viewBox="0 0 18 18" />
              </a>
            ))}
          </div>
        </div>

        <div data-is-visible="false" className="module grid search_overlay" ref={searchRef}>
          <div className="header">
            <h3 className="title title--xs">
              <IconClose onClick={closeSearchOverlay} />
            </h3>
          </div>
          <div className="body">
            <form className="search-input-wrapper">
              <input onChange={handleInput} className="search-input" ref={searchInput} placeholder="Buscar" />
              <button className="search-submit" onClick={handleSearchSubmit} type="button">
                <SearchLense />
              </button>
            </form>
            <div className="search-input-test-stand" ref={searchInputTestStand}></div>
          </div>
        </div>
      </>
    </>
  );
};

export default Header;
