export default {
  meta: {
    title: 'Página Hechos Relevantes',
    templateClass: 'template-noticias template-hechos-relevantes template-ancho-completo',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          secondLevel: 'Actualidad',
          pageTitle: 'Hechos relevantes (16)',
          intro: 'Texto descriptivo de la página (opcional)',
        },
      },
      {
        component: 'BuscadorFiltros',
        props: {
          type: 'noticias',
          title: 'Comunicación de Información Privilegiada (11)',
        },
      },
      {
        component: 'ModuleNews',
        props: {
          type: 'hechos',
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              date: '27 agosto 2019',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              date: '27 agosto 2019',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
          ],
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
      {
        component: 'BuscadorFiltros',
        props: {
          type: 'noticias',
          title: 'Comunicación de Información Privilegiada (11)',
        },
      },
      {
        component: 'ModuleNews',
        props: {
          type: 'hechos',
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              date: '27 agosto 2019',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              date: '27 agosto 2019',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
          ],
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
      {
        component: 'BuscadorFiltros',
        props: {
          type: 'noticias',
          title: 'Comunicación de Información Privilegiada (11)',
        },
      },
      {
        component: 'ModuleNews',
        props: {
          type: 'hechos',
          content: [
            {
              title:
                'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
              date: '27 agosto 2019',
              text:
                'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de',
            },
            {
              title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
              date: '27 agosto 2019',
              text:
                'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
            {
              title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
              text:
                'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
              tag: 'Innovación',
              date: '27 agosto 2019',
            },
          ],
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
      {
        component: 'TextWithCta',
        props: {},
      },
      {
        component: 'Banners',
        props: {
          moduleTitle: 'Tal vez te interese',
          frames: [
            {
              title: 'Gobierno Corporativo',
              content:
                'Consulta los órganos de gobierno, la participación accionarial, los estatutos de la sociedad, el libro de políticas, la junta general y más.',
              link: '#',
              linkTitle: 'Ir a Gobierno Corporativo',
            },
            {
              title: 'Informes y presentaciones trimestrales',
              content: 'Consulta los diferentes informes y presentaciones económicas trimestrales.',
              link: '#',
              linkTitle: 'Ir a Informes y presentaciones',
            },
            {
              title: 'Financiación Sostenible',
              content:
                'Financiaciones que incorporan criterios de Sostenibilidad y donde las condiciones económicas se ajustan en función del cumplimiento de los mismos.',
              link: '#',
              linkTitle: 'Ir a Financiación Sostenible',
            },
          ],
        },
      },
      {
        component: 'Banners',
        props: {
          title: 'Oficina del accionista',
          type: 'motosharing',
          description: 'Póngase en contacto con la oficina del accionista para cualquier duda o petición.',
          linkLightbox: true,
        },
      },
    ],
  },
};
