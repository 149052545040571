export default {
  meta: {
    title: 'Área de clientes',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
			pageTitle: 'Área de clientes'
        }
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Business as unusual',
          text: 'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
		  linkExternal: 'Acceder a Huertas Solares'
        },
      },	
      {
        component: 'Banners',
        props:  {
			type: 'motosharing',
			title: 'Huertas solares',
			description: 'Avd. Ciudad de la Innovación, 531621 Sarriguren (Navarra)',
			linkLightbox: true
        },
      },	
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Presente en todo el ciclo integral del agua',
          text: 'ACCIONA tiene como misión ser líder aportando soluciones globales que contribuyan al desarrollo sostenible en el sector del agua, a través de la innovación en el diseño, ejecución y operación de plantas de tratamiento, depuración y desalación de agua. Cuenta con proyectos de diseño y construcción, mantenimiento y servicio integral de agua en todo el mundo que disfrutan más de 70 millones de personas.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Agua',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
		  linkExternal: 'Acceder a Huertas Solares'
        },
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Presente en todo el ciclo integral del agua',
          text: 'ACCIONA tiene como misión ser líder aportando soluciones globales que contribuyan al desarrollo sostenible en el sector del agua, a través de la innovación en el diseño, ejecución y operación de plantas de tratamiento, depuración y desalación de agua. Cuenta con proyectos de diseño y construcción, mantenimiento y servicio integral de agua en todo el mundo que disfrutan más de 70 millones de personas.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Agua',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
		  linkExternal: 'Acceder a Huertas Solares'
        },
      },
	  	  	    
      {
        component: 'Banners',
        props:  {
			type: 'motosharing',
			title: 'Acciona Motosharing',
			description: 'Atención al usuario'
        },
      },
      {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Presente en todo el ciclo integral del agua',
          text: 'ACCIONA tiene como misión ser líder aportando soluciones globales que contribuyan al desarrollo sostenible en el sector del agua, a través de la innovación en el diseño, ejecución y operación de plantas de tratamiento, depuración y desalación de agua. Cuenta con proyectos de diseño y construcción, mantenimiento y servicio integral de agua en todo el mundo que disfrutan más de 70 millones de personas.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Agua',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
		  linkExternal: 'Acceder a Huertas Solares'
        },
      },	
      {
        component: 'Banners',
        props:  {
			type: 'motosharing',
			title: 'Green Energy. Servicios de comercialización',
			description: 'Servicio de Atención al Cliente (Comercializadora)'
        },
      },
     {
        component: 'DistribuidorDestacado',
        props:  {
          title: 'Presente en todo el ciclo integral del agua',
          text: 'ACCIONA tiene como misión ser líder aportando soluciones globales que contribuyan al desarrollo sostenible en el sector del agua, a través de la innovación en el diseño, ejecución y operación de plantas de tratamiento, depuración y desalación de agua. Cuenta con proyectos de diseño y construcción, mantenimiento y servicio integral de agua en todo el mundo que disfrutan más de 70 millones de personas.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Agua',
          logo: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
		  linkExternal: 'Acceder a Huertas Solares'
        },
      },	  
      {
        component: 'Banners',
        props:  {
			type: 'motosharing',
			title: 'Green Energy. Representación en mercado',
			description: 'Servicio de Atención al Cliente (Representación)',
			linkLightbox: true
        },
      },	  	    	    
    ]
  }
};
