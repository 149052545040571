export default {
  meta: {
    title: 'Template 404',
	  templateName: 'template-404'
  },
  content: {
    modules: [
      {
        component: 'error404',
        props: {
        }
      },
      {
        component: 'ModuloContenidoDev',
        props: {
        }
      },      
    ],
  }
};