import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useHeroParallax } from 'React/custom_hooks';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';

import './styles.scss';

const HeroImage = ({
  className,
  imageUrl,
  videoUrl,
  secondCategoryProject,
  countryProject,
  titleProject,
  cifraProject,
  unitProject,
  descriptionProject,
  categoryProject,
  title,
  body,
  cta,
  velo,
  combined,
}) => {
  const imgContainer = React.createRef();

  let app = useRef(null);

  useHeroParallax('.component__hero-image');

  useEffect(() => {
    if (app != null) {
      let img = new Image();
      img.src = `${imageUrl}`;

      // controlamos con esto que la imagen esté cargada y entonces la desvelamos
      img.onload = function() {
        app.classList.add('is-loaded');
      };

      if (img.complete) img.onload();
    }
  }),
    [];

  return (
    <div
      ref={(el) => (app = el)}
      className={`module grid full-width component__hero-image ${className}`}
      data-combined={combined}
    >
      {imageUrl && (
        <div ref={imgContainer} className="backgrounds full-width" style={{ backgroundImage: `url(${imageUrl})` }}>
          <div className={`img-container ${velo ? '' : 'has-veil'}`}></div>
        </div>
      )}

      {videoUrl && <div className="video-container"></div>}
      <div className="content-blocks">
        {title && <h2 className="title title--xl">{title}</h2>}
        {body && <p className="body body--l">{body}</p>}
        {cta && (
          <a href="" className="circled-arrow-link">
            <svg width="94" height="94" version="1.1" viewBox="0 0 94 94" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M56.25,40 L63.6746212,47.4246212 L56.25,54.8492424 L55.5428932,54.1421356 L62.01,47.674 L33,47.6746212 L33,46.6746212 L61.51,46.674 L55.5428932,40.7071068 L56.25,40 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>

              <circle fill="none" id="circle1" strokeWidth="1" stroke="#FFFFFF" cx="48" cy="47" r="44"></circle>
            </svg>
          </a>
        )}
        {cifraProject && (
          <h2 className="cifras--xl">
            {cifraProject}
            <span className="cifras--m">{unitProject}</span>
          </h2>
        )}
        {descriptionProject && <p className="title--xs">{descriptionProject}</p>}
        <div className="separator"></div>

        {titleProject && (
          <div className="project-detail-footer">
            <h2 className="title--m">{titleProject}</h2>
            <div className="project-detail-footer__desc">
              <span className="category-project taglabel">
                <IconAgua />
                {categoryProject}
              </span>
              {countryProject && <span className="country-project taglabel">{countryProject}</span>}
              {secondCategoryProject && (
                <span className="country-project taglabel">
                  <IconCivil width="24" height="24" viewBox="0 0 24 24" />
                  {secondCategoryProject}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroImage;
