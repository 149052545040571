import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import { ReactComponent as IconPlus } from 'Icons/iconplus.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

import uiContext from 'Data/_uiContext';

const _Link = ({ type, fileParams, label, path, children, className, subsection }) => {
  // the following path prefix needs to be modified to match actual production paths
  const href = `${process.env.NODE_ENV === 'production' ? '/dist' : ''}${path}`;
  const context = useContext(uiContext);

  const toggleSubsectionVisibility = () => {
    if (subsection) {
      const { order } = subsection;
      context.updateDesktopNavSubsState(order);
    }
    if (['/proyectos', '/actualidad', '/about-us', '/our-approach', '/projects', '/news'].includes(path)) {
      context.updateDesktopNavSubsState(null);
    }
  };

  if (type === 'arrow' || type === 'internal') {
    const arrow = !label && !children ? <IconArrowRightLong className="cta-single" /> : <IconArrowRight />;

    return (
      <a className={`link link--icon icon--red ${className}`} href={href}>
        <span>
          {label}
          {children}
          {arrow}
        </span>
      </a>
    );
  }
  if (type === 'arrowLong') {
    return (
      <a className={`link link--icon icon--red ${className}`} href={href}>
        <span>
          {label}
          {children}
          <IconArrowRightLong />
        </span>
      </a>
    );
  }
  if (type === 'download') {
    return (
      <a className={`link link--icon icon--red link--download ${className}`} href={href}>
        <IconDownload />
        <span>
          {label}
          {children}
        </span>
        <p className="link--download-type">{fileParams ? fileParams : 'PDF 814KB'}</p>
      </a>
    );
  }
  if (type === 'back') {
    return (
      <a className={`link link--icon icon--red link--back ${className}`} href={href}>
        <IconArrowRight />
        <span>
          {label}
          {children}
        </span>
      </a>
    );
  }
  if (type === 'subscribe') {
    return (
      <a className={`link link--icon link--subscribe`} to="#">
        <IconCalendar />
        <span>
          {label}
          {children}
        </span>
      </a>
    );
  }
  if (type === 'lightbox') {
    return (
      <a className={`link link--icon icon--red link--lightbox ${className}`}>
        <span>
          {label}
          {children}
          <IconPlus />
        </span>
      </a>
    );
  }
  if (type === 'external') {
    return (
      <a className={`link link--icon icon--red link--external ${className}`} href={href}>
        <span>
          {label}
          {children}
          <IconExternal />
        </span>
      </a>
    );
  } else {
    return (
      <a className={className} href={href} onMouseOver={toggleSubsectionVisibility}>
        {label}
        {children}
      </a>
    );
  }
};

export default _Link;
