export default {
  meta: {
    title: 'Ancho Completo - Imagen',
    templateClass: 'template-ancho-completo-imagen'
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl:
          'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
          title: 'Trabajamos para dar respuesta a los grandes desafíos del planteta',
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          secondLevel: 'Servicios',
          pageTitle: 'Título de página'
        }
      },      
      {
        component: 'ModuloContenidoDev',
        props: {

        },
      },
    ],
  },
};
