export default {
  meta: {
    title: 'Página Resultados de búsqueda',
    templateClass: 'template-ancho-completo'
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Resultados de búsqueda para agua (50)',
          search: true,
        }
      },		
      {
        component: 'Distribuidor2Col',
        props: {
          items: [
            {
			titleBlock: 'Noticia',
              title: 'Reactor Biológico Secuencial, BRAINYSBR®',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
			  cta: true,
            },
            {
              title: 'CODIGESTIÓN',
				titleBlock: 'Equipo de dirección',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
			  cta: true,
            },
            {
              title: 'Título',
			titleBlock: 'Noticia',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
			  cta: true,
            },
            {
              title: 'Título',
			titleBlock: 'Noticia',
              leadText: 'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
			  cta: true,
            }   			                   
          ]
        },
      } ,
      {
        component: 'Paginator',
        props: {
        },
      } 
    ]
  }
};
