import "intersection-observer";
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './styles/main.scss';

//TODO: right now we always render one layout,
// we need to handle multiple layouts when they will be added
import defaultLayout from 'React/layout/default';
import { productionRoutes, devRoutes } from './routes/';
import { BasePage, PageNotFound } from 'React/views/';

// HOC to pass JSON data into Views and add Layout
const defaultModel = {
  meta: {
    title: 'Acciona'
  },
  content: {
    modules: []
  }
}

const withLayoutAndModel = (View, Layout=defaultLayout, model=defaultModel) => data => {
  const { meta } = model;

  return (
    <Layout pageMetaData={ meta }>
      <View model={ model } />
    </Layout>
  )
};

const allRoutes = [...productionRoutes, ...devRoutes];

// TODO: <Route exact={true} component={withLayoutAndModel(PageNotFound)} />
const App = () => {
  return (
    <Router>
      { generateRoutes(allRoutes) }
    </Router>
  );
};

// recursively generate Routes from routes array
const generateRoutes = (routesArray, lvl=0) => {
  return routesArray.map((route, idx) => {
    const { path, model, subRoutes, layout } = route;
    const view = route.view || BasePage;

    const mainRoute = (
        <Route
          key={`route-${lvl}-${idx}`}
          exact={route.exact === false ? false : true } 
          path={`${process.env.NODE_ENV === 'production' ? '/dist' : ''}${path}`} 
          component={withLayoutAndModel(view, layout, model)} 
        />
    )

    if (typeof subRoutes !== 'undefined') {
      return (
        <React.Fragment key={`fragment-${idx}`}>
          { mainRoute }
          { generateRoutes(subRoutes, lvl+1) }
        </React.Fragment>
      )
    } else {
      return mainRoute;
    }

  })
};

export default App;
