// button.component.js
import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { ButtonCta } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';

import './Styles.scss';
import { useFade } from 'React/custom_hooks';

const Breadcrumbs = ({
  noresults,
  searchFail,
  search,
  cta,
  cargo,
  icon,
  download,
  className,
  type,
  intro,
  subscribe,
  label,
  sector,
  jobDate,
  jobPlace,
  pageTitle,
  firstLevel,
  secondLevel,
  thirdLevel,
  fourthLevel,
  fithLevel,
  backLink,
  anchor,
  secondAnchor,
  thirdAnchor,
  fourthAnchor,
}) => {
  let moduleRef = React.createRef();

  useFade(moduleRef);

  useEffect(() => {
    const headerNav = document.querySelector('.breadcrumbs--fixed');
    const appWrapper = document.querySelector('.app--layout');

    const shareSocial = document.querySelector('.module-sharesocial');
    const appScrollTop = appWrapper.scrollTop;

    appWrapper.addEventListener('scroll', function() {
      if (headerNav != null) {
        const headerNavHeight = headerNav.outerHeight;
        let headerNavOffset = headerNav.offsetTop;
        let elHeight = headerNavOffset;
      }

      if (document.querySelector('.module-wysiwyg') != null) {
        let wysiwygPos = document.querySelector('.module-wysiwyg').getBoundingClientRect();

        if (wysiwygPos.top < 0) {
          headerNav.classList.add('is-fixed');
        }

        if (wysiwygPos.top > 0) {
          headerNav.classList.remove('is-fixed');
        }
      }

      if (shareSocial !== null && headerNav !== undefined) {
        if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
          headerNav.classList.add('is-done');
        } else {
          headerNav.classList.remove('is-done');
        }
      }
    });

    const headerNavFooter = document.querySelector('.breadcrumbs--fixed-footer');

    if (headerNavFooter != null) {
      const headerNavOffset = headerNavFooter.offsetTop;
      let elHeight = headerNavOffset;
    }

    appWrapper.addEventListener('scroll', function() {
      let mainHeight = document.getElementById('main').getBoundingClientRect().height;
      let headerHeight = document.getElementById('header').getBoundingClientRect().height;

      if (document.getElementById('breadcrumbs') != null) {
        let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
        let breadcrumbsBottom = document.getElementById('breadcrumbs').getBoundingClientRect().bottom;
        let heroImage = document.getElementsByClassName('component__hero-image')[0].getBoundingClientRect().height;

        if (breadcrumbsBottom < '-100') {
          headerNavFooter.classList.add('is-fixed');
        } else {
          headerNavFooter.classList.remove('is-fixed');
        }

        if (winScroll >= mainHeight) {
          headerNavFooter.classList.add('is-done');
        } else {
          headerNavFooter.classList.remove('is-done');
        }

        if (breadcrumbsBottom > '-100') {
          headerNavFooter.classList.add('is-sticky');
        } else {
          headerNavFooter.classList.remove('is-sticky');
        }

        let scrolled = (winScroll / mainHeight) * 100;
        document.getElementById('progress-bar').style.width = scrolled + '%';
      }

      if (shareSocial !== null) {
        if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
          headerNav.classList.add('is-done');
        } else {
          headerNav.classList.remove('is-done');
        }
      }
    });
  });

  if (type === 'fixed--empleo') {
    return (
      <div ref={moduleRef} className="module grid breadcrumbs breadcrumbs--fixed">
        <div className="breadcrumbs__container">
          <div className="breadcrumbs__nav">
            <nav>
              {firstLevel && (
                <a className="taglabel" href="">
                  {firstLevel}
                </a>
              )}
              {secondLevel && (
                <a className="taglabel" href="">
                  {secondLevel}
                </a>
              )}
              {thirdLevel && (
                <a className="taglabel" href="">
                  {thirdLevel}
                </a>
              )}
              {fourthLevel && (
                <a className="taglabel" href="">
                  {fourthLevel}
                </a>
              )}
              {fithLevel && (
                <a className="taglabel" href="">
                  {fithLevel}
                </a>
              )}
            </nav>
            {backLink && <Link type="back" label={backLink} />}
          </div>
          <div className="header-cta">
            <div className="header-cta__subheader">
              <h1 className="title--m">{pageTitle}</h1>
              {sector && (
                <div className="job-subheader">
                  <span className="taglabel">
                    <a className="job-sector" title={sector} href="#">
                      {icon && <IconEnergy />}
                      {sector}
                    </a>
                  </span>
                  {jobPlace && (
                    <span className="taglabel">
                      <a href="#" className="job-sector">
                        {jobPlace}
                      </a>
                    </span>
                  )}
                  <span className="taglabel">
                    <IconCalendar />
                    {jobDate}{' '}
                  </span>
                </div>
              )}
              {cargo && <div className="taglabel mt-2 cargo">{cargo}</div>}
            </div>

            {anchor && (
              <div>
                <a href="#one" className="taglabel">
                  {anchor}
                </a>
                <a href="#two" className="taglabel">
                  {secondAnchor}
                </a>
                <a href="#three" className="taglabel">
                  {thirdAnchor}
                </a>
                <a href="#four" className="taglabel">
                  {fourthAnchor}
                </a>
              </div>
            )}
            {label && <ButtonCta label={label} />}
          </div>
        </div>
      </div>
    );
  } else if (type === 'fixed') {
    return (
      <>
        <div ref={moduleRef} id="breadcrumbs" className={`module grid breadcrumbs breadcrumbs--fixed ${className}`}>
          <div className="breadcrumbs__container">
            <div className="breadcrumbs__nav">
              <nav>
                {firstLevel && (
                  <a className="taglabel" href="">
                    {firstLevel}
                  </a>
                )}
                {secondLevel && (
                  <a className="taglabel" href="">
                    {secondLevel}
                  </a>
                )}
                {thirdLevel && (
                  <a className="taglabel" href="">
                    {thirdLevel}
                  </a>
                )}
                {fourthLevel && (
                  <a className="taglabel" href="">
                    {fourthLevel}
                  </a>
                )}
                {fithLevel && (
                  <a className="taglabel" href="">
                    {fithLevel}
                  </a>
                )}
              </nav>
              {backLink && <Link type="back" label={backLink} />}
            </div>
            <div className="header-cta">
              <div className="header-cta__subheader">
                <h1 className="title--m">{pageTitle}</h1>
                {sector && (
                  <div className="job-subheader">
                    <span className="taglabel">
                      <a className="job-sector" title={sector} href="#">
                        {icon && <IconEnergy />}
                        {sector}
                      </a>
                    </span>
                    <span className="taglabel">
                      <a href="#" className="job-sector">
                        {jobPlace}
                      </a>
                    </span>
                    <span className="taglabel">
                      <IconCalendar />
                      {jobDate}{' '}
                    </span>
                  </div>
                )}
              </div>

              {anchor && (
                <div className="anchors">
                  <a href="#one" className="taglabel">
                    {anchor}
                  </a>
                  <a href="#two" className="taglabel">
                    {secondAnchor}
                  </a>
                  <a href="#three" className="taglabel">
                    {thirdAnchor}
                  </a>
                  <a href="#four" className="taglabel">
                    {fourthAnchor}
                  </a>
                  <ButtonCta label={label} />
                </div>
              )}

              {download && (
                <div>
                  <ButtonCta label={label} />
                </div>
              )}
            </div>
          </div>
          <div className="progress-bar" id="progress-bar-desktop"></div>
        </div>
        <div
          id="breadcrumbs-footer"
          className={`module grid breadcrumbs breadcrumbs--fixed breadcrumbs--fixed-footer ${className}`}
        >
          <div className="breadcrumbs__container">
            <div className="breadcrumbs__nav">
              <nav>
                {firstLevel && (
                  <a className="taglabel" href="">
                    {firstLevel}
                  </a>
                )}
                {secondLevel && (
                  <a className="taglabel" href="">
                    {secondLevel}
                  </a>
                )}
                {thirdLevel && (
                  <a className="taglabel" href="">
                    {thirdLevel}
                  </a>
                )}
                {fourthLevel && (
                  <a className="taglabel" href="">
                    {fourthLevel}
                  </a>
                )}
                {fithLevel && (
                  <a className="taglabel" href="">
                    {fithLevel}
                  </a>
                )}
              </nav>
              {backLink && <Link type="back" label={backLink} />}
            </div>
            <div className="header-cta">
              <div className="header-cta__subheader">
                <h1 className="title--m">{pageTitle}</h1>
                {sector && (
                  <div className="job-subheader">
                    <span className="taglabel">
                      <a className="job-sector" title={sector} href="#">
                        {icon && <IconEnergy />}
                        {sector}
                      </a>
                    </span>
                    {jobPlace && (
                      <span className="taglabel">
                        <a href="#" className="job-sector">
                          {jobPlace}
                        </a>
                      </span>
                    )}
                    <span className="taglabel">
                      <IconCalendar />
                      {jobDate}{' '}
                    </span>
                  </div>
                )}
              </div>

              {anchor && (
                <div className="anchors">
                  <a href="#one" className="taglabel">
                    {anchor}
                  </a>
                  <a href="#two" className="taglabel">
                    {secondAnchor}
                  </a>
                  <a href="#three" className="taglabel">
                    {thirdAnchor}
                  </a>
                  <a href="#four" className="taglabel">
                    {fourthAnchor}
                  </a>
                  <ButtonCta label={label} />
                </div>
              )}

              {cta && (
                <div>
                  <ButtonCta label={label} />
                  hola
                </div>
              )}

              {download && (
                <div>
                  <ButtonCta label={label} />
                </div>
              )}
            </div>
          </div>
          <div className="progress-bar" id="progress-bar"></div>
        </div>{' '}
      </>
    );
  }
  if (type === 'detalle-noticia') {
    return (
      <div ref={moduleRef} className="module grid breadcrumbs">
        <div className="breadcrumbs__container">
          <div className="breadcrumbs__nav">
            <nav>
              {firstLevel && (
                <a className="taglabel" href="">
                  {firstLevel}
                </a>
              )}
              {secondLevel && (
                <a className="taglabel" href="">
                  {secondLevel}
                </a>
              )}
              {thirdLevel && (
                <a className="taglabel" href="">
                  {thirdLevel}
                </a>
              )}
              {fourthLevel && (
                <a className="taglabel" href="">
                  {fourthLevel}
                </a>
              )}
              {fithLevel && (
                <a className="taglabel" href="">
                  {fithLevel}
                </a>
              )}
            </nav>
            {backLink && <Link type="back" label={backLink} />}
          </div>
          {sector && (
            <div className="header-cta">
              <div className="header-cta__subheader">
                <h1 className="title--m">{pageTitle}</h1>
                <div className="job-subheader">
                  <span className="taglabel">
                    <a className="job-sector" title={sector} href="#">
                      {icon && <IconEnergy />}
                      {sector}
                    </a>
                  </span>
                  {jobPlace && (
                    <span className="taglabel">
                      <a href="#" className="job-sector">
                        {jobPlace}
                      </a>
                    </span>
                  )}
                  <span className="taglabel">
                    <IconCalendar />
                    {jobDate}{' '}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {intro && (
          <div className="intro grid-container">
            <p className="body--l slow--y">{intro}</p>
          </div>
        )}
        {subscribe && (
          <div className="mt-3 mobile-visible">
            <Link type="subscribe" label="subscribirse" />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div ref={moduleRef} className="module grid breadcrumbs">
        <div className="breadcrumbs__container slow">
          <div className="breadcrumbs__nav slow">
            <nav>
              {firstLevel && (
                <a className="taglabel" href="">
                  {firstLevel}
                </a>
              )}
              {secondLevel && (
                <a className="taglabel" href="">
                  {secondLevel}
                </a>
              )}
              {thirdLevel && (
                <a className="taglabel" href="">
                  {thirdLevel}
                </a>
              )}
              {fourthLevel && (
                <a className="taglabel" href="">
                  {fourthLevel}
                </a>
              )}
              {fithLevel && (
                <a className="taglabel" href="">
                  {fithLevel}
                </a>
              )}
            </nav>
            {backLink && <Link type="back" label={backLink} />}
          </div>
          <h1 className="title--s slow">
            {pageTitle}
            {subscribe && (
              <div className="mobile-hidden slow">
                <Link type="subscribe" label="subscribirse" />
              </div>
            )}
          </h1>
          {sector && (
            <div className="header-cta">
              <div className="header-cta__subheader">
                <div className="job-subheader">
                  <span className="taglabel">
                    <a className="job-sector" title={sector} href="#">
                      {icon && <IconEnergy />}
                      {sector}
                    </a>
                  </span>
                  {jobPlace && (
                    <span className="taglabel">
                      <a href="#" className="job-sector">
                        {jobPlace}
                      </a>
                    </span>
                  )}
                  <span className="taglabel">
                    <IconCalendar />
                    {jobDate}{' '}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {intro && (
          <div className="intro grid-container slow">
            <p className="body--l slow">{intro}</p>
          </div>
        )}
        {subscribe && (
          <div className="mt-3 mobile-visible">
            <Link type="subscribe" label="subscribirse" />
          </div>
        )}
        {search && (
          <div className="input-wrapper grid-container slow">
            <div className="input-btn">
              <input type="text" className="search" defaultValue="Agua" />
              <button className="search-btn">
                <IconSearch />
              </button>
            </div>
          </div>
        )}
        {searchFail && (
          <div className="input-wrapper grid-container">
            <div className="input-btn">
              <input type="text" className="search" defaultValue="fffhgh" />
              <button className="search-btn">
                <IconSearch />
              </button>
            </div>
          </div>
        )}
        {noresults && (
          <div className="intro grid-container">
            <p className="body--l slow--y">{noresults}</p>
          </div>
        )}
      </div>
    );
  }
};

export default Breadcrumbs;
