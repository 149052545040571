import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link } from 'React/components';

import './styles.scss';

const Distribuidor2Col = ({ tagLine, items }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);
  const refList = items.map((item) => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.remove('is-hovered');
  };

  return (
    <div ref={moduleRef} className="module grid distribuidor-2-col">
      {tagLine && <TagTitle style="slow--y" title={tagLine} />}
      <div className="distribuidor-2-col-container">
        {items.map((item, idx) => {
          const { titleBlock, cta, image, title, leadText, downloadItem, linkItem, fileParams } = item;

          return (
            <div key={`data-point-${idx}`} className=" slow--y distribuidor-2-col-item" ref={refList[idx]}>
              {image && (
                <div className="image-container slow--y">
                  <img src={image} />
                </div>
              )}

              {titleBlock && (
                <h2 className="title--xs mb-2 slow--y">
                  <a data-ref-id={idx} href="#">
                    {titleBlock}
                  </a>
                </h2>
              )}

              {title && (
                <h2 className="title--m title slow--y">
                  <a data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} href="#">
                    {title}
                  </a>
                </h2>
              )}

              <div className="lead-text-container slow--y">
                {leadText && <p className="lead-text body--m">{leadText}</p>}

                {downloadItem && (
                  <div className="main-text-paragraph slow--y">
                    <Link label={downloadItem} fileParams={fileParams} type="download" />
                  </div>
                )}

                {linkItem && (
                  <div className="main-text-paragraph slow--y">
                    <Link label={linkItem} type="arrow" />
                  </div>
                )}

                {cta && (
                  <div className="slow--y">
                    <Link data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} type="arrow" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Distribuidor2Col;
