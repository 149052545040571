export default {
  meta: {
    title: '[WRK] - Descargas',
  },
  content: {
    modules: [
      {
        component: 'Descargas',
        props: {
			type: 'two-columns',
        	moduleTitle: 'Descargas',
			downloads: [
				'Mejora en las condiciones de vida de las comunidades',
				'Mejora de la economía de las comunidades',
				'Creación de empleo local y fomento del autoempleo local a través de microempresas',
				'Integración laboral de personas en riesgo de exclusión social.',
				'Acceso a iluminación eléctrica',
				'Mejoras en la salud de las comunidades',
				'Acceso a la educación',
				'Mejora de las infraestructuras públicas',
				'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
				'Fomento del deporte',
			],			 
	  	}
	  },
    {
        component: 'Descargas',
        props: {
        	moduleTitle: 'Descargas',
			downloads: [
				'Mejora en las condiciones de vida de las comunidades',
				'Mejora de la economía de las comunidades',
				'Creación de empleo local y fomento del autoempleo local a través de microempresas',
				'Integración laboral de personas en riesgo de exclusión social.',
				'Acceso a iluminación eléctrica',
				'Mejoras en la salud de las comunidades',
				'Acceso a la educación',
				'Mejora de las infraestructuras públicas',
				'Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos',
				'Fomento del deporte',
			],			 
	  	}
	  }	  
    ],
  },
};
