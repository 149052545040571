export default {
  meta: {
    title: 'Tabla',
  },
  content: {
    modules: [
      {
        component: 'Table',
        props: {
          headers: ['Denominación', 'Fecha inscripción', 'Importe inicial', 'Mercado'],
          tagtitle: true,
          bullets: true,
          headerDownloads: true,
          rows: [
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            },
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            },                                                                                        
          ],                                                                                         
        }
      },      
      {
        component: 'Table',
        props: {
          headers: ['Denominación', 'Fecha inscripción', 'Importe inicial', 'Mercado'],
          tableType: 'double',
          tagtitle: true,
          internalLink: true,                                                                                       
        }
      },     
      {
        component: 'Table',
        props: {
          headers: ['Denominación', 'Fecha inscripción', 'Importe inicial', 'Mercado'],
          tableType: 'cargos',
          className: 'table-min',
          singleDownload: true,
          logo: true                                                                               
        }
      },       
    {
        component: 'Table',
        props: {
          headers: ['Denominación', 'Fecha inscripción', 'Importe inicial', 'Mercado'],
          rows: [
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            },
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
                { cell: 'Hasta 1.000.000€'},
                { download: 'Irish Stock Exchange (ISE)'},
              ],
            },                                                                                        
          ],
        }
      },
   {
        component: 'Table',
        props: {
          headers: ['Denominación', 'Fecha inscripción'],
          className: 'fixed-table',
          rows: [
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
              ],
            },
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
              ],
            }, 
            {
              cells: [
                { download: 'Resultados 1er semestre 2019'},
                { cell: '01/07/2019'},
              ],
            },                                                                                        
          ],
        }
      },      
      {
        component: 'Table',
        props: {
          headers: ['Precios', 'Última cotización', 'Diferencia', 'Diferencia (%)'],
          total: true,
          text: true,
          rows: [
            {
              cells: [
                { cell: '0,8500'},
                { cell: '0,8500'},
                { cell: '0,8500', className: 'cell-red'},
                { cell: '0,8500'},
              ],
            },   
            {
              cells: [
                { cell: 'Apertura'},
                { cell: '0,8500'},
                { cell: '0,8500', className: 'cell-green'},
                { cell: '0,8500'},
              ],
            },
            {
              cells: [
                { cell: 'Max. Sesión'},
                { cell: '0,8500'},
                { cell: '0,8500', className: 'cell-red'},
                { cell: '0,8500'},
              ],
            },
            {
              cells: [
                { cell: 'Último ejercicio'},
                { cell: '0,8500'},
                { cell: '0,8500', className: 'cell-red'},
                { cell: '0,8500'},
              ],
            },   
            {
              cells: [
                { cell: 'Máximo anual'},
                { cell: '0,8500'},
                { cell: '0,8500', className: 'cell-green'},
                { cell: '0,8500'},
              ],
            },   
            {
              cells: [
                { cell: 'Mínimo anual'},
                { cell: '0,8500'},
                { cell: '0,8500'},
                { cell: '0,8500'},
              ],
            },                                                                     
          ],
        }
      },      
     {
        component: 'Table',
        props: {
          headers: ['Precios', 'Última cotización', 'Diferencia'],
          rows: [
            {
              cells: [
                { cell: '0,8500'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },   
            {
              cells: [
                { cell: 'Apertura'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },
            {
              cells: [
                { cell: 'Max. Sesión'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },
            {
              cells: [
                {cell: 'Último ejercicio'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },   
            {
              cells: [
                { cell: 'Máximo anual'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },   
            {
              cells: [
                { download: 'Mínimo anual'},
                { download: 'Resultados 1er semestre 2019'},
                { download: 'Resultados 1er semestre 2019'},
              ],
            },                                                                     
          ],
        }
      },      
    ]
  }
};
