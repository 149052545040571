export default {
  meta: {
    title: '[WRK] - Areas de actividad',
  },
  content: {
    modules: [
      {
        component: 'DistribuidorDestacadoAreas',
        props: {
          title: 'Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace:
            'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          cifra1: '7.812',
          cifra2: '7.812',
        },
      },
      {
        component: 'DistribuidorDestacadoAreas',
        props: {
          title: 'Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace:
            'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          tagtitle: 'Nuestro objetivo',
          cifra1: '7.812',
        },
      },
      {
        component: 'DistribuidorDestacadoAreas',
        props: {
          moduleTitle: 'Áreas de actividad',
          title: 'Business as unusual',
          text:
            'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
          image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
          imageReplace:
            'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
          label: 'Descubre más sobre seguridad y salud',
          href: '#',
          titleBlock: 'Nuestro objetivo',
          cifra1: '7.812',
        },
      },
      {
        component: 'AreasActividad',
        props: {
          title: 'Áreas de actividad',
          module_cta: {
            label: 'Todas las áreas de actividad de energia',
            path: '#areas-de-activdad_portada',
          },
          frames: [
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 1',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro',
            },
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 2',
              body:
                'Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!',
              link: '#hidro',
            },
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',              
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 3',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro2',
            },
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 4',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro',
            },
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 5',
              body:
                'Completely rewritten from scratch, the new version provides a truly magical experience. Measuring things has never been faster or more enjoyable!',
              link: '#hidro',
            },
            {
              image: 
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_XL.jpg',
              imageReplace:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_areasactividad_L.jpg',              
              image_alt: 'img descr',
              title: 'HIDROELÉCTRICA 6',
              body:
                'ACCIONA es un líder global en la promoción, construcción, operación y mantenimiento de instalaciones eólicas, con más de 20 años de experiencia en el sector.',
              link: '#hidro2',
            },            
          ],
        },
      },
    ],
  },
};
