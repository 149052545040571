export default {
  meta: {
    title: '[WRK] - Cifras Slider',
  },
  content: {
    modules: [
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - frame único con texto',
          frames: [ 
            { value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' },
          ],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description: 'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' }
        }
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider - dos frames con texto',
          frames: [ 
            { value: '10.000', unit: 'MM', caption: 'instalados en propiedad en las principales tecnologías' },
            { value: '22.087', unit: 'GWh', caption: 'prodicidos en 2018', descr: 'longer description for the second frame' },
          ],
          module_title: 'Capacidad y producción de ACCIONA en Energía',
          lead_text: 'ACCIONA es uno de los mayores operadores mundiales en energías renovables',
          module_description: 'La compañía es líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables, con un modelo de crecimiento garantizado en el tiempo con 1,2 GW en construcción, que se focaliza en las tecnologías con mayor grado de madurez y competitividad –eólica y solar fotovoltaica–. En 2018, ACCIONA ha aumentado en un 10 % la producción consolidada en propiedad con la entrada en operación de nuevos activos.',
          module_cta: { path: '#sample_path', label: 'Más sobre la capacidad y producción' }
        }
      },
      {
        component: 'CifrasSlider',
        props: {
          title: 'Cifras slider',
          frames: [ 
            { value: '22.087', unit: 'GWh', caption: 'producidos en 2018', descr: 'some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1 some descriptive text for frame 1some descriptive text for frame 1' },
            { value: '82,322', unit: 'MM', caption: 'caption 2' },
            { value: '7.321', unit: 'K', caption: 'caption 3', descr: 'some descriptive text for frame 3' },
            { value: '99,23', unit: '%', caption: 'satisfaction' },
            { value: '+38.544', caption: 'empleados' },
          ],
          // module_description: 'Garantizamos energía limpia y 100% renovable equivalente al consumo de 6 millones de hogares.'
        }
      },
    ]
  }
};
