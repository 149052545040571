import React from 'react';
import PropTypes from 'prop-types';

import * as componentsList from 'React/components';

const BasePage = ({ model }) => {
  const { pageTitle, modules } = model.content;

  return (
    <>
      {pageTitle && <h1 className="title--m">{pageTitle}</h1>}

      {modules.map((module, idx) => {
        const { component, props } = module;
        const componentInstance = componentsList[component];

        if (!componentInstance) {
          console.error(
            `Could not create componentInstance of ${component}. Check whether the component with this name exists.`
          );
          return;
        }
        // rendering nested components - 1 level
        const childrenConfig = props.children || [];
        props.children = childrenConfig.map((child, idx) => (
          <Component
            render={componentsList[child.component]}
            {...child.props}
            key={`page-child-component-${idx + 1}`}
            name={component}
          />
        ));

        // we have to trigger PropTypes check manually,
        // because we are not rendering components directly, so checkPropTypes isn't triggered by React
        PropTypes.checkPropTypes(componentInstance.propTypes, props, 'prop', component);

        return <Component render={componentInstance} {...props} key={`page-component-${idx + 1}`} name={component} />;
      })}
    </>
  );
};

// this component renders the component received as a prop
// it also passes props to its rendered child
const Component = (props) => {
  // DEV NOTES:
  // all components in DevTools and stack traces appear as Component
  // which makes it hard to debug
  // however this log will appear just before error message (if there is one)
  // so we can identify which component has error
  return <>{props.render(props)}</>;
};

export default BasePage;
