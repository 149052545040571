// import window from 'global'

import React, { useContext } from 'react';
// import { useSpring, animated, config } from 'react-spring';

import { Button } from 'React/components';
import uiContext from 'Data/_uiContext';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';
import { ReactComponent as Chevron_S } from 'Icons/chevron_down-10.svg';
import './NavSubsection.scss';

const NavSubsection = ({ children, level, order, sectionTitle }) => {
  const context = useContext(uiContext);

  const isVisible = context.mobileNavSubsState[level] === order;

  const toggleVisibility = () => {
    context.updateMobileNavSubsState(level, order);
  };

  // animated chevron Large and Small
  // const ChevronLA = animated(Chevron_L);
  // const ChevronSA = animated(Chevron_S);

  const [springProps, set] = useSpring(() => ({
    transform: 'rotate(0deg)',
  }));

  set({
    config: config.gentle,
    transform: `rotate(${isVisible ? 180 : 0}deg)`,
  });

  const sectionTitleKebabed = sectionTitle.toLowerCase().replace(/ /g, '-');

  return (
    <>
      <Button type="link" onClick={toggleVisibility} className="subsection__toggle">
        {sectionTitle}

        {level === 0 ? (
          <ChevronLA style={springProps} data-state={isVisible} />
        ) : (
          <ChevronSA style={springProps} data-state={isVisible} />
        )}
      </Button>
      <div
        data-section={sectionTitleKebabed}
        className={`nav_sub_section lvl-${level + 1}`}
        data-subsection-visible={isVisible}
      >
        {children}
      </div>
    </>
  );
};

export default NavSubsection;
