import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle } from 'React/components';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const DistribuidorDestacado = ({ linkExternal, logo, title, text, image, ods, download, soluciones, tagtitle }) => {
  let moduleRef = React.createRef();

  useSlowFade(moduleRef);

  useEffect(() => {
    function callback(entries, observer) {
      entries.forEach((el) => {
        if (el.isIntersecting == true) {
          el.target.classList.add('is-visible');
        }
      });
    }

    // Instancing a new IntersectionObserver
    const observer = new IntersectionObserver(callback);

    // Adding a target to be observed
    observer.observe(moduleRef.current);
  });

  return (
    <section ref={moduleRef} className={`module grid module-distribuidor-destacado grid-flow--left single`}>
      <div className="grid-container--left">
        <div className="image grid-container--left" style={{ backgroundImage: `url(${image})` }}>
          {ods && <img src={ods} className="ods" />}
          {logo && <img src={logo} className="logo" />}
        </div>
      </div>
      <div className={`grid-container--right`}>
        {tagtitle ? <TagTitle style="slow--y" title={tagtitle} /> : null}

        {title ? <h2 className="title--l slow--y">{title}</h2> : null}

        {text ? (
          <div className="mt-2">
            <p className="body--m slow--y">{text}</p>
          </div>
        ) : null}

        {soluciones === true && (
          <div className="soluciones-destacado slow--y">
            <ul className="grid-container">
              <li className="title--xs">
                <a href="#">
                  <EnergyIcon height="16" width="16" viewBox="0 0 24 24" />
                  Energía
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <MovilidadIcon height="16" width="16" viewBox="0 0 24 24" />
                  Movilidad
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <AguaIcon height="16" width="16" viewBox="0 0 24 24" />
                  Agua
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <SocialIcon height="16" width="16" viewBox="0 0 24 24" />
                  Social
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <CiudadesIcon height="16" width="16" viewBox="0 0 24 24" />
                  Ciudades
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <InmobiliariaIcon height="16" width="16" viewBox="0 0 24 24" />
                  Inmobiliaria
                </a>
              </li>
              <li className="title--xs">
                <a href="#">
                  <CapitalIcon height="16" width="16" viewBox="0 0 24 24" />
                  Capital
                </a>
              </li>
            </ul>
          </div>
        )}

        {download && (
          <div className="mt-3 slow--y">
            <Link type="download" label={download} />
          </div>
        )}

        {linkExternal && (
          <div className="mt-3 slow--y">
            <Link type="external" label={linkExternal} />
          </div>
        )}
      </div>
    </section>
  );
};

export default DistribuidorDestacado;
