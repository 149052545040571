import React, { useState, useEffect } from 'react';
import { useFade } from 'React/custom_hooks';
import { Link, EnlaceAgrupador } from 'React/components';

import './styles.scss';
import { TagTitle } from '..';

const DistribuidorMultiple = ({ moduleTitle, frames }) => {
  let moduleRef = React.createRef();
  useFade(moduleRef);
  return (
    <div ref={moduleRef} className="module distribuidor-multiple grid">
      {moduleTitle && <TagTitle style="slow" title={moduleTitle} />}

      {frames.map(
        (
          { type, title, desktopImage, mobileImage, body, link, linkGroup, fileParams, download, list, singleList },
          idx
        ) => {
          const imageUrl = useWindowWidth() >= 1023 ? desktopImage : mobileImage;

          return (
            <div key={`image-frame-${idx}`} className="distribuidor-multiple__item grid full-width slow">
              {type === 'logo' && (
                <div className="image-src slow">
                  <img src={imageUrl} />
                </div>
              )}
              {type !== 'logo' && <div style={{ backgroundImage: `url(${imageUrl})` }} className="image slow"></div>}

              <div className="content slow">
                <h2 className="title--s slow">{title}</h2>
                <div className="body--m slow">{body}</div>
                <div className="links-container slow">
                  {link && (
                    <div>
                      <Link type="arrow" label={link} />
                    </div>
                  )}

                  {download && (
                    <div>
                      <Link type="download" label={link} fileParams={fileParams} />
                    </div>
                  )}

                  {linkGroup && (
                    <div>
                      <EnlaceAgrupador links={linkGroup.links} box_label={linkGroup.label} />
                    </div>
                  )}

                  {list && !singleList && (
                    <ul className="list">
                      {list.map((item, idx) => {
                        return (
                          <li key={`text-p-${idx}`} className="body--m main-text-paragraph slow">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {list && singleList && (
                    <ul className="list list--single">
                      {list.map((item, idx) => {
                        return (
                          <li key={`text-p-${idx}`} className="body--m main-text-paragraph slow">
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
};

export default DistribuidorMultiple;
