// this is used for unfinished pages
const generic_model = {
  meta: {
    title: '[ Redbility - WiP ]',
  },
  content: {
    modules: [],
  },
};

import { PageWithAnimations, Elements } from 'React/views';

// DATA MODELS
// development data models
import {
  // pages
  base_page,

  // modules
  header,
  header_slider,
  distribuidor_destacado,
  distribuidor_destacado_slider,
  module_news,
  items_filter,
  lightbox,
  areas_de_actividad,
  cifras_slider,
  cifras,
  trabaja_con_nosotros,
  module_texto,
  banners,
  distribuidor_2_col,
  share_social,
  distribuidor_iconos,
  breadcrumbs,
  wysiwyg,
  mediateca,
  distribuidor_multiple,
  descargas,
  module_soluciones,
  module_proyectos,
  timeline,
  module_testimonios,
  module_testimonios_slider,
  module_table,
  soluciones_botonera,
  acordeon,
  logos_slider,
  projects_map,
  projects_map_static,
  enlace_agrupador,
  pdf_reader,
  development_goals,
  RSSfeed,
  paginador,
  newsletter_subscription_form,
  soluciones_mosaico,
  cookies,
  soluciones_story_final,
  soluciones_debug,
  soluciones_story_final_en,
  prueba_viewbox,

  //templates
  template_home,
  template_ancho_completo,
  template_ancho_completo_imagen,
  template_ancho_completo_imagen_single,
  template_ancho_completo_video,
  template_soluciones,
  template_detalle_solucion,
  template_detalle_solucion_interior,
  template_detalle_solucion_interior_tecnologias,
  template_detalle_solucion_interior_single,
  template_proyectos_resultados_filtros,
  template_proyectos_resultados_filtros_max,
  template_proyectos_resultados_filtro,
  template_historicos_informes,
  template_informes_presentaciones,
  template_historico_juntas,
  template_ofertas_empleo,
  template_ofertas_empleo_sin_resultados,
  template_detalle_empleo,
  template_informe_anual_integrado,
  template_cta,
  template_cta_download,
  template_noticias,
  template_resultados,
  template_detalle_proyecto,
  template_proyectos,
  template_agenda_inversor,
  template_hechos_relevantes,
  template_country,
  template_landing,
  template_landing_footer,
  template_landing_logo,
  template_contacts,
  template_detalle_noticia,
  template_detalle_persona,
  template_404,
  template_mapa_web,
  template_webs_acciona,
  template_equipo_directivo,

  // Pages
  page_sobre_acciona,
  page_detalle_empleo,
  page_ofertas_empleo,
  page_historia,
  page_plan_social,
  page_portada_soluciones,
  page_detalle_solucion,
  page_detalle_solucion_areas_actividad,
  page_portada_proyectos,
  page_proyectos_resultados,
  page_detalle_proyecto,
  page_informe_anual_integrado_2018,
  page_informe_anual_integrado,
  page_informes_trimestrales,
  page_agenda_inversor,
  page_area_clientes,
  page_hechos_relevantes,
  page_detalle_noticia,
  page_mapa_web,
  page_webs_acciona,
  page_404,
  page_resultados,
  page_sin_resultados,
  page_equipo_directivo,

  //lightbox
  lightbox_contacto,
  lightbox_contacto_errores,
  lightbox_contacto_confirmacion,
  contacts,
} from 'Data/';

// layout without header and footer
import baseLayout from 'React/layout/base';

const devRoutes = [
  // layout
  { path: '/header-and-footer', model: generic_model },

  // PoC pages
  {
    path: '/page-with-animations',
    view: PageWithAnimations,
    model: generic_model,
  },
  { path: '/elements', view: Elements, model: generic_model },
  { path: '/base-page', model: base_page },

  // templates pages
  // { path: '/templates/404', baseLayout, view: PageNotFound, model: generic_model },
  {
    path: '/templates/template-detalle-solucion',
    model: template_detalle_solucion,
  },
  { path: '/templates/template-home', model: template_home },
  { path: '/templates/template-ancho-completo', model: template_ancho_completo },
  { path: '/templates/template-ancho-completo-imagen', model: template_ancho_completo_imagen },
  { path: '/templates/template-ancho-completo-imagen-single', model: template_ancho_completo_imagen_single },
  { path: '/templates/template-ancho-completo-video', model: template_ancho_completo_video },
  { path: '/templates/template-soluciones', model: template_soluciones },
  { path: '/templates/template-detalle-solucion-interior', model: template_detalle_solucion_interior },
  {
    path: '/templates/template-detalle-solucion-interior-tecnologias',
    model: template_detalle_solucion_interior_tecnologias,
  },
  { path: '/templates/template-detalle-solucion-single', model: template_detalle_solucion_interior_single },
  { path: '/templates/template-proyectos-resultados-filtros', model: template_proyectos_resultados_filtros },
  { path: '/templates/template-proyectos-resultados-filtros-max', model: template_proyectos_resultados_filtros_max },
  { path: '/templates/template-proyectos-resultados-filtro', model: template_proyectos_resultados_filtro },
  { path: '/templates/template-proyectos', model: template_proyectos },
  { path: '/templates/template-historicos-informes', model: template_historicos_informes },
  { path: '/templates/template-informes-presentaciones', model: template_informes_presentaciones },
  { path: '/templates/template-historico-juntas', model: template_historico_juntas },
  { path: '/templates/template-ofertas-empleo', model: template_ofertas_empleo },
  { path: '/templates/template-ofertas-empleo-sin-resultados', model: template_ofertas_empleo_sin_resultados },
  { path: '/templates/template-detalle-empleo', model: template_detalle_empleo },
  { path: '/templates/template-informe-anual', model: template_informe_anual_integrado },
  { path: '/templates/template-cta', model: template_cta },
  { path: '/templates/template-cta-download', model: template_cta_download },
  { path: '/templates/template-resultados', model: template_resultados },
  { path: '/templates/template-noticias', model: template_noticias },
  { path: '/templates/template-agenda-inversor', model: template_agenda_inversor },
  { path: '/templates/template-hechos-relevantes', model: template_hechos_relevantes },
  { path: '/templates/template-country', model: template_country },
  { path: '/templates/template-header-landing', model: template_landing },
  { path: '/templates/template-landing-logo', model: template_landing_logo },
  { path: '/templates/template-landing-footer', model: template_landing_footer },
  { path: '/templates/template-contacts', model: template_contacts },
  { path: '/templates/template-detalle-proyecto', model: template_detalle_proyecto },
  { path: '/templates/template-detalle-noticia', model: template_detalle_noticia },
  { path: '/templates/template-detalle-persona', model: template_detalle_persona },
  { path: '/templates/404', model: template_404 },
  { path: '/templates/template-mapa-web', model: template_mapa_web },
  { path: '/templates/template-webs-acciona', model: template_webs_acciona },
  { path: '/templates/template-equipo-directivo', model: template_equipo_directivo },

  // components pages
  { path: '/modules/header-slider', layout: baseLayout, model: header_slider },
  { path: '/modules/header', layout: baseLayout, model: header },
  {
    path: '/modules/distribuidor-destacado',
    layout: baseLayout,
    model: distribuidor_destacado,
  },
  {
    path: '/modules/distribuidor-destacado-slider',
    layout: baseLayout,
    model: distribuidor_destacado_slider,
  },
  { path: '/modules/noticias', layout: baseLayout, model: module_news },
  { path: '/modules/items-filter', layout: baseLayout, model: items_filter },
  { path: '/modules/lightbox', layout: baseLayout, model: lightbox },
  {
    path: '/modules/areas-de-actividad',
    layout: baseLayout,
    model: areas_de_actividad,
  },
  { path: '/modules/cifras-slider', layout: baseLayout, model: cifras_slider },
  { path: '/modules/cifras', layout: baseLayout, model: cifras },
  {
    path: '/modules/work-with-us',
    layout: baseLayout,
    model: trabaja_con_nosotros,
  },
  { path: '/modules/texto', layout: baseLayout, model: module_texto },
  { path: '/modules/cookies', layout: baseLayout, model: cookies },
  { path: '/modules/banners', layout: baseLayout, model: banners },
  {
    path: '/modules/distribuidor-2-col',
    layout: baseLayout,
    model: distribuidor_2_col,
  },
  { path: '/modules/shares-social', layout: baseLayout, model: share_social },
  {
    path: '/modules/distribuidor-iconos',
    layout: baseLayout,
    model: distribuidor_iconos,
  },
  { path: '/modules/breadcrumbs', layout: baseLayout, model: breadcrumbs },
  { path: '/modules/wysiwyg', layout: baseLayout, model: wysiwyg },
  { path: '/modules/mediateca', layout: baseLayout, model: mediateca },
  {
    path: '/modules/distribuidor-multiple',
    layout: baseLayout,
    model: distribuidor_multiple,
  },
  { path: '/modules/descargas', layout: baseLayout, model: descargas },
  {
    path: '/modules/solucion-destacada',
    layout: baseLayout,
    model: module_soluciones,
  },
  {
    path: '/modules/soluciones-botonera',
    layout: baseLayout,
    model: soluciones_botonera,
  },
  { path: '/modules/proyectos', layout: baseLayout, model: module_proyectos },
  { path: '/modules/timeline', layout: baseLayout, model: timeline },
  {
    path: '/modules/testimonios',
    layout: baseLayout,
    model: module_testimonios,
  },
  {
    path: '/modules/testimonios-slider',
    layout: baseLayout,
    model: module_testimonios_slider,
  },
  { path: '/modules/table', layout: baseLayout, model: module_table },
  { path: '/modules/acordeon', layout: baseLayout, model: acordeon },
  { path: '/modules/logos', layout: baseLayout, model: logos_slider },
  { path: '/modules/mapa-proyectos', layout: baseLayout, model: projects_map },
  { path: '/modules/mapa-proyectos-static', layout: baseLayout, model: projects_map_static },
  {
    path: '/modules/enlace-agrupador',
    layout: baseLayout,
    model: enlace_agrupador,
  },
  { path: '/modules/pdf-reader', layout: baseLayout, model: pdf_reader },
  { path: '/modules/development-goals', layout: baseLayout, model: development_goals },
  { path: '/modules/rss-feed', layout: baseLayout, model: RSSfeed },
  { path: '/modules/paginador', layout: baseLayout, model: paginador },
  { path: '/modules/newsletter-subscription-form', layout: baseLayout, model: newsletter_subscription_form },
  { path: '/modules/soluciones-mosaico', layout: baseLayout, model: soluciones_mosaico },
  { path: '/modules/soluciones-story-final', layout: baseLayout, model: soluciones_story_final },
  { path: '/modules/soluciones-debug', layout: baseLayout, model: soluciones_debug },
  { path: '/modules/soluciones-story-final-en', layout: baseLayout, model: soluciones_story_final_en },
  { path: '/modules/prueba-viewbox', layout: baseLayout, model: prueba_viewbox },

  // pages
  { path: '/pages/sobre-acciona', model: page_sobre_acciona },
  { path: '/pages/detalle-empleo', model: page_detalle_empleo },
  { path: '/pages/ofertas-empleo', model: page_ofertas_empleo },
  { path: '/pages/historia', model: page_historia },
  { path: '/pages/plan-social', model: page_plan_social },
  { path: '/pages/portada-soluciones', model: page_portada_soluciones },
  { path: '/pages/detalle-solucion', model: page_detalle_solucion },
  { path: '/pages/informes-trimestrales', model: page_informes_trimestrales },
  {
    path: '/pages/detalle-solucion-areas-actividad',
    model: page_detalle_solucion_areas_actividad,
  },
  { path: '/pages/portada-proyectos', model: page_portada_proyectos },
  { path: '/pages/proyectos-resultados', model: page_proyectos_resultados },
  { path: '/pages/detalle-proyecto', model: page_detalle_proyecto },
  {
    path: '/pages/informe-anual-integrado',
    model: page_informe_anual_integrado,
  },
  { path: '/pages/lightbox-contacto', model: lightbox_contacto },
  { path: '/pages/lightbox-contacto-errores', model: lightbox_contacto_errores },
  { path: '/pages/lightbox-contacto-confirmacion', model: lightbox_contacto_confirmacion },
  {
    path: '/pages/informe-anual-integrado',
    model: page_informe_anual_integrado,
  },
  {
    path: '/pages/informe-anual-integrado-2018',
    model: page_informe_anual_integrado_2018,
  },
  {
    path: '/pages/area-clientes',
    model: page_area_clientes,
  },
  {
    path: '/pages/agenda-inversor',
    model: page_agenda_inversor,
  },
  {
    path: '/pages/hechos-relevantes',
    model: page_hechos_relevantes,
  },
  {
    path: '/pages/detalle-noticia',
    model: page_detalle_noticia,
  },
  { path: '/pages/contacto', model: contacts },
  { path: '/pages/mapa-web', model: page_mapa_web },
  { path: '/pages/webs-acciona', model: page_webs_acciona },
  { path: '/pages/404', model: page_404 },
  { path: '/pages/resultados', model: page_resultados },
  { path: '/pages/sin-resultados', model: page_sin_resultados },
  { path: '/pages/equipo-directivo', model: page_equipo_directivo },
];

export default devRoutes;
