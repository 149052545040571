export default {
  meta: {
    title: 'Detalle solución Interior tecnologías',
    templateClass: 'detalle-solucion-interior template-ancho-completo-imagen',
    stickyHeader: false,
  },
  content: {
    modules: [
      {
        component: 'withSolucionesNav',
        props: {
          interior: true,
          children: [
            {
              component: 'HeroImage',
              props: {
                imageUrl: 'https://source.unsplash.com/random/2560x1440?wind',
                title: 'Energía limpia para un sistema energético sostenible',
                body:
                  'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
                cta: { type: 'link', target: '#some-cta' },
              },
            },
          ],
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          pageTitle: 'Título de página',
          intro:
            'La investigación y la apuesta por la innovación tecnológica del negocio de Agua de ACCIONA se encuentran detrás de una gestión eficiente y un desarrollo sostenible. Como resultado de un trabajo de desarrollo e investigación, se han registrado más de 25 patentes relacionadas con desalinización, purificación y reutilización de tecnologías de agua.',
        },
      },
      {
        component: 'Distribuidor2Col',
        props: {
          tagLine: 'Distribuidor 2 Columnas',
          items: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'Reactor Biológico Secuencial, BRAINYSBR®',
              leadText:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              downloadItem: 'Descárgate toda la información sobre esta tecnología',
              fileParams: 'PDF 814KB',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'CODIGESTIÓN',
              leadText:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'Título',
              leadText:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información sobre esta tecnología',
            },
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_tecnologias_L.jpg',
              title: 'Título',
              leadText:
                'En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.',
              linkItem: 'Descárgate toda la información sobre esta tecnología',
            },
          ],
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
    ],
  },
};
