// button.component.js
import React from 'react';
import { TagTitle, EnlaceAgrupador } from 'React/components';

import './Styles.scss';


const Agenda = ({ dates, datesPast }) => {
 return (
	 <div className="template-agenda grid">
		 <div className="grid-container agenda-activo">
		{ dates.map((item, idx) => {
          const { day, title, month, place, text, link } = item;

          return (
			<div className="agenda__item" key={`key-${idx}`}>
				<div className="agenda__day">
					<div className="day cifras--l">{day}</div>
					<div className="month title--xs">{month}</div>
				</div>
				<div className="agenda__content">
					<h3 className="title--s">{title}</h3>
					<div className="agenda__container">
						<div className="title--xs">{place}</div>

						{ link && 
							<div className="title--xs"><EnlaceAgrupador links={link.path} box_type="subscribe" /></div>
						}

					</div>
					{ text && 
						<p className="body--m">{text}</p>			
					}
				</div>
			</div>
          );
        })}
	</div>
	<div className="grid-container">
		<div className="agenda-header ">
			<TagTitle title="Eventos finalizados de 2019 (10 eventos)" />
			<div className="select_input">
				<div className="select_wrapper">
					<select className="body--m">
						<option className="body--m">2019</option>
						<option className="body--m">2018</option>
						<option className="body--m">2017</option>
					</select>
					<label className="taglabel">Año</label>
				</div>
			</div>
		</div>
		<div className="agenda-pasado grid-container">
		{ datesPast.map((item, idx) => {
			const {title, place } = item;

			return (
				<div className="agenda__item" key={`key-${idx}`}>
					<div className="agenda__content">
						<h3 className="title--s">{title}</h3>
						<div className="agenda__container">
							<div className="title--xs">{place}</div>
							<div className="title--xs">12 septiembre 2019</div>
						</div>
					</div>
				</div>
			);
			})}
		 </div>
		</div>
	 </div>
	)

};

export default Agenda;