// to add more icon options, update IconsMap file

import React, { useState } from 'react';

import './styles.scss';
import { TagTitle, InputRangeSlider } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';

import { useSliderSwipe } from 'React/custom_hooks';

import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';
import IconsMap from './_iconsMap';
import { useFade } from '../../custom_hooks';

const DistribuidorIconos = ({ title, frames }) => {
  const [activeFrame, setActiveFrame] = useState(1);
  const thisModule = React.createRef();
  const context = window.innerWidth;

  const prevButton = React.createRef();
  const nextButton = React.createRef();

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const nextFrame = () => {
    if (activeFrame < sliderFramesCount) {
      setActiveFrame(activeFrame + 1);
    }
  };

  const prevFrame = () => {
    if (activeFrame > 1) {
      setActiveFrame(activeFrame - 1);
    }
  };

  const sliderSpans = (framesCount) => {
    if (context < 1024) return `${100 * framesCount}vw`;

    const spanCount = framesCount % 3 === 0 ? framesCount / 3 : parseInt(framesCount / 3) + 1;

    return `${100 * spanCount}%`;
  };

  const sliderOffset = (framesCount, activeFrame) => {
    if (context < 1024) {
      return `${100 * (activeFrame - 1) * -1}vw`;
    }
    const extraSlots = framesCount % 3 === 0 ? 0 : 3 - (framesCount % 3);

    return (100 / (framesCount + extraSlots)) * (activeFrame - 1) * -1 + '%';
  };

  const framesContainerStyle = {
    width: sliderSpans(frames.length),
    transform: `translateX(${sliderOffset(frames.length, activeFrame)})`,
  };

  const refList = frames.map((frame) => React.createRef());

  const onHover = (event) => {
    const { frameId } = event.currentTarget.dataset;
    refList[parseInt(frameId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { frameId } = event.currentTarget.dataset;
    refList[parseInt(frameId)].current.classList.remove('is-hovered');
  };

  const sliderFramesCount = context >= 1024 ? frames.length - 2 : frames.length;

  useSliderSwipe(thisModule, setActiveFrame, sliderFramesCount);
  useFade(thisModule);

  return (
    <div className="module grid distribuidor-iconos with-slider" data-active-frame={activeFrame} ref={thisModule}>
      <div className="inner-container slow">
        {frames.length > 1 && (
          <>
            <button
              className="ghost-frame-button ghost-frame-button--left"
              onMouseOver={prevHover}
              onMouseOut={removeHover}
              onClick={prevFrame}
            >
              Slider anterior
            </button>
            <button
              className="ghost-frame-button ghost-frame-button--right"
              onMouseOver={nextHover}
              onMouseOut={removeHover}
              onClick={nextFrame}
            >
              Slider siguiente
            </button>
          </>
        )}
        <TagTitle style="slow" title={title} />
        <div className="frames-container" style={framesContainerStyle}>
          {frames.map((frame, idx) => {
            const { icon_type, title, text } = frame;

            return (
              <div className="frame" data-frame-id={idx + 1} key={`tecno-frame-${idx}`} ref={refList[idx]}>
                <div className="icon" data-frame-id={idx + 1}>
                  {IconsMap[icon_type]}
                </div>
                <div className="text">
                  <h2 className="icon-label title--xs">{icon_type}</h2>
                  <h3 className="frame-title title--m" data-frame-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                    <a href="">{title}</a>
                  </h3>
                  <p className="body--m">{text}</p>
                  <div data-frame-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="frame-cta">
                    <a href="#">
                      <IconArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <InputRangeSlider
          changeHandler={setActiveFrame}
          noIndicator
          framesCount={sliderFramesCount}
          activeFrame={parseInt(activeFrame)}
        />
        <div className="slider-controls slow">
          <button ref={prevButton} className="prev-button" onClick={prevFrame}>
            <IconArrowRightLong />
          </button>
          <button ref={nextButton} className="next-button" onClick={nextFrame}>
            <IconArrowRightLong />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DistribuidorIconos;
