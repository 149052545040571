export default {
  meta: {
    title: '[WRK] - Mediateca',
  },
  content: {
    modules: [
      {
        component: 'Mediateca',
        props: {
          titleTag: 'Mediateca - imagenes',
          mediaList: [
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_type: 'image',
              preview_size: 'full',
              descr:
                'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              media_cta: {
                label: 'Descargar documento',
                path: '#download_media_path',
                type: 'download',
                fileParams: 'PDF 1.2Mb',
              },
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              gallery_icon: 'instagram',
              descr:
                'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              // 'Descripcion corta',
              media_cta: {
                label: 'Leer el estudio',
                path: '#internal_redirect_path',
                type: 'internal',
              },
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion un poco menos corta',
              media_cta: {
                label: 'Ver el articulo',
                path: '#extenral_redirect_path',
                type: 'external',
              },
            },
          ],
        },
      },
      {
        component: 'Mediateca',
        props: {
          titleTag: 'Mediateca - imagenes & video',
          mediaList: [
            {
              preview_url:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_url: 'https://youtu.be/eWpZ9mPEoOc',
              src_type: 'video',
              preview_size: 'full',
              descr: 'Un media item sin enlace',
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              gallery_icon: 'infografia',
              descr: 'Descripcion corta',
              media_cta: {
                label: 'Leer el estudio',
                path: '#internal_redirect_path',
                type: 'internal',
              },
            },
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_L.jpg',
              src_type: 'image',
              preview_size: 'half',
              descr: 'Descripcion un poco menos corta',
              media_cta: {
                label: 'Ver el articulo',
                path: '#extenral_redirect_path',
                type: 'external',
              },
            },
          ],
        },
      },
      {
        component: 'Mediateca',
        props: {
          titleTag: 'Mediateca - imagen única',
          mediaList: [
            {
              src_url: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_mediateca_XL.jpg',
              src_type: 'image',
              preview_size: 'full',
              descr:
                'Los Objetivos de Desarrollo Sostenible aseguran que todas las personas tengan las mismas oportunidades de desarrollo y bienestar.',
              media_cta: {
                label: 'Descargar documento',
                path: '#download_media_path',
                type: 'download',
                fileParams: 'PDF 1.2Mb',
              },
            },
          ],
        },
      },
    ],
  },
};
