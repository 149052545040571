export default {
  meta: {
    title: 'Header',
  },
  content: {
    modules: [
      {
        component: 'Header',
        props: {
        },
      },
    ],
  },
};
