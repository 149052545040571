import React, { useEffect } from 'react';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const SolucionesFixed = () => {
  useEffect(() => {
    const appWrapper = document.querySelector('.app--layout');

    appWrapper.addEventListener('scroll', function() {
      let mainHeight = document.getElementById('main').getBoundingClientRect().height;
      let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
      let solucionesBotonera = document.getElementById('soluciones-botonera').getBoundingClientRect().bottom;
      let solucionesFixed = document.getElementById('soluciones-fixed');

      if (solucionesBotonera < -10) {
        solucionesFixed.classList.add('is-fixed');
      } else {
        solucionesFixed.classList.remove('is-fixed');
      }

      if (winScroll > mainHeight) {
        solucionesFixed.classList.add('is-done');
      } else {
        solucionesFixed.classList.remove('is-done');
      }
    });
  });

  return (
    <div className="soluciones-fixed" id="soluciones-fixed">
      <ul>
        <li className="title--xs">
          <a href="#">
            <EnergyIcon viewBox="0 0 24 24" />
            Energía
          </a>
        </li>
        <li className="title--xs">
          <a href="#">
            <MovilidadIcon viewBox="0 0 24 24" />
            Transporte
          </a>
        </li>
        <li className="title--xs">
          <a href="#">
            <AguaIcon viewBox="0 0 24 24" />
            Agua
          </a>
        </li>
        <li className="title--xs">
          <a href="#">
            <SocialIcon viewBox="0 0 24 24" />
            Social
          </a>
        </li>
        <li className="title--xs">
          <a href="#">
            <CiudadesIcon viewBox="0 0 24 24" />
            Ciudades
          </a>
        </li>

        <li className="title--xs">
          <a href="#">
            <InmobiliariaIcon viewBox="0 0 24 24" />
            Inmobiliaria
          </a>
        </li>
        <li className="title--xs">
          <a href="#">
            <CapitalIcon viewBox="0 0 24 24" />
            Capital
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SolucionesFixed;
