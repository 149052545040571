import React from 'react';

const TitleLink = ({title}) => (
	<div className={`module grid`}>
		<div className={`grid-container`}>
			{ title ? <div className="module-text__title"><h2 className="title--tag">{title}</h2></div> : null }
		</div>
	</div>
  )

export default TitleLink;
