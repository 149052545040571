import React from 'react';

import { Link } from 'React/components';

import './Styles.scss';


const websAcciona = () => {
 return (
	 <div className="module-webs module grid">
		 <div className="grid-container">
			 <div className="item">
				<h2 className="title--s">Corporación</h2>
				<nav>
					<Link type="external" label="Acciona" />
					<Link type="external" label="Acciona Experience" />
					<Link type="external" label="SOSTENIBILIDAD PARA TODOS" />
					<Link type="external" label="I’mnovation#Hub" />
				</nav>					
			 </div>
			 <div className="item">
				<h2 className="title--s">Países</h2>
				<nav>
					<Link type="external" label="Australia" />
					<Link type="external" label="Canadá" />
					<Link type="external" label="EEUU" />
					<Link type="external" label="México" />
					<Link type="external" label="Brasil" />
					<Link type="external" label="Oriente Medio" />
				</nav>
			</div>
				<div className="item">
					<h2 className="title--s"><a href="">Otros negocios</a></h2>
					<nav>
						<Link type="external" label="Mostostal Warszawa" />
						<Link type="external" label="Campus Residencial Estudiantes" />
						<Link type="external" label="Bestinver" />
						<Link type="external" label="Grupo Bodegas Palacio 2894" />
					</nav>
				</div>
			</div>
		</div>
	)

};

export default websAcciona;