import React from 'react';
import ReactDOM from 'react-dom';
// import { Controller, Scene } from 'react-scrollmagic';
// import { Timeline, Tween } from 'react-gsap';

// assets
import solarPanels from 'Images/solar-panels-field.png';
import iconWindTurbine from 'Icons/wind-turbine.svg';

// components
// import { ProjectsMap } from 'React/components/';

const PageWithAnimations = () => {
  const listStagger = {
    opacity: 0,
    cycle: {
      x: function(idx) {
        return idx * 30;
      },
    },
  };

  return (
    <>
      {/* <h2>Page with animations</h2>
      <h4>Staggering list</h4>
      <Tween wrapper={<ul className="staggering-list" />} duration={0.75} ease={Back.easeOut} staggerFrom={listStagger}>
        <li>Item_1</li>
        <li>Item_2</li>
        <li>Item_3</li>
        <li>Item_4</li>
        <li>Item_5</li>
      </Tween>

      <br />
      <div className="demo-animated-module">
        <h1 className="title">
          Business
          <br />
          as unusual
        </h1>
        <div className="text-block">
          <p>
            En ACCIONA hacemos negocio de una manera diferente: no solo miramos por nuestros intereses económicos, sino
            que buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad y al planeta.
          </p>
          <a href="#">Descubre nuestro propósito</a>
        </div>
        <Controller>
          <Scene classToggle="animated">
            <div className="visual-block">
              <img src={solarPanels} alt="Solar panel field" className="primary-visual" />
              <div className="secondary-visual">
                <img src={iconWindTurbine} alt="" />
              </div>
            </div>
          </Scene>

          <Scene duration={2000} triggerHook="onEnter">
            <Timeline target={<h1 className="x-axis-scroll-title">long and meaningful statement</h1>}>
              <Tween to={{ x: '-100%' }} ease={Power1.easeInOut} />
            </Timeline>
          </Scene>

          <Scene duration={2000} triggerHook="onEnter">
            <Timeline target={<h1 className="x-axis-scroll-title">long and meaningful statement</h1>}>
              <Tween to={{ x: '-100%' }} ease={Power1.easeInOut} />
            </Timeline>
          </Scene>
        </Controller>

        <Controller>
          <Scene classToggle="animated">
            <div className="visual-block">
              <img src={solarPanels} alt="Solar panel field" className="primary-visual" />
              <div className="secondary-visual">
                <img src={iconWindTurbine} alt="" />
              </div>
            </div>
          </Scene>
        </Controller>
      </div> */}
    </>
  );
};

export default PageWithAnimations;
