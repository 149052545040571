export default {
  meta: {
    title: 'Landing',
    templateClass: 'template-ancho-completo',
    routesSet: null,
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          pageTitle: 'Cabecera landing',
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
    ],
  },
};
