export default {
  meta: {
    title: 'Paginación',
    layout: 'base',
  },
  content: {
    modules: [
      {
        component: 'Paginator',
        props: {},
      },
    ],
  },
};
