import React, { useState, useEffect } from 'react';

import './styles.scss';

import { TagTitle, Lightbox, Link } from 'React/components';
import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';
import { ReactComponent as IconPlus } from 'Icons/iconplus.svg';

import { useSlowFade } from 'React/custom_hooks';

const EquipoDirectivo = ({ title, frames }) => {
  const [activeAudio, setActiveAudio] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const showLightbox = (e) => {
    setModalIsVisible(true);
    document.querySelector('.app--layout').dataset.canScroll = false;
  };

  const hideLightbox = () => {
    setModalIsVisible(false);
    document.querySelector('.app--layout').dataset.canScroll = true;
  };

  // slider functionality
  const thisModule = React.createRef();

  const prevButton = React.createRef();
  const nextButton = React.createRef();

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  useSlowFade(thisModule);

  // audio controls
  const audioElementRefs = frames.map((element) => React.createRef());
  const audioStatsRefs = frames.map((element) => React.createRef());

  const togglePlay = (frameId) => {
    setActiveAudio((oldState) => (oldState === frameId ? null : frameId));
  };

  useEffect(() => {
    audioElementRefs.forEach((ref) => {
      if (ref.current != null) {
        ref.current.pause();
      }
    });

    if (activeAudio !== null) {
      audioElementRefs[activeAudio - 1].current.play();
    }
  }, [activeAudio]);

  // audio stats
  const formatedAudioTime = (timeFloat) => {
    if (timeFloat === Infinity) return '<i>∞</i>';

    const seconds = parseInt(timeFloat);
    if (seconds < 60) return `0’${seconds}’’`;

    if (seconds >= 60) return `${parseInt(seconds / 60)}’${seconds % 60}’’`;

    return timeFloat;
  };

  useEffect(() => {
    audioElementRefs.forEach((audioEl, idx) => {
      if (audioStatsRefs[idx].current != null) {
        const statsElement = audioStatsRefs[idx].current;
        const [oldTime, oldDuration] = statsElement.innerText.split('/');

        audioEl.current.addEventListener('loadedmetadata', (event) => {
          const { duration } = event.target;

          statsElement.innerHTML = `${oldTime} / ${formatedAudioTime(duration)}`;
        });

        audioEl.current.addEventListener('timeupdate', (event) => {
          const { currentTime, duration } = event.target;

          statsElement.innerHTML = `${formatedAudioTime(currentTime)} / ${formatedAudioTime(duration)}`;
        });
      }
    });
    return () => {
      audioElementRefs.forEach((audioEl) => {
        audioEl.current.removeEventListener('loadedmetadata');
        audioEl.current.removeEventListener('timeupdate');
      });
    };
  }, []);

  const countriesList = [
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'Japón', path: '#japan' },
    { label: 'Alemania', path: '#germany' },
    { label: 'Australia', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'ESTADOS UNIDOS Y CANADÁ', path: '#australia' },
    { label: 'Canadá', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
    { label: 'Japón', path: '#japan' },
    { label: 'Alemania', path: '#germany' },
    { label: 'Australia', path: '#australia' },
    { label: 'SINGAPUR, VIETNAM & FILIPINAS', path: '#canada' },
    { label: 'Chile', path: '#chilie' },
  ];

  return (
    <div className="module grid equipo-directivo" ref={thisModule}>
      <div className="slow--y equipo-directivo-container grid full-width">
        {title && <TagTitle title={title} />}

        {frames.map((frame, idx) => {
          const { title, subtitle, innercontent, link } = frame;
          return (
            <div className="frame full-width" key={`tecno-frame-${idx}`}>
              <h2 className="title--s">{title}</h2>
              <h3 className="name link">
                {subtitle} <IconDireccion />
              </h3>
              <div>
                <Link type="arrow" label="Conoce más" />
              </div>
              <div className="inner-content">
                <ul>
                  <li className="body--s">
                    <strong>Construcción:</strong> Huberto Moreno
                  </li>
                  <li className="body--s">
                    <strong> Service:</strong> Nicolás Corral <IconDireccion />
                  </li>
                  <li className="body--s">
                    <strong>Concesiones:</strong> Diego Martín
                  </li>
                  <li className="body--s">
                    <strong>Operaciones:</strong> Carlos Arilla
                    <IconDireccion />
                  </li>
                  <li className="body--s">
                    <strong>Agua:</strong> José Díaz-Caneja
                  </li>
                </ul>
              </div>
              <div className="frame-content">
                <a href="#" onClick={showLightbox} className="link link--icon icon--red link--lightbox">
                  <span>
                    Ver más países
                    <IconPlus />
                  </span>
                </a>
              </div>
            </div>
          );
        })}
      </div>
      {modalIsVisible && (
        <Lightbox isVisible={modalIsVisible} closeMethod={hideLightbox}>
          <div className="countries_equipo grid full-width">
            <div className="grid-container">
              <div className="header title--l">
                <span>Países</span>
              </div>
              <div className="body">
                {countriesList.map((country, linkCountry, idx) => (
                  <div path={country.path} key={`country-item-${idx}`}>
                    <div className="title--s">{country.label}</div>
                    <p className="name link">
                      Macarena Carrión <IconDireccion />
                    </p>
                    <div>
                      <Link type="arrow" label="Conoce más" />
                    </div>
                  </div>
                ))}
              </div>
              <p className="legend link slow--y">
                <IconDireccion />
                PERSONAS QUE OSTENTAN CARGOS CON RESPONSABILIDAD DE DIRECCIÓN A EFECTOS DE LO PREVISTO EN EL REGLAMENTO
                (UE) Nº596/2014, DE 16 DE ABRIL, SOBRE EL ABUSO DE MERCADO.
              </p>
            </div>
          </div>
        </Lightbox>
      )}
    </div>
  );
};

export default EquipoDirectivo;
