import React, { useState } from 'react';

import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';

import './style.scss';

import { TagTitle, InputRangeSlider, Link } from 'React/components';

const TrabajaConNosotros = ({ title, frames }) => {
  const [activeFrame, setActiveFrame] = useState(1);

  const refList = frames.map((frame) => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.remove('is-hovered');
  };

  return (
    <div className="module grid jobs-slider" data-active-frame={activeFrame}>
      <TagTitle title={title} />

      <InputRangeSlider
        changeHandler={setActiveFrame}
        framesCount={frames.length}
        activeFrame={parseInt(activeFrame)}
        noIndicator
      />

      <div className="jobs-container">
        {frames.map((frame, idx) => {
          const { link, sector, sectorLink, jobTitle, jobDate, jobPlace, jobText, id } = frame;

          return (
            <div className="job-description grid" key={`data-point-${idx}`} ref={refList[idx]}>
              <div className="job-header">
                <a href={link} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                  <h2 className="title--s">{jobTitle}</h2>
                </a>
                <div className="job-subheader">
                  <span className="taglabel">
                    <a className="job-sector" title={sector} href={sectorLink}>
                      <IconEnergy />
                      {sector}
                    </a>
                  </span>
                  <span className="taglabel">
                    <a href="#" className="job-sector">
                      {jobPlace}
                    </a>
                  </span>
                  <span className="taglabel">
                    <IconCalendar />
                    {jobDate}{' '}
                  </span>
                </div>
              </div>
              <a href={link} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="job-body body--s">
                {jobText}
                <div href={link} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="job-cta">
                  <IconArrowRight />
                </div>
              </a>
            </div>
          );
        })}
      </div>

      <Link className="section-cta" type="arrow" path="#ofertas-de-empleo">
        todas las ofertas de empleo de energía
      </Link>
    </div>
  );
};

export default TrabajaConNosotros;
