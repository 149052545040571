export default {
  meta: {
    title: 'Soluciones Story',
  },
  content: {
    modules: [
      {
        component: 'PruebaViewbox',
        props: {},
      },
    ],
  },
};
