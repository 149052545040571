import React, { useState } from 'react';

import './Styles.scss';

const TextArea = ({ label, initValue = '' }) => {
  const [value, setValue] = useState(initValue);

  return (
    <div className="text_area">
      <p className="form_section_title body--l">{label}</p>
      <textarea
        className={`${value.trim().length && 'not-empty'} body--m`}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      ></textarea>
    </div>
  );
};

export default TextArea;
