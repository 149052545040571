export default {
  meta: {
    title: 'Mapa Web',
	  templateClass: 'template-mapa-web template-ancho-completo'  
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          pageTitle: 'Mapa Web'
        }
      },		
      {
        component: 'mapaWeb',
        props: {
        }
      },
    ],
  }
};