export default {
  meta: {
    title: 'Projects Map Static',
  },
  content: {
    modules: [
      {
        component: 'ProjectsMapStatic',
        props: {
          projectsList: [
            {
              lat: -23.6948347,
              lng: 133.8793945,
            },
          ],
        },
      },
    ],
  },
};
