import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { useFade } from 'React/custom_hooks';

import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';

import './style.scss';

import { TagTitle, Link } from 'React/components';

const TrabajaConNosotrosSimple = ({ title, className, cta, frames }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);
  useFade(moduleRef);

  const refList = frames.map((frame) => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId)].current.classList.remove('is-hovered');
  };

  return (
    <div ref={moduleRef} className={`module grid jobs-simple ${className}`}>
      {title && (
        <div className="jobs-header slow--y">
          <TagTitle style="slow--y" title={title} />

          {cta && (
            <Link className="section-cta slow--y mobile-hidden" type="arrow" path="#ofertas-de-empleo">
              {cta}
            </Link>
          )}
        </div>
      )}
      <div className="jobs-container">
        {frames.map((frame, idx) => {
          const { link, sector, sectorLink, jobTitle, jobDate, jobPlace, jobText, icon, id } = frame;

          return (
            <div className="job-description slow--y grid" key={`data-point-${idx}`} ref={refList[idx]}>
              <div className="job-header slow--y">
                <a href={link} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                  <h2 className="title--s">{jobTitle}</h2>
                </a>
                <div className="job-subheader">
                  <span className="taglabel">
                    <a className="job-sector" title={sector} href={sectorLink}>
                      {icon && <IconEnergy />}
                      {sector}
                    </a>
                  </span>
                  <span className="taglabel">
                    <a href="#" className="job-sector">
                      {jobPlace}
                    </a>
                  </span>
                  <span className="taglabel">
                    <IconCalendar />
                    {jobDate}{' '}
                  </span>
                </div>
              </div>
              <a
                href={link}
                data-ref-id={idx}
                onMouseOut={notHover}
                onMouseOver={onHover}
                className="job-body body--m slow--y"
              >
                {jobText}
                <div
                  href={link}
                  data-ref-id={idx}
                  onMouseOut={notHover}
                  onMouseOver={onHover}
                  className="job-cta slow--y"
                >
                  <IconArrowRight />
                </div>
              </a>
            </div>
          );
        })}
      </div>
      {cta && (
        <Link className="section-cta mobile-visible slow--y" type="arrow" path="#ofertas-de-empleo">
          {cta}
        </Link>
      )}
    </div>
  );
};

export default TrabajaConNosotrosSimple;
