export default {
  meta: {
    title: 'Template equipo directivo',
    templateClass: 'template-equipo-directivo',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Home',
          secondLevel: 'Accionistas e inversores',
          thirdLevel: 'Gobierno corporativo',
          pageTitle: 'Equipo de dirección (46)',
        },
      },
      {
        component: 'EquipoDirectivoDestacado',
        props: {
          frames: [
            {
              status: 'Presidente ejecutivo de acciona',
              personName: 'Jose manuel entrecanales',
              icon: true,
              link: true,
              audio: {
                label: 'Escucha al presidente',
                src: 'http://hi5.1980s.fm/;',
              },
            },
            {
              status: 'vicePresidente ejecutivo de acciona',
              personName: 'juan ignacio entrecanales franco',
            },
          ],
        },
      },
      {
        component: 'Acordeon',
        props: {
          moduleCTA: {
            label: 'Enlace interno',
            path: '#enlace',
            type: 'internal',
          },
          equipodirectivo: true,
          items: [
            {
              title: 'Gabinete de Presidencia y Servicios Generales',
              subtitle: 'Macarena Carrión',
              disabled: true,
            },
            {
              title: 'Secretaría General',
              subtitle: 'Jorge Vega-Penichet',
              link: 'Conoce más sobre el presidente de Acciona',
              innercontent: true,
            },
            {
              title: 'Económico-Financiero',
              subtitle: 'José Ángel Tejero',
              icon: true,
              innercontent: true,
            },
            {
              title: 'Desarrollo Corporativo y Participadas',
              subtitle: 'Juan Muro-Lara',
              innercontent: true,
              icon: true,
            },
            {
              title: 'Relaciones Institucionales, Sostenibilidad y Marca',
              subtitle: 'Joaquín Mollinedo',
              innercontent: true,
            },
            {
              title: 'Tecnología e Innovación',
              subtitle: 'Arantza Ezpeleta',
              chevron: false,
            },
            {
              title: 'Organización, Talento y Salud',
              subtitle: 'Carlos Anta',
              innercontent: true,
              icon: true,
            },
            {
              title: 'Jurídico',
              subtitle: 'José Julio Figueroa',
              innercontent: true,
            },
            {
              title: 'Cumplimiento',
              subtitle: 'Vicente Santamaría',
              innercontent: true,
            },
            {
              title: 'Desarrollo de Negocio',
              subtitle: 'Fernando Fajardo',
              innercontent: true,
            },
          ],
        },
      },

      {
        component: 'EquipoDirectivo',
        props: {
          frames: [
            {
              title: 'Infraestructuras',
              subtitle: 'luís castilla',
              innercontent: true,
              link: true,
            },
            {
              title: 'Infraestructuras',
              subtitle: 'luís castilla',
              innercontent: true,
              link: true,
            },
          ],
        },
      },
      {
        component: 'Acordeon',
        props: {
          equipodirectivolower: true,
          legend: true,

          items: [
            {
              title: 'Inmobiliaria',
              subtitle: 'Andrés Pan de Soraluce',
              disabled: true,
            },
            {
              title: 'Bestinver',
              subtitle: 'Enrique Pérez-Pla',
              innercontent: true,
            },
            {
              title: 'Acciona Cultural Engineering',
              subtitle: 'José María López-Galiacho',
              innercontent: true,
            },
          ],
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {},
      },
    ],
  },
};
