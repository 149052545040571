export default {
  meta: {
    title: 'Template Home',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl:
          'https://source.unsplash.com/random/2560x1440?wind',
          title: 'Energía limpia para un sistema energético sostenible',
          body:
          'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
          cta: { type: 'link', target: '#some-cta' },
        },
      },
      {
        component: 'ModuloContenidoDev',
        props: {
        }
      },
    ]
  }
};
