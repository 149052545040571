export default {
  meta: {
    title: '[WRK] - Trabaja con nosotros',
  },
  content: {
    modules: [
      {
        component: 'TrabajaConNosotrosSimple',
        props: {
          title: 'Trabaja con nosotros',
          cta: 'Todas las ofertas de energía',
          frames: [
            { 
              link: '#',
              sector: 'Energía',
              icon: 'asfasf',
              sectorLink: '#energia',
              jobTitle: 'Beca Project Junior Engineer',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar soporte y ayuda técnica para la ejecución de las obras, bajo las directrices del Jefe de Producción, en los aspectos técnicos, de control o de calidad, con el fin de asegurar una correcta ejecución de la obra.'
            },
            { 
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Gestión Contractual',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Conocimiento de los diferentes aspectos jurídico-legales, de acuerdo con la normativa vigente, para defender los intereses de la división de Infraestructuras de ACCIONA y el cumplimiento de sus obligaciones jurídicas.'
            },   
            { 
              link: '#',
              sector: 'Energía',
              sectorLink: '#energia',
              jobTitle: 'Beca de Administración-Costes',
              jobDate: '29 junio 2017',
              jobPlace: 'Madrid',
              jobText: 'Dar apoyo a los procesos de Administración y costes de los proyectos actualmente en ejecución.'
            },                     
          ]
        }
      },
    ]
  }
};
