export default {
  meta: {
    title: 'Breadcrumbs',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          secondLevel: 'Servicios',
          pageTitle: 'Título de página'
        }
      }
    ]
  }
};
