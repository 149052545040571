export default {
  meta: {
    title: 'Template Histórico informes anuales integrados',
    templateClass: 'template-ancho-completo-imagen',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'https://source.unsplash.com/random/2560x1440?wind',
          title: 'Energía limpia para un sistema energético sostenible',
          body:
            'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
          cta: { type: 'link', target: '#some-cta' },
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          backLink: 'Volver',
          firstLevel: 'Home',
          secondLevel: 'Accionistas e Inversores',
          thirdLevel: 'Información financiera',
          fourthLevel: 'Informe anual integrado',
          pageTitle: 'Histórico de informes anuales integrados',
        },
      },
      {
        component: 'DistribuidorMultiple',
        props: {
          frames: [
            {
              moduleLink: 'Descubre nuestro propósito',
              desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              title: 'Premios Expansión Compilance 2018',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              link: 'Descubre nuestro propósito',
              download: 'Descárgate toda la información',
            },
          ],
        },
      },
      {
        component: 'DistribuidorMultiple',
        props: {
          frames: [
            {
              moduleLink: 'Descubre nuestro propósito',
              desktopImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              mobileImage: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg',
              title: 'Premios Expansión Compilance 2018',
              body:
                'ACCIONA ha recibido el galardón a la Innovación en materia de cumplimiento (compliance) en los Premios Expansión Compliance 2018 por haber desarrollado la primera plataforma basada en la tecnología Blockchain para reforzar el procedimiento interno de identificación y validación de consultores comerciale	s aportando trazabilidad y seguridad.',
              link: 'Descubre nuestro propósito',
              download: 'Descárgate toda la información',
            },
          ],
        },
      },
      {
        component: 'Paginator',
        props: {},
      },
    ],
  },
};
