import window from 'global';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import uiContext from 'Data/_uiContext';

import './default.scss';

class baseLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canScroll: true,
      toggleScroll: this.toggleScroll.bind(this),
      deviceScreenSize: 'small',
      windowWidth: null,
      visibleLinksListBox: null,
      setVisibleLinksListBox: this.setVisibleLinksListBox.bind(this),
    };

    this.updateDeviceScreenSize = this.updateDeviceScreenSize.bind(this);
  }
  toggleScroll(newValue) {
    if (!newValue) {
      this.setState((prevState) => ({
        canScroll: !prevState.canScroll,
      }));
    } else {
      this.setState({ canScroll: newValue });
    }
  }

  componentDidMount() {
    this.updateDeviceScreenSize();
    window.addEventListener('resize', this.updateDeviceScreenSize);
    this.detectTouchSupport();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDeviceScreenSize);
  }

  setVisibleLinksListBox(newId) {
    this.setState({
      visibleLinksListBox: newId,
    });
  }

  updateDeviceScreenSize() {
    this.setState({
      windowWidth: window.innerWidth,
    });

    if (window.innerWidth <= 1024) {
      this.setState({
        deviceScreenSize: 'small',
      });
    } else {
      this.setState({
        deviceScreenSize: 'large',
      });
    }
  }

  detectTouchSupport() {
    if (!('outouchstart' in document.documentElement)) {
      document.querySelector('.app--layout').classList.add('no-touch');
    } else {
      document.querySelector('.app--layout').classList.remove('no-touch');
    }
  }

  render() {
    const { pageMetaData, children } = this.props;
    const { title, templateClass } = pageMetaData;
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <uiContext.Provider value={this.state}>
          <div className={`app--layout layout--base ${templateClass}`} data-can-scroll={this.state.canScroll}>
            <main id="main">{children}</main>
          </div>
        </uiContext.Provider>
      </>
    );
  }
}

baseLayout.propTypes = {
  pageMetaData: PropTypes.object.isRequired,
};

export default baseLayout;
