import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useObserver } from 'React/custom_hooks';
import { TimelineMax, TweenMax } from 'gsap/all';

import { PropTypes as pt } from 'prop-types';

import { Link, InputRangeSlider } from 'React/components';

import './styles.scss';

const Timelime = ({ framesList }) => {
  const [activeFrame, setActiveFrame] = useState(1);
  const swipableComponent = React.createRef();
  const framesContainer = React.createRef();
  const sliderControl = React.createRef();
  const sliderTack = React.createRef();

  const refList = framesList.map(() => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId - 1)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId) - 1].current.classList.remove('is-hovered');
  };

  useSliderSwipe(swipableComponent, setActiveFrame, framesList.length, 680);

  useEffect(() => {
    const track = sliderControl.current;
    const tack = sliderTack.current;
    const trackLength = track.getBoundingClientRect().height;

    tack.style.top = (trackLength / framesList.length) * (activeFrame - 1) + 'px';
  }, [activeFrame]);

  useEffect(() => {
    sliderControl.current.addEventListener('click', handleSliderControlClick);
    return () => {
      sliderControl.current.removeEventListener('click', handleSliderControlClick);
    };
  }, []);

  const handleSliderControlClick = (event) => {
    // here we are simulating the input slider behaviour to be able to use it in the vertical position
    event.preventDefault();
    const sliderTop = event.target.getBoundingClientRect().top;
    const sliderRange = event.target.getBoundingClientRect().height;
    const rangePosition = event.clientY - sliderTop;
    const frameSectionLength = sliderRange / framesList.length;
    const targetFrame = parseInt(rangePosition / frameSectionLength + 1);
    setActiveFrame(targetFrame);
  };

  const [observer, setElements, entries] = useObserver({
    root: null,
    threshold: 0.25,
  });

  const tl = new TimelineMax({ delay: 0, repeat: 0 });

  useEffect(() => {
    // setting elements to observe
    const elements = swipableComponent.current.querySelectorAll('.slow--y');
    TweenMax.set(elements, { opacity: 0, y: 90 });
    setElements(elements);
  }, [setElements]);

  useEffect(() => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        let lazyItem = entry.target;
        tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
        observer.unobserve(lazyItem);
      }
    });
  }, [entries, observer, TimelineMax]);

  return (
    <div data-active-frame={activeFrame} ref={swipableComponent} className="module grid timeline">
      <div className="mobile-switch-buttons">
        {framesList.map((frame, idx) => {
          const { date } = frame;

          return (
            <button onClick={() => setActiveFrame(idx + 1)} data-frame-id={idx + 1} key={`mobile-button-${idx}`}>
              {date}
            </button>
          );
        })}
      </div>

      <div className="frames-container" ref={framesContainer} style={{ width: `${framesList.length * 100}vw` }}>
        <div className="vertical-desktop-slider slow--y" ref={sliderControl}>
          <div className="slider-tack" ref={sliderTack}></div>
        </div>
        {framesList.map((frame, idx) => {
          const { date, title, body, image, cta } = frame;

          return (
            <div className="frame slow--y" data-frame-id={idx + 1} key={`data-point-${idx}`} ref={refList[idx]}>
              <div className="frame-year-label-wrapper grid slow--y">
                <button className="year-label" onClick={() => setActiveFrame(idx + 1)}>
                  {date}
                </button>
              </div>
              <div className="frame-content grid">
                <h3 className="title title--m" data-ref-id={idx + 1} onMouseOut={notHover} onMouseOver={onHover}>
                  <a href="">{title}</a>
                </h3>

                <p className="body-one body--m" data-with-image={body[1] ? false : true}>
                  {body[0]}
                </p>

                {body[1] && <p className="body-two body--m">{body[1]}</p>}

                {cta && <Link className="cta" path={cta} type="arrow" />}

                {image && (
                  <div className="image-container">
                    <img className="body-image" src={image.src} alt={image.alt} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {typeof window != 'undefined' && window.windowWidth < 680 && (
        <InputRangeSlider
          framesCount={framesList.length}
          activeFrame={parseInt(activeFrame)}
          changeHandler={setActiveFrame}
          noIndicator={true}
          className="mobile-slider"
        />
      )}
    </div>
  );
};

Timelime.propTypes = {
  framesList: pt.arrayOf(
    pt.shape({
      date: pt.number.isRequired,
      title: pt.string.isRequired,
      body: pt.arrayOf(pt.string).isRequired,
      image: pt.shape({
        src: pt.string.isRequired,
        alt: pt.string.isRequired,
      }),
      cta: pt.string,
    })
  ).isRequired,
};

export default Timelime;
