import React from 'react';
import './styles.scss';

import { ReactComponent as IconLeft } from 'Icons/paginatorLeft.svg';
import { ReactComponent as IconRight } from 'Icons/paginatorRight.svg';

const Paginator = () => {
  return (
    <div className="module grid module-paginator">
      <nav className="slow">
        <button className="button--left">
          <IconLeft />
        </button>
        <a href="#">01</a>
        <a href="#" className="is-active">
          02
        </a>
        <a href="#">03</a>
        <a href="#">04</a>
        <a href="#">05</a>
        <a href="#" className="not-clickable">
          ...
        </a>
        <a href="#">16</a>
        <button className="button--right">
          <IconRight />
        </button>
      </nav>
    </div>
  );
};

export default Paginator;
