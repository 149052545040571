import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { rndKeyGen } from 'React/helpers';
import { Link, Lightbox, Wysiwyg } from 'React/components';
import './styles.scss';

const DevelopmentGoals = ({ goals }) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);
  const [visibleLightbox, setVisibleLightbox] = useState(null);

  const goalsImagesUrlPrefix =
    'http://accionacorporativa-front.redbilityservers.com/static/development_goals_images/Goal_';

  const goalsArray = goals.reduce((acc, el, idx) => {
    acc[el.number] = el;
    return acc;
  }, []);

  const goalsWithLightbox = goalsArray.filter((goal) => goal.cta_type === 'lightbox');

  const handleGoalClick = (number) => {
    const clickedGoal = goalsArray[number];
    if (clickedGoal.cta_type === 'lightbox') {
      setVisibleLightbox(number);
    }
  };

  return (
    <>
      <div ref={moduleRef} className="module grid development-goals">
        <h2 className="title title--l slow--y">Nuestros objetivos</h2>
        <p className="description body--m slow--y">
          Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los
          estudiantes del área.
        </p>
        <div className="goals-buttons slow--y">
          {Array.from(new Array(18), (goal, idx) => {
            const currentGoal = goalsArray[idx];

            // external link
            if (currentGoal && currentGoal.cta_type === 'external') {
              return (
                <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                  <a href={currentGoal.content} target="_blank">
                    <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                  </a>
                </div>
              );
            }
            // internal link
            if (currentGoal && currentGoal.cta_type === 'internal') {
              return (
                <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                  <Link path={currentGoal.content} target="_blank">
                    <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                  </Link>
                </div>
              );
            }
            // lightbox
            return (
              <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                <img
                  data-is-active={currentGoal !== undefined}
                  onClick={() => handleGoalClick(idx)}
                  src={goalsImagesUrlPrefix + idx + '.png'}
                />
              </div>
            );
          })}
        </div>
      </div>
      {goalsWithLightbox.map((goal, idx) => {
        return (
          <Lightbox
            key={rndKeyGen()}
            isVisible={visibleLightbox === goal.number}
            closeMethod={() => setVisibleLightbox(null)}
          >
            <Wysiwyg html={goal.content} />
          </Lightbox>
        );
      })}
    </>
  );
};

export default DevelopmentGoals;
