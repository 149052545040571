export default {
  meta: {
    title: 'RSS feed',
  },
  content: {
    modules: [
      {
        component: 'RSSfeed',
        props: {
          feed: [
            {
              handle: 'Acciona',
              textContent: 'ET @robertocn100: Video 360º desde lo alto de un aerogenerador!!!',
              link: 'https://t.co/hGAYKAHhgs',
            },
            {
              handle: 'Acciona',
              textContent: 'ET @robertocn100: Video 360º desde lo alto de un aerogenerador!!!',
              link: 'https://t.co/hGAYKAHhgs',
            },
            {
              handle: 'Acciona',
              textContent: 'ET @robertocn100: Video 360º desde lo alto de un aerogenerador!!!',
              link: 'https://t.co/hGAYKAHhgs',
            },
          ],
        },
      },
    ],
  },
};
