export default {
  meta: {
    title: 'Sobre Acciona',
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          frames: [
            {
              image: 'http://accionacorporativa-front.redbilityservers.com/static/images/imagen_cabecera.svg',
              title: 'Single-frame example',
              body:
                'Creates a Manager instance with a default set of recognizers and returns the manager instance. The default set contains tap, doubletap, pan, swipe, press, pinch and rotate recognizer instances. You should only use this when you’re fine with the default setup, or have set your own initial setup.',
              cta: { type: 'link', target: '#slide-cta-5' },
            },
          ],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          tagLine: 'Texto con entradilla y dos párrafos',
          title: 'wrk',
          leadText: 'leading text',
          mainText: ['p1', 'p2'],
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          tagLine: 'Texto 2 columnas',
          title: 'wrk',
          leadText: 'leading text',
          mainText: ['p1'],
          list: ['item 1', 'item 2', 'item 3', 'item 4', 'item 5', 'item 6', 'item 7', 'item 8', 'item 9', 'item 10'],
        },
      },
    ],
  },
};
