export default {
  meta: {
    title: 'Module - Timeline',
  },
  content: {
    modules: [
      {
        component: 'Timeline',
        props: {
          framesList: [
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'titulo de ejemplo - 2',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
          ],
        },
      },
    ],
  },
};
