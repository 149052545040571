import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useFade } from 'React/custom_hooks';

import './style.scss';

import { TagTitle, Link } from 'React/components';
import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';

const AreasActividad = ({ title, module_cta, frames }) => {
  const [activeFrame, setActiveFrame] = useState(1);

  const imagesContainerWidth = { width: frames.length * 100 + 'vw' };
  const headerSliderComponent = React.createRef();
  const prevButton = React.createRef();
  const nextButton = React.createRef();

  // hovering on title triggers link icon effect
  const refList = frames.map(() => React.createRef());

  const onHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId - 1)].current.classList.add('is-hovered');
  };

  const notHover = (event) => {
    const { refId } = event.currentTarget.dataset;
    refList[parseInt(refId) - 1].current.classList.remove('is-hovered');
  };

  const nextHover = () => {
    nextButton.current.classList.add('is-animated');
    prevButton.current.classList.remove('is-animated');
  };

  const prevHover = () => {
    prevButton.current.classList.add('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const removeHover = () => {
    prevButton.current.classList.remove('is-animated');
    nextButton.current.classList.remove('is-animated');
  };

  const nextFrame = () => {
    if (activeFrame < frames.length) {
      setActiveFrame(activeFrame + 1);
    }
  };

  const prevFrame = () => {
    if (activeFrame > 1) {
      setActiveFrame(activeFrame - 1);
    }
  };

  useSliderSwipe(headerSliderComponent, setActiveFrame, frames.length);
  useFade(headerSliderComponent);

  return (
    <div className="module grid areas-actividad slow" data-active-frame={activeFrame} ref={headerSliderComponent}>
      <TagTitle style="slow" title={title} />
      {frames.length > 1 && (
        <>
          <button
            className="ghost-frame-button ghost-frame-button--left"
            onMouseOver={prevHover}
            onMouseOut={removeHover}
            onClick={prevFrame}
          >
            Slider anterior
          </button>
          <button
            className="ghost-frame-button ghost-frame-button--right"
            onMouseOver={nextHover}
            onMouseOut={removeHover}
            onClick={nextFrame}
          >
            Slider siguiente
          </button>
        </>
      )}
      <div className="image-frames-container">
        <div className="frames-container" style={imagesContainerWidth}>
          {frames.map(({ image, imageReplace }, idx) => {
            const imageUrl = useWindowWidth() >= 767 ? image : imageReplace;

            return (
              <div className="image-frame data-point" data-frame-id={idx + 1} key={`frame-${idx}`}>
                <div className="img-container" style={{ backgroundImage: `url(${imageUrl})` }}></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="slider-controls">
        <button ref={prevButton} className="prev-button" onClick={prevFrame}>
          <IconArrowRight />
        </button>
        <button ref={nextButton} className="next-button" onClick={nextFrame}>
          <IconArrowRight />
        </button>
      </div>
      <div className="frames-container text-frames-container slow">
        {frames.map((frame, idx) => {
          const { title, body, link } = frame;

          return (
            <div className="text-frame" ref={refList[idx]} data-frame-id={idx + 1} key={`frame-${idx}`}>
              <a href={link} data-ref-id={idx + 1} onMouseOut={notHover} onMouseOver={onHover}>
                <h3 className="title title--m">{title}</h3>
              </a>
              <p className="body body--m">{body}</p>
              <Link type="arrow" className="frame-cta" path={module_cta.path}>
                Conoce más sobre hidroeléctrica
              </Link>
            </div>
          );
        })}
      </div>

      <Link type="arrow" className="module_cta slow" path={module_cta.path}>
        {module_cta.label}
      </Link>
    </div>
  );
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
};

export default AreasActividad;
