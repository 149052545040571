export default {
  meta: {
    title: '[WRK] - Social Share',
  },
  content: {
    modules: [
      {
        component: 'ShareSocial',
        props: {
            moduleTitle: 'Compartir en redes sociales',
        }
      },
    ]
  }
};
