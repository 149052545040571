export default {
  meta: {
    title: 'Portada Soluciones',
    templateClass: 'template-soluciones template-ancho-completo-imagen',
    stickyHeader: false
  },
  content: {
    modules: [
      {
        component: 'HeroImage',
        props: {
          imageUrl: 'https://source.unsplash.com/random/2560x1440?wind',
          title:
            'Trabajamos para dar respuesta a los grandes desafíos del planteta',
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          pageTitle: 'Soluciones',
        },
      },
      {
        component: 'SolucionesBotonera',
        props: {
          title: 'Descubre nuestras soluciones',
          body:
            'Somos expertos en diseñar un planeta mejor. Ofrecemos soluciones para dar respuesta a los grandes desafíos globales a los que se enfrenta la humanidad y que marcarán el futuro del planeta.',
          cta: 'Accede a todas las soluciones',
        },
      },
      {
        component: 'SolucionesFixed',
        props: {
        },
      },
      {
        component: 'SolucionesFixed',
        props: {
        },
      },
      {
        component: 'Proyectos',
        props: {
          isProject: false,
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',

              content: {
                cifra: '432.000',
                unit: 'M³',
                caption: 'capacidad',
              },
              project: {
                titleBottom: true,
                name: 'Energía',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',

              content: {
                cifra: '432.000',
                unit: 'M³',
                caption: 'capacidad',
              },
              project: {
                titleBottom: true,
                name: 'Energía',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                },
              },
            },
          ],
        },
      },
      {
        component: 'Proyectos',
        props: {
          frames: [
            {
              desktopImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_XL.jpg',
              mobileImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_soluciones_M.jpg',

              content: {
                cifra: '432.000',
                unit: 'M³',
                caption: 'capacidad',
              },
              project: {
                titleBottom: true,
                name: 'Energía',
                nameIcon: true,
                link: {
                  path: '#project_3',
                  type: 'link internal',
                },
              },
            },
          ],
        },
      },
      {
        component: 'DistribuidorDestacadoSlider',
        props: {
          frames: [
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
            {
              title: 'Business as unusual',
              text:
                'La prevención de riesgos laborales constituye para ACCIONA un elemento diferenciador y un requerimiento indispensable para garantizar la seguridad y salud de empleados y colaboradores.',
              image:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_contenido_L.jpg',
              label: 'Descubre más sobre seguridad y salud',
              href: '#',
              tagtitle: 'Nuestro objetivo',
            },
          ],
        },
      },
    ],
  },
};
