import React, { useState, useEffect } from 'react';

function useStickyHeader(headerRef, stickyHeader) {
  // STICKY HEADER ON SCROLL UP
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState();

  useEffect(() => {
    const scrollFrame = document.querySelector('.app--layout');

    const handleScroll = (event) => {
      const scrollFramePosition = event.currentTarget.scrollTop;
      setScrollPosition((oldState) => {
        if (oldState > scrollFramePosition) {
          setScrollDirection('up');
        } else {
          setScrollDirection('down');
        }
        return scrollFramePosition;
      });
    };
    // stickyHeader value is set in the page model metadata
    if (stickyHeader) {
      scrollFrame.addEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const header = headerRef.current;

    if (scrollPosition > 250) {
      header.classList.add('invisible');
    } else {
      header.classList.remove('invisible');
    }

    if (scrollDirection === 'up') {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }, [scrollPosition]);
}

export default useStickyHeader;
