// button.component.js
import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import './Table.scss';

import { Link, TagTitle } from 'React/components';

const Table = ({
  text,
  bullets,
  headerDownloads,
  singleDownload,
  tagtitle,
  internalLink,
  tableType,
  className = '',
  total,
  logo,
  rows,
  headers,
}) => {
  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  const tableHeaders = headers.map((header, idx) => (
    <th className="taglabel" key={`header-${idx}`}>
      {header}
    </th>
  ));

  if (tableType === 'double') {
    return (
      <div ref={moduleRef} className={`module grid module-table table-double ${className}`}>
        <div className="grid-container">
          {tagtitle && <TagTitle style="slow--y" title="informes trimestrales de 2019" />}
          <div className="table-wrapper">
            <div className="table-container slow--y">
              <table>
                <thead>
                  <tr>
                    <th className="taglabel">Precios</th>
                    <th className="taglabel">Última cotización</th>
                    <th className="taglabel">Diferencia</th>
                    <th className="taglabel">Diferencia %</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="body--s">0,8500</td>
                    <td className="body--s">95,1500</td>
                    <td className="body--s">95,1500</td>
                    <td className="body--s">95,1500</td>
                  </tr>
                  <tr>
                    <td className="body--s">0,8500</td>
                    <td className="body--s">95,1500</td>
                    <td className="body--s">95,1500</td>
                    <td className="body--s">95,1500</td>
                  </tr>
                </tbody>
                {total && (
                  <tfoot>
                    <tr>
                      <td className="body--l">Total</td>
                      <td className="body--l">1811,90</td>
                      <td className="body--l">1811,90</td>
                      <td className="body--l">1811,90</td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
            <div className="table-container no-margin slow--y">
              <table>
                <thead>
                  <tr>
                    <th className="taglabel">Volúmenes</th>
                    <th className="taglabel">Diferencia%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="body--s">0,8500</td>
                    <td className="body--s">95,1500</td>
                  </tr>
                  <tr>
                    <td className="body--s">0,8500</td>
                    <td className="body--s">95,1500</td>
                  </tr>
                </tbody>
                {total && (
                  <tfoot>
                    <tr>
                      <td className="body--l">Total</td>
                      <td className="body--l">1811,90</td>
                      <td className="body--l">1811,90</td>
                      <td className="body--l">1811,90</td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
          <div className="mt-4 slow--y">{internalLink && <Link type="arrow" label="Enlace interno" />}</div>
        </div>
      </div>
    );
  }
  if (tableType === 'cargos') {
    return (
      <div ref={moduleRef} className={`module grid module-table ${className}`}>
        <div className="grid-container">
          {tagtitle && <TagTitle style="slow--y" title="informes trimestrales de 2019" />}
          <div className="table-container slow--y">
            <table>
              <thead>
                <tr>
                  <th className="taglabel">Consejero y cargo</th>
                  <th className="taglabel">Primer nombramiento</th>
                  <th className="taglabel">Último nombramiento</th>
                  <th className="taglabel">Condición</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="body--s">
                    <Link type="arrow" className="cargo" label="D. José Manuel Entrecanales Domenec" />
                    <div>
                      <span className="body--s">Presidente</span>
                    </div>
                  </td>
                  <td className="body--s">01/07/2019</td>
                  <td className="body--s">01/07/2019</td>
                  <td className="body--s">
                    <Link type="lightbox" label="Ejecutivo" />
                    <p className="body--s">Consejero independiente, cordinador</p>
                  </td>
                </tr>
                <tr>
                  <td className="body--s">
                    <Link type="arrow" className="cargo" label="D. José Manuel Entrecanales Domenec" />
                    <div>
                      <span className="body--s">Presidente</span>
                    </div>
                  </td>
                  <td className="body--s">01/07/2019</td>
                  <td className="body--s">01/07/2019</td>
                  <td className="body--s">
                    <Link type="lightbox" label="Ejecutivo" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4 logo-container slow--y">
            {internalLink && <Link type="arrow" label="Enlace interno" />}
            {singleDownload && <Link type="download" label="Resultados de 1er semestre 2019" />}
            {logo && (
              <div className="logo">
                <img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div ref={moduleRef} className={`module grid module-table ${className}`}>
        <div className="grid-container">
          {tagtitle && <TagTitle style="slow--y" title="informes trimestrales de 2019" />}
          {headerDownloads && (
            <div className="header-downloads slow--y">
              <Link type="download" label="Resultados 1er trimestre" />
              <Link type="download" label="Resultados 1er trimestre" />
            </div>
          )}
          <div className="table-container slow--y">
            <table>
              <thead>
                <tr>{tableHeaders}</tr>
              </thead>
              <tbody>
                {rows.map(({ cells, cellType }, idx) => {
                  return (
                    <tr key={`row-${idx}`}>
                      {cells.map(({ className = '', cell, download }, idx) => {
                        return (
                          <td className={`body--s ${className}`} key={`cell-${idx}`}>
                            {cell && cell}
                            {download && <Link type="download" label={download} />}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {total && (
                <tfoot>
                  <tr>
                    <td className="body--l">Total</td>
                    <td className="body--l">1811,90</td>
                    <td className="body--l">1811,90</td>
                    <td className="body--l">1811,90</td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
          {text && (
            <div className="text-container slow--y">
              <p className="body--s">Última actualización: 06/08/2019 - 16:58 CET</p>
            </div>
          )}
          {bullets && (
            <div className="bullets-container">
              <h2 className="body--l slow--y">Título descriptivo</h2>
              <ul className="list">
                <li className="body--s slow--y">
                  Serie 10: ACCIONA FINANCIACIÓN FILIALES, S.A., 25/11/2016 EUR 22.000.000 4,25% (Green Notes) vto.
                  noviembre 2031 Código ISIN: XS1525867104
                </li>
                <li className="body--s slow--y">
                  Serie 10: ACCIONA FINANCIACIÓN FILIALES, S.A., 25/11/2016 EUR 22.000.000 4,25% (Green Notes) vto.
                  noviembre 2031 Código ISIN: XS1525867104
                </li>
                <li className="body--s slow--y">
                  Serie 10: ACCIONA FINANCIACIÓN FILIALES, S.A., 25/11/2016 EUR 22.000.000 4,25% (Green Notes) vto.
                  noviembre 2031 Código ISIN: XS1525867104
                </li>
                <li className="body--s slow--y">
                  Serie 10: ACCIONA FINANCIACIÓN FILIALES, S.A., 25/11/2016 EUR 22.000.000 4,25% (Green Notes) vto.
                  noviembre 2031 Código ISIN: XS1525867104
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Table;
