import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';

const HeaderLanding = ({ stickyHeader, logo }) => {
  const headerRef = useRef();

  // STICKY HEADER ON SCROLL UP
  useStickyHeader(headerRef, stickyHeader);

  return (
    <>
      {!logo && (
        <header className="header-landing" ref={headerRef} id="header">
          <Link path="/" className="logo-home-link">
            <img className="logo" src={LogoFull} alt="Logo Acciona" />
          </Link>
        </header>
      )}
      {logo && (
        <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
          <Link path="/" className="logo-home-link">
            <img className="logo" src={LogoFull} alt="Logo Acciona" />
          </Link>
          <Link path="/" className="logo-home-right">
            <img
              className="logo"
              src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_logos_M.jpg"
              alt="Logo One"
            />
          </Link>
        </header>
      )}
    </>
  );
};

export default HeaderLanding;
