import React, { useEffect, useRef } from 'react';

import { ReactComponent as IconEnergy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as IconCiudades } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as IconMovilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as IconAgua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as IconSocial } from 'Icons/soluciones/social.svg';
import { ReactComponent as IconCapital } from 'Icons/soluciones/capital.svg';
import { ReactComponent as IconInmo } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const SolucionesMosaico = ({ energia, transporte, agua, social, ciudades, inmobiliaria, capital }) => {
  let moduleRef = React.createRef();
  let energiaRef = useRef();
  let transporteRef = useRef();
  let aguaRef = useRef();
  let socialRef = useRef();
  let ciudadesRef = useRef();
  let inmobiliariaRef = useRef();
  let capitalRef = useRef();

  useEffect(() => {
    let imgEnergia = new Image();
    let imgTransporte = new Image();
    let imgCiudades = new Image();
    let imgSocial = new Image();
    let imgCapital = new Image();
    let imgInmobiliaria = new Image();
    let imgAgua = new Image();

    imgEnergia.src = `${energia}`;
    imgTransporte.src = `${transporte}`;
    imgSocial.src = `${social}`;
    imgCiudades.src = `${ciudades}`;
    imgCapital.src = `${capital}`;
    imgInmobiliaria.src = `${inmobiliaria}`;
    imgAgua.src = `${agua}`;

    // controlamos con esto que la imagen esté cargada y entonces la desvelamos
    imgEnergia.onload = function() {
      energiaRef.classList.add('reveal');
    };

    imgTransporte.onload = function() {
      transporteRef.classList.add('reveal');
    };

    imgSocial.onload = function() {
      socialRef.classList.add('reveal');
    };

    imgCiudades.onload = function() {
      ciudadesRef.classList.add('reveal');
    };

    imgCapital.onload = function() {
      capitalRef.classList.add('reveal');
    };

    imgInmobiliaria.onload = function() {
      inmobiliariaRef.classList.add('reveal');
    };

    imgAgua.onload = function() {
      aguaRef.classList.add('reveal');
    };

    if (imgEnergia.complete) imgEnergia.onload();
    if (imgTransporte.complete) imgTransporte.onload();
    if (imgAgua.complete) imgAgua.onload();
    if (imgSocial.complete) imgSocial.onload();
    if (imgCiudades.complete) imgCiudades.onload();
    if (imgInmobiliaria.complete) imgInmobiliaria.onload();
    if (imgCapital.complete) imgCapital.onload();
  });

  return (
    <div ref={moduleRef} className="module grid module-soluciones-mosaico">
      <div className="grid-container">
        <div className="mosaico-row">
          <div className="mosaico-cell mosaico-cell--1">
            <a
              ref={(el) => (energiaRef = el)}
              href="#"
              className="mosaico-item item-1"
              style={{ backgroundImage: `url(${energia})` }}
            >
              <div className="content">
                <div className="content-inner">
                  <h2 className="title--xs">
                    <IconEnergy />
                    Energía
                  </h2>
                </div>
              </div>
              <div className="hover-content">
                <h2 className="cifras--m">
                  23.000
                  <span className="title--xs">instalados</span>
                </h2>
                <h3 className="title--xs">
                  <IconEnergy />
                  Energía
                </h3>
              </div>
            </a>
            <div className="mosaico-cell--half">
              <a
                ref={(el) => (transporteRef = el)}
                href="#"
                className="mosaico-item item-2"
                style={{ backgroundImage: `url(${transporte})` }}
              >
                <div className="content">
                  <div className="content-inner">
                    <h2 className="title--xs">
                      <IconMovilidad />
                      Transporte
                    </h2>
                  </div>
                </div>
                <div className="hover-content">
                  <h2 className="cifras--s">
                    12.000
                    <span className="title--xs">puentes construidos</span>
                  </h2>
                  <h3 className="title--xs">
                    <IconMovilidad />
                    Transporte
                  </h3>
                </div>
              </a>
              <a
                ref={(el) => (aguaRef = el)}
                href="#"
                className="mosaico-item item-3"
                style={{ backgroundImage: `url(${agua})` }}
              >
                <div className="content">
                  <div className="content-inner">
                    <h2 className="title--xs">
                      <IconAgua />
                      Agua
                    </h2>
                  </div>
                </div>
                <div className="hover-content">
                  <h2 className="title--s">Nuestro impacto positivo en el planeta</h2>
                  <h3 className="title--xs">
                    <IconAgua />
                    Agua
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <a
            href="#"
            className="mosaico-cell--2 mosaico-item mosaico-cell item-4"
            ref={(el) => (socialRef = el)}
            style={{ backgroundImage: `url(${social})` }}
          >
            <div className="content">
              <div className="content-inner">
                <h2 className="title--xs">
                  <IconSocial />
                  Social
                </h2>
              </div>
            </div>
            <div className="hover-content">
              <h2 className="title--s">Nuestro impacto positivo en el planeta</h2>
              <h3 className="title--xs">
                <IconSocial />
                Social
              </h3>
            </div>
          </a>
        </div>
      </div>
      <div className="grid-container">
        <div className="mosaico-row">
          <a
            ref={(el) => (ciudadesRef = el)}
            href="#"
            className="mosaico-cell-3 mosaico-item item-5"
            style={{ backgroundImage: `url(${ciudades})` }}
          >
            <div className="content">
              <div className="content-inner">
                <h2 className="title--xs">
                  <IconCiudades />
                  Ciudades
                </h2>
              </div>
            </div>
            <div className="hover-content">
              <h2 className="title--s">Nuestro impacto positivo en el planeta</h2>
              <h3 className="title--xs">
                <IconCiudades />
                Ciudades
              </h3>
            </div>
          </a>
          <a
            href="#"
            className="mosaico-cell-4 mosaico-item item-6"
            ref={(el) => (inmobiliariaRef = el)}
            style={{ backgroundImage: `url(${inmobiliaria})` }}
          >
            <div className="content">
              <div className="content-inner">
                <h2 className="title--xs">
                  <IconInmo />
                  Inmobiliaria
                </h2>
              </div>
            </div>
            <div className="hover-content">
              <h2 className="title--s">Nuestro impacto positivo en el planeta</h2>
              <h3 className="title--xs">
                <IconInmo />
                Inmobiliaria
              </h3>
            </div>
          </a>
          <a
            ref={(el) => (capitalRef = el)}
            href="#"
            className="mosaico-cell-5 mosaico-item item-7"
            style={{ backgroundImage: `url(${capital})` }}
          >
            <div className="content">
              <div className="content-inner">
                <h2 className="title--xs">
                  <IconCapital />
                  Capital
                </h2>
              </div>
            </div>
            <div className="hover-content">
              <h2 className="title--s">Nuestro impacto positivo en el planeta</h2>
              <h3 className="title--xs">
                <IconCapital />
                Capital
              </h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SolucionesMosaico;
