export default {
  meta: {
    title: 'Historia',
    templateClass: 'template-historia template-ancho-completo-imagen',
  },
  content: {
    modules: [
      {
        component: 'HeaderSlider',
        props: {
          frames: [
            {
              video: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/video_sample.mp4',
              },
              previewImage:
                'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_cabecera_XL.jpg',
              title: 'Energía limpia para un sistema energético sostenible',
              body:
                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
              cta: { type: 'play' },
            },
          ],
        },
      },
      {
        component: 'Breadcrumbs',
        props: {
          firstLevel: 'Inicio',
          secondLevel: 'Sobre Acciona',
          pageTitle: 'Historia',
        },
      },
      {
        component: 'BloqueTexto',
        props: {
          title: 'Antecedentes',
          leadText:
            'Se funda MZOV (Compañía de los Ferrocarriles de Medina del Campo a Zamora y de Orense a Vigo) en Galicia.',
          mainTextColumnLeft:
            'En 1861, fue concedida la línea de Medina del Campo a Zamora, consiguiendo inmediatamente la concesión entre otros del tramo Orense a Vigo y del esperado ferrocarril que uniera Galicia con la Meseta y particularmente con la capital, Madrid. Posteriormente, la Cia. M.Z.O.V. evolucionó hasta convertirse en una de las principales constructoras españolas.',
          mainTextColumnRight:
            'En el año 1916 se funda CUBIERTAS Y TEJADOS, S.A., cuyo nombre reflejaba su prestigio en esa especialidad, aunque ya realizaba otras construcciones industriales. 50 años después se fusionará con MZOV. A finales del siglo pasado CUBIERTAS Y MZOV, S.A. acabarían fusionándose con ENTRECANALES Y TÁVORA, S.A. para crear ACCIONA.',
        },
      },
      {
        component: 'Timeline',
        props: {
          framesList: [
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'titulo de ejemplo - 2',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
            {
              date: 1997,
              title: 'Nuestros primeros pasos',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path',
            },
            {
              date: 2000,
              title: 'titulo de ejemplo - 2',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              image: {
                src: 'http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_timeline_L.jpg',
                alt: 'timeline-related image',
              },
              cta: '#sample_path_2',
            },
            {
              date: 2003,
              title: 'titulo de ejemplo - 3',
              body: [
                'Se empieza a construir el proyecto en el 2000. Participamos como compañía líder en el desarrollo, ingeniería, construcción, operación y mantenimiento de activos renovables.',
              ],
              cta: '#sample_path_3',
            },
          ],
        },
      },
    ],
  },
};
