export default {
  meta: {
    title: 'Template detalle noticia',
    templateClass: 'template-detalle-noticia template-ancho-completo',
  },
  content: {
    modules: [
      {
        component: 'Breadcrumbs',
        props: {
          type: 'detalle-noticia',
          backLink: 'Volver',
          firstLevel: 'Inicio',
          secondLevel: 'Sobre Acciona',
          thirdLevel: 'Noticias',
          pageTitle: 'ACCIONA adquiere en EE.UU. 3.000 MW de proyectos fotovoltaicos en desarrollo',
          sector: 'Energía',
          jobDate: '15 de Marzo, 2019',
        },
      },
     {
        component: 'Wysiwyg',
        props: {
          html: `
            <div class="image-featured"><div class="image-content"><img src="http://accionacorporativa-front.redbilityservers.com/static/formatos/formato_wysiwyg_L.jpg"></div></div>
<div class="text-container"><ul><li>Mejora en las condiciones de vida de las comunidades&gt;</li><li>Mejora de la economía de las comunidades&gt;</li><li>Creación de empleo local y fomento del autoempleo local a través de microempresas&gt;</li><li>Integración laboral de personas en riesgo de exclusión social.&gt;</li><li>Acceso a iluminación eléctrica&gt;</li><li>Mejoras en la salud de las comunidades&gt;</li><li>Acceso a la educación&gt;</li><li>Mejora de las infraestructuras públicas&gt;</li><li>Incremento de la concienciación y sensibilización con el medioambiente y mitigación de impactos&gt;</li><li>Fomento del deporte&gt;</li></ul><p>ACCIONA prevé poner en servicio ocho proyectos de esa cartera antes de que finalice 2023, añadiendo unos 1.500 MW de potencia pico (unos 1.200 MW nominales) a su parque de activos renovables en Norteamérica, donde cuenta con 1.245 MW eólicos operativos y en construcción. La compañía gestiona asimismo en propiedad una planta termosolar de 64 MW cerca de Las Vegas. </p>
<div class="blockquote"><blockquote>Somos una empresa singular que forma parte de un nuevo sector económico de actividades bajas en carbono. Nuestras oportunidades de negocio se encuentran en la intersección entre lo que la sociedad necesita y lo que del planeta demanda</blockquote></div>
<h3>Título pequeño</h3>
<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>

<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>
<p>Como explican los autores en el “Prólogo”, el objetivo principal de este manual es proporcionar a los estudiantes del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades de magisterio) una base de conocimientos que les permita diseñar del área de educación (pedagogía, psicopedagogía, educación social y las distintas especialidades y como explican los autores en el “Prólogo”, el objetivo</p>
            `
        },
     },   
      {
        component: 'ModuloContenidoDev',
        props: {
        },
      },     
      {
        component: 'SubscriptionForm',
        props: {
          color: 'white',
          action: '/subscription-API-endpoint',
          newsletterType: 'generic',
          title: 'Suscríbete a nuestra newsletter',
          descr:
            'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
          checkboxLabel:
            'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
          confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
        },
      },  
      {
        component: 'ShareSocial',
        props: {
          moduleTitle: 'Compartir en redes sociales',
        },
      },          
      {
        component: 'ModuleNews',
        props: {
        tagLine: 'Módulo Noticias',
        cta: true,
        content: [
          {
          title: 'ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao',
          category: 'Agua',
          icon: '',
          date: 'Marzo 2018',
          text: 'ACCIONA compartirá su experiencia en la jornada de trabajo organizada por el grupo Quero en colaboración con el Consorcio de'
          },
          {
          title: 'ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA',
          category: 'Agua',
          date: 'Marzo 2018',
          text: 'ACCIONA, a través de su negocio de Agua, ha resultado adjudicataria del contrato para la explotación y conservación de las instalaciones'
          },
          {
          title: 'ACCIONA muestra la EBAR Pradera a escolares de la zona',
          text: 'Los niños, de entre 9 y 10 años, pudieron conocer de primera mano el proceso de tratamiento de aguas residuales y participaron en actividades lúdica',
          tag: 'Innovación',
          date: 'Marzo 2018'
          },
        ]			
        },
      },  
      {
        component: 'ModuloContenidoDev',
        props: {
        },
      },           
    ],
  },
};
